/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import Confetti from 'react-confetti';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import { DEFAULT_CAMPAIGNS_LIMIT } from '@/campaigns/context/constants';
import useFilterCampaigns from '@/pages/campaigns/hooks/useFilterCampaigns';
import { usePageView } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Button, Flex } from '@/shared/ui';

import { CampaignsState, useCampaignsContext } from '../context/CampaignsContext';
import { CampaignSearch } from '../list/CampaignSearch';
import ListEmailCampaignCards from './ListEmailCampaignCards';

type CampaignStatus = 'draft' | 'scheduled' | 'complete' | 'archive' | '';
type EmailCampaignsListPageProps = {
  status: CampaignStatus;
};

export const EmailCampaignsListPage = ({
  status = '',
}: EmailCampaignsListPageProps): JSX.Element => {
  // Change analytics page
  usePageView();

  const { campaignsState: campaignsState, showConfetti } = useCampaignsContext();

  const { loading: isLoading } = campaignsState;
  const filteredCampaigns = getFilteredCampaigns(status, campaignsState);
  const emailCampaigns = filteredCampaigns.filter((campaign) => campaign.type == 'email');

  const { width, height } = useWindowSize();

  const campaignsContext = useCampaignsContext();
  const {
    setCurrentCampaign,
    campaignsState: {
      isUpdating: isCampaignsListUpdating,
      totalCount,
      isCampaignsInitialized,
    },
  } = campaignsContext;

  // if filters changed just now, we don't want to
  // display SearchedCampaignEmptyState or CampaignEmptyState immediately
  const [didFiltersChange, setDidFiltersChange] = useState(false);
  // manages the fetching campaigns when the filter changes
  // will update the CampaignContext
  const {
    filters,
    changeFilters,
    increaseOffsetCoefficient,
    offsetCoefficient,
    campaignsRef,
  } = useFilterCampaigns({
    status,
  });

  const history = useHistory();

  const isListComplete = (offsetCoefficient + 1) * DEFAULT_CAMPAIGNS_LIMIT < totalCount;

  // when a users clicks create
  const handleCreateCampaign = () => {
    // reset the current campaign state
    setCurrentCampaign({} as any);
    // navigate to the campaign builder
    history.push('/campaigns/email/create');
  };

  // check if loading next is enabled
  // temporarily unused for email campaigns
  // since the total count is for all campaigns
  // and not narrowed to email campaigns. This
  // creates a faulty match
  // eslint-disable-next-line
  const _enabledLoadingNext =
    // Is campaign list updating
    !isCampaignsListUpdating &&
    // If the list is already complete
    isListComplete &&
    // Is campaigns Initialized
    isCampaignsInitialized;

  // this is an event hook to prevent the UI "blinking" of the following sequence
  // CampaignEmptyState -> SearchedCampaignEmptyState -> loading -> SearchedCampaignEmptyState
  // when user search something inside an empty campaign list
  useEffect(() => {
    // if loading changed from true to false
    // it means the fetch campaigns api call has finished
    // we can now set didFiltersChange back to false
    // to allow SearchedCampaignEmptyState or CampaignEmptyState to be rendered
    if (!isLoading) {
      setDidFiltersChange(false);
    }
  }, [isLoading]);

  {
    /** Campaigns state - filter out duplicate ids */
  }
  const filteredEmailCampaigns = useMemo(
    () =>
      emailCampaigns.filter((campaign, index, self) => {
        return index === self.findIndex((t) => t.id === campaign.id);
      }),
    [emailCampaigns]
  );

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Campaigns', path: '/campaigns' },
        { title: 'Emails', path: `/campaigns/emails` },
      ]}
      actions={<Button onClick={handleCreateCampaign}>Create Campaign</Button>}
      css={{}}
    >
      {showConfetti && <Confetti width={width} height={height} />}
      {/* used just for its padding top */}
      <Flex css={{ paddingTop: '30px' }} />
      <CampaignSearch
        campaigns={emailCampaigns}
        filters={filters}
        changeFilters={changeFilters}
        setDidFiltersChange={setDidFiltersChange}
      />

      <ListEmailCampaignCards
        campaigns={emailCampaigns}
        filteredCampaigns={filteredEmailCampaigns}
        isLoading={isLoading}
        filters={filters}
        campaignsRef={campaignsRef}
        didFiltersChange={didFiltersChange}
        isCampaignsListUpdating={isCampaignsListUpdating}
        totalCount={totalCount}
        increaseOffsetCoefficient={increaseOffsetCoefficient}
      />
    </PageLayout>
  );
};

function getFilteredCampaigns(status: CampaignStatus, campaignsState: CampaignsState) {
  switch (status) {
    case '':
      return campaignsState.campaigns;
    case 'draft':
      return campaignsState.draftCampaigns;
    case 'complete':
      return campaignsState.completeCampaigns;
    case 'scheduled':
      return campaignsState.scheduledCampaigns;
    case 'archive':
      return campaignsState.archivedCampaigns;
  }
}
