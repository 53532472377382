/* eslint-disable no-unused-vars */
import {
  Combobox,
  ComboboxItem,
  ComboboxPopover,
  ComboboxProvider,
  useComboboxContext,
} from '@ariakit/react';
import { Toolbar } from '@radix-ui/react-toolbar';
import { createContext, useContext, useState } from 'react';
import { HiX } from 'react-icons/hi';
import { Virtuoso } from 'react-virtuoso';

import { AddUploadToAudience } from '@/pages/campaigns/quick/paste/AddUploadToAudience';
import { ToolbarButton } from '@/pages/inbox/conversation/panels/ConversationToolbar';
import { Contact } from '@/shared/types';
import { Group } from '@/shared/types/contacts/groups';
import { Tag } from '@/shared/types/tags';
import {
  Box,
  Flex,
  Skeleton,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from '@/shared/ui';
import { styled } from '@/stitches.config';
/**
 * The `TabbedCombobox` component is a multi-tabbed selection input that allows users
 * to switch between different tabs and select multiple items from a searchable dropdown.
 * The combobox items require 3 key details. A unique id, the value that we'll present to the user
 * which would be the text displayed that they could select, and finally the type. The type allows us
 * to better track which item was selected between the tabs.
 *
 * @param onTabChange - Callback function that is called when the user switches tabs.
 * @param [defaultTab] - The default active tab key when the component mounts.
 * @param [onSearch] - Callback function triggered when the user types in the search box.
 * @param setSelectedItems - Function to update the selected items.
 * @param selectedItems - An array of currently selected items.
 * @param [onEndReached] - Callback function triggered when the end of the list is reached (for infinite scrolling).
 * @param children - Content of the tabbed combobox, typically the list of items.
 */

type ContextProps = {
  onSearch: (searchInput: string) => void;
  onTabChange?: (tab: string) => void;
  defaultTab?: string;
  isScrolling?: (scrolling: boolean) => void;
  onEndReached: (tab: string, searchInput: string) => void;
  setSelectedItems: (values: { id: string; value: string; type: string }[]) => void;
  setValue?: (value: string) => void;
  selectedItems?: { id: string; value: string; type: string }[];
  currentTab?: string;
  setCurrentTab?: (tab: string) => void;
};

interface TabbedComboboxComponent
  extends React.FC<React.PropsWithChildren<ContextProps>> {
  Tabs: React.FC<React.PropsWithChildren<React.InputHTMLAttributes<HTMLDivElement>>>;
  TabsList: React.FC<React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>>;
  Popover: React.FC<React.PropsWithChildren<React.InputHTMLAttributes<HTMLDivElement>>>;
  Input: React.FC<React.InputHTMLAttributes<HTMLInputElement>>;
  ResultsList: React.FC<React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>>;
  ResultItem: React.FC<{ value: string; id: string }>;
  TabsTrigger: React.FC<
    React.PropsWithChildren<{ value: string } & React.HTMLAttributes<HTMLDivElement>>
  >;
  TabsContent: React.FC<
    React.PropsWithChildren<{
      value: string;
      isLoading: boolean;
      items: (Tag | Contact | Group)[];
      totalItems: number;
    }>
  >;
}

const TabbedComboboxContext = createContext<ContextProps | undefined>(undefined);
const useTabbedComboboxContext = () => {
  const context = useContext(TabbedComboboxContext);
  if (context == undefined) {
    throw new Error('TabbedCombobox.* must be a child of TabbedCombobox component');
  }
  return context;
};

const TabbedCombobox: TabbedComboboxComponent = ({
  onTabChange,
  defaultTab,
  onSearch,
  setSelectedItems,
  setValue,
  selectedItems,
  onEndReached,
  isScrolling,
  children,
}) => {
  const [currentTab, setCurrentTab] = useState(defaultTab);
  return (
    <TabbedComboboxContext.Provider
      value={{
        onTabChange,
        defaultTab,
        onSearch,
        setSelectedItems,
        setValue,
        onEndReached,
        setCurrentTab,
        isScrolling,
        currentTab,
      }}
    >
      <Flex
        direction="column"
        data-testid="tabbed-combobox-root"
        css={{
          width: '100%',
          padding: '4px 0px',
        }}
      >
        <ComboboxProvider
          setValue={setValue}
          setSelectedValue={(selectedItems) => {
            const items = convertValueStringToObject(selectedItems as string[]);
            setSelectedItems(items);
          }}
          selectedValue={
            selectedItems?.map(
              (item) => item.value + '---' + item.id + '---' + item.type
            ) ?? []
          }
        >
          {children}
        </ComboboxProvider>
      </Flex>
    </TabbedComboboxContext.Provider>
  );
};

const TabbedComboboxTabs: React.FC<
  React.PropsWithChildren<React.InputHTMLAttributes<HTMLDivElement>>
> = ({ children, style }) => {
  const context = useTabbedComboboxContext();
  return (
    <Tabs
      style={style}
      defaultValue={context.defaultTab}
      onValueChange={(tab) => {
        if (context.setCurrentTab) {
          context.setCurrentTab(tab);
        }
        if (context.onTabChange) {
          context.onTabChange(tab);
        }
      }}
    >
      {children}
    </Tabs>
  );
};

const TabbedComboboxTabsList: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ children, style }) => {
  return (
    <Box
      css={{
        borderBottom: '2px solid var(--colors-slate2)',
      }}
    >
      <TabsList
        style={{
          borderBottom: 'none',
          marginBottom: '0px',
          ...style,
        }}
      >
        {children}
      </TabsList>
    </Box>
  );
};

const TabbedComboboxInput: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({
  placeholder,
}) => {
  const tabbedContext = useTabbedComboboxContext();
  const comboboxContext = useComboboxContext();
  return (
    <Flex justify="between" align="center" css={{ padding: '0px' }}>
      <StyledCombobox
        onChange={(e) => tabbedContext.onSearch(e.target.value)}
        placeholder={placeholder}
        focusable={false}
        style={{
          width: '100%',
          marginLeft: '4px',
        }}
      />
      <Flex
        css={{
          gap: '8px',
          alignItems: 'center',
        }}
      >
        <AddUploadToAudience
          renderAsIconButton={true}
          addPastedDataToAudience={(uploadTag: Tag) => {
            const currentSelectedValues = getSelectedValues(comboboxContext);
            const newSelectedValues = [
              ...currentSelectedValues,
              { id: uploadTag.id, type: 'upload', value: uploadTag.name },
            ].map((selectedValueObject) =>
              toSelectValueString(
                selectedValueObject.id,
                selectedValueObject.type,
                selectedValueObject.value
              )
            );
            setSelectedValues(comboboxContext, newSelectedValues);
          }}
          // eslint-disable-next-line
          setQuickCampaignDialogState={(_isOpen: boolean) => {}}
        />
        <Toolbar>
          <ToolbarButton
            testID="clear-all-combobox-items"
            description="Clear All Items"
            side="top"
            onClick={() => setSelectedValues(comboboxContext, [])}
          >
            <HiX
              style={{
                color: '#C62A2F',
              }}
            />
          </ToolbarButton>
        </Toolbar>
      </Flex>
    </Flex>
  );
};

const TabbedComboboxTabsTrigger: React.FC<
  React.PropsWithChildren<{ value: string } & React.HTMLAttributes<HTMLDivElement>>
> = ({ value, style, children }) => {
  return (
    <TabsTrigger value={value} style={style}>
      {children}
    </TabsTrigger>
  );
};

const handleEndReached = (
  currentTab: string,
  searchInput: string,
  onEndReached: (tab: string, searchInput: string) => void
) => {
  onEndReached(currentTab, searchInput as string);
};

// NOTE: For some reason including increaseViewportBy creates a buggy experience. As the user scrolls, this will cause some "shake"
// which ultimately doesn't really let scroll work properly. It will only let you scroll almost one item at a time.
const TabbedComboboxTabsContent: React.FC<
  React.PropsWithChildren<{
    value: string;
    isLoading: boolean;
    items: (Tag | Group | Contact)[];
    totalItems: number;
  }>
> = ({ value, items, totalItems, isLoading }) => {
  const tabbedComboboxContext = useTabbedComboboxContext();
  const [topMostIndex, setTopMostIndex] = useState(() =>
    determineTopMostIndex(items, totalItems)
  );
  const { isScrolling } = tabbedComboboxContext;
  const comboboxContext = useComboboxContext();

  return (
    <TabsContent
      value={value}
      css={{
        width: '100%',
        marginTop: '8px',
      }}
    >
      {isLoading ? <LoadingState /> : <></>}
      {!isLoading && totalItems == 0 ? <EmptyState /> : <></>}
      {!isLoading && totalItems > 0 ? (
        <Virtuoso
          style={{
            height: totalItems * 24 >= 192 ? '192px' : totalItems * 24 + 30,
          }}
          data={items}
          initialTopMostItemIndex={topMostIndex}
          isScrolling={isScrolling}
          endReached={(index) => {
            // don't load more data if we've reached the end
            // accounts for zero index vs length
            if (index >= totalItems - 1) return;
            setTopMostIndex(index);
            handleEndReached(
              tabbedComboboxContext.currentTab as string,
              comboboxContext?.getState().value ?? '',
              tabbedComboboxContext.onEndReached
            );
          }}
          itemContent={(index, item) => {
            let value = item.name;
            if (isContact(item) && !item.name) {
              if (item.phone) value = item.phone;
              else if (item.email) value = item.email;
              // no name, show dash instead
              else value = '-';
            }
            return (
              <ComboboxItem
                key={index}
                value={value + '---' + item.id + '---' + getType(item)}
                resetValueOnSelect={() => {
                  // if we select an item make sure to
                  // reset the search state.
                  tabbedComboboxContext.onSearch('');
                  return true;
                }}
                hideOnClick
                focusOnHover
                style={{
                  height: '32px',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  cursor: 'pointer',
                }}
              >
                <Flex
                  align="center"
                  css={{
                    height: '100%',
                    borderRadius: '4px',
                    padding: '0px 12px',
                    boxShadow: 'none',
                    '&:focus': {
                      boxShadow: 'none',
                    },
                    '&:hover': {
                      backgroundColor: '#3E63DD',
                      color: '#FFF',
                      boxShadow: 'none',
                    },
                    '&:active': {
                      backgroundColor: '#3E63DD',
                      boxShadow: 'none',
                    },
                  }}
                >
                  {value}
                </Flex>
              </ComboboxItem>
            );
          }}
        />
      ) : (
        <></>
      )}
    </TabsContent>
  );
};

const EmptyState = () => {
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      css={{
        height: '192px',
      }}
    >
      <Text variant="bold" size="3">
        {' '}
        No Search Results{' '}
      </Text>
      <p>Change your search parameters</p>
    </Flex>
  );
};

const LoadingState = () => {
  return (
    <Flex
      direction="column"
      css={{
        marginTop: '12px',
        width: '100%',
        gap: '8px',
        height: '192px',
        overflowY: 'auto',
      }}
    >
      {Array.from({ length: 9 }, (_, index) => (
        <div
          key={index}
          style={{
            height: '24px',
          }}
        >
          <Skeleton
            css={{
              margin: '0px 18px',
              height: '24px',
            }}
          />
        </div>
      ))}
    </Flex>
  );
};

const TabbedComboboxPopover: React.FC<
  React.PropsWithChildren<React.InputHTMLAttributes<HTMLDivElement>>
> = ({ children, style }) => {
  return (
    <ComboboxPopover sameWidth flip={false} style={style}>
      {children}
    </ComboboxPopover>
  );
};

const TabbedComboboxResultsList: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ children, style }) => {
  return <Flex style={style}>{children}</Flex>;
};

const TabbedComboboxResultItem: React.FC<{ value: string; id: string }> = ({
  value,
  id,
}) => {
  const comboboxContext = useComboboxContext();
  return (
    <Flex
      // use the id section of the value to create a test data id
      data-testid={`tabbed-combobox-result-item-${id}`}
      css={{
        display: 'flex',
        height: '24px',
        padding: '0px 8px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        cursor: 'pointer',
        borderRadius: '3px',
        background: '#0144FF0F',
        color: '#00259ECB',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0.04px',
      }}
    >
      {value}
      <button
        onClick={() => {
          const selectedItems = getSelectedValues(comboboxContext);
          const selectedValues = selectedItems
            .filter((item) => item.value != value)
            .map((item) => item.value + '---' + item.id + '---' + item.type);
          setSelectedValues(
            comboboxContext,
            selectedValues.filter((item) => item != value)
          );
        }}
      >
        <HiX
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      </button>
    </Flex>
  );
};

// if the object has an email field, then it's of type Contact
function isContact(item: any): item is Contact {
  return (item as Contact).email !== undefined;
}

// if the object has an filters_version field, then it's of type Segment
function isSegment(item: any): item is Group {
  return (item as Group).filters_version !== undefined;
}

// if the object has an type field, then it's of type Tag
function isTag(item: any): item is Tag {
  return (item as Tag).type !== undefined;
}

function getType(item: Contact | Tag | Group) {
  if (isContact(item)) return 'contact';
  else if (isSegment(item)) return 'segment';
  else if (isTag(item)) {
    if (item.type == 'upload') {
      return 'list';
    } else {
      return 'tag';
    }
  }
}
function setSelectedValues(comboboxContext: any, selectedValues: string[]): void {
  comboboxContext?.setState('selectedValue', selectedValues);
}
function toSelectValueString(id: string, type: string, value: string): string {
  return value + '---' + id + '---' + type;
}
/**
 * Converts an array of string values into an array of objects.
 * Each string is expected to be in the format `value---id---type`.
 * The function splits each string by the '---' delimiter and maps it to an object containing `id`, `value`, and `type`.
 *
 * @param selectedItems - An array of strings, each in the format `value---id---type`.
 * @returns An array of objects, where each object has `id`, `value`, and `type` properties.
 *
 * @example
 * // Example input:
 * const selectedItems = ['apple---123---fruit', 'carrot---456---vegetable'];
 *
 * // Example output:
 * [
 *   { id: '123', value: 'apple', type: 'fruit' },
 *   { id: '456', value: 'carrot', type: 'vegetable' }
 * ]
 */
function convertValueStringToObject(selectedItems: string[]) {
  return selectedItems.map((item) => {
    const value = item.split('---')[0];
    const id = item.split('---')[1];
    const type = item.split('---')[2];
    return {
      id,
      value,
      type,
    };
  });
}

function getSelectedValues(
  comboboxContext: any
): { id: string; value: string; type: string }[] {
  const state = comboboxContext?.getState();
  const selectedItems = state?.selectedValue as string[];
  return convertValueStringToObject(selectedItems);
}

function determineTopMostIndex(items: (Tag | Contact | Group)[], totalItems: number) {
  // if we're at the bottom of the screen and there's no more
  // data to load, index should be the last element
  if (totalItems == items.length) {
    return totalItems;
  }
  // otherwise, only choose nearest hundred. Only 100, 200, 300 are valid options for the top index
  const nearestHundred = Math.round((items.length - 100) / 100) * 100;
  return Math.max(nearestHundred, 0);
}

const StyledCombobox = styled(Combobox, {
  paddingLeft: '4px',
  paddingRight: '4px',
  width: '100%',

  // remove any focus styling
  '&:focus': {
    outline: 'none',
    boxShadow: 'none',
    border: 'none',
  },

  // font related
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
});

export {
  TabbedCombobox,
  TabbedComboboxTabs,
  TabbedComboboxTabsList,
  TabbedComboboxTabsTrigger,
};
TabbedCombobox.Tabs = TabbedComboboxTabs;
TabbedCombobox.TabsList = TabbedComboboxTabsList;
TabbedCombobox.TabsTrigger = TabbedComboboxTabsTrigger;
TabbedCombobox.TabsContent = TabbedComboboxTabsContent;
TabbedCombobox.ResultsList = TabbedComboboxResultsList;
TabbedCombobox.ResultItem = TabbedComboboxResultItem;
TabbedCombobox.Popover = TabbedComboboxPopover;
TabbedCombobox.Input = TabbedComboboxInput;
export default TabbedCombobox;
