import { Helmet } from 'react-helmet-async';

import { useAuth } from '@/auth/context/AuthProvider';
import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';

import { AddChannel } from './AddChannel';
import { ChannelsTable } from './ChannelsTable';
export * from './channel';

export const Channels = () => {
  usePageView();

  const auth = useAuth();
  const isWhippyAdmin =
    auth?.tokens?.email && auth?.tokens?.email.includes('whippy' || 'hellowhippy');

  return (
    <>
      <Helmet>
        <title>Whippy | Channels</title>
      </Helmet>
      <SettingsLayout
        background="#fafafa"
        padding="0"
        width="100%"
        breadcrumbs={[
          { title: 'Settings', path: '/settings/channels' },
          { title: 'Channels', path: `/settings/channels` },
        ]}
        actions={isWhippyAdmin && <AddChannel />}
      >
        <ChannelsTable />
      </SettingsLayout>
    </>
  );
};
