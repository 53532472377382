/* eslint-disable react-hooks/exhaustive-deps */
import { Combobox, ComboboxItem, ComboboxList, ComboboxProvider } from '@ariakit/react';
import * as RadixSelect from '@radix-ui/react-select';
import { matchSorter } from 'match-sorter';
import { startTransition, useMemo, useState } from 'react';
import { HiCheck, HiChevronDown, HiSearch } from 'react-icons/hi';

import { Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

export type ComboboxOption = { label: string; value: string | number };

type ValueComboboxProps = {
  options: Array<{ label: string; value: string | number; disabled?: boolean }>;
  selected: ComboboxOption | null | undefined;
  onSelect: (option: { label: string; value: string | number }) => void;
  selectLabel?: string;
  searchLabel?: string;
  searchValue?: string;
  handleSearchValue?: (value: string) => void;
  selectorStyles?: { [key: string]: any };
  valueStyles?: { [key: string]: any };
  popoverStyles?: { [key: string]: any };
  comboboxStyles?: { [key: string]: any };
  comboboxItemStyles?: { [key: string]: any };
  comboboxWrapperStyles?: { [key: string]: any };
  css?: { [key: string]: any };
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
  SelectItemIndicatorComponent?: React.FC<RadixSelect.SelectItemIndicatorProps>;
  transformValue?: (value?: string | number) => string;
  withSearch?: boolean;
  /* hides the options when select is not open to avoid slows/blocks renders
    if it has more items "than usual". And use span instead of <SelectValue/> */
  renderOptionsIfOpen?: boolean;
};

export const ValueCombobox = (props: ValueComboboxProps) => {
  const {
    options,
    selected,
    onSelect,
    selectLabel,
    searchLabel,
    searchValue,
    handleSearchValue,
    selectorStyles,
    valueStyles,
    popoverStyles,
    comboboxStyles,
    comboboxItemStyles,
    comboboxWrapperStyles,
    css,
    isOpen,
    setIsOpen,
    SelectItemIndicatorComponent,
    transformValue,
    renderOptionsIfOpen,
    withSearch = true,
  } = props;
  const [open, setOpen] = useState(isOpen);
  const [value, setValue] = useState('');

  // if searchValue is not provided, use value and handleSearchValue else use value and setValue
  const handleSearch = handleSearchValue ? handleSearchValue : setValue;
  const searchValueProp = handleSearchValue ? searchValue : value;

  const matches = useMemo(() => {
    if (!searchValueProp) return options;
    const keys = ['label', 'value'];
    const matches = matchSorter(options, searchValueProp, { keys });
    // Radix Select does not work if we don't render the selected item, so we
    // make sure to include it in the list of matches.
    const selectedLanguage = options?.find((lang) => lang.value === selected?.value);
    if (selectedLanguage && !matches?.includes(selectedLanguage)) {
      matches?.push(selectedLanguage);
    }
    return matches;
  }, [searchValueProp, selected?.value, options]);

  return (
    <Flex align="center" css={{ minWidth: 127, height: 35, ...css }}>
      <RadixSelect.Root
        value={selected?.value as string}
        onValueChange={(value) => {
          const option = options.find((lang) => lang.value === value);
          if (option) {
            onSelect(option);
          }
        }}
        open={open}
        onOpenChange={(v) => {
          setOpen(v);
          setIsOpen && setIsOpen(v);
        }}
      >
        <ComboboxProvider
          open={open}
          setOpen={(v) => {
            setOpen(v);
            setIsOpen && setIsOpen(v);
          }}
          resetValueOnHide
          includesBaseElement={false}
          setValue={(value) => {
            startTransition(() => {
              handleSearch(value);
            });
          }}
        >
          <StyledSelect css={selectorStyles} aria-label={selectLabel || 'Select a value'}>
            <span style={{ ...valueStyles }}>
              {transformValue ? (
                renderOptionsIfOpen ? (
                  <span>
                    {transformValue(selected?.value) || selectLabel || 'Select a value'}
                  </span>
                ) : (
                  <RadixSelect.Value>
                    {transformValue(selected?.value) || selectLabel || 'Select a value'}
                  </RadixSelect.Value>
                )
              ) : renderOptionsIfOpen ? (
                <span>{selected?.label || selectLabel || 'Select a value'}</span>
              ) : (
                <RadixSelect.Value placeholder={selectLabel || 'Select a value'} />
              )}
            </span>
            <RadixSelect.Icon style={{ translate: '4px 0' }}>
              <HiChevronDown />
            </RadixSelect.Icon>
          </StyledSelect>
          <StyledPopover
            css={popoverStyles}
            role="dialog"
            aria-label="Languages"
            position="popper"
            sideOffset={4}
          >
            {withSearch && (
              <ComboboxWrapper css={comboboxWrapperStyles}>
                <StyledComboboxIcon>
                  <HiSearch />
                </StyledComboboxIcon>
                <StyledCombobox
                  css={comboboxStyles}
                  autoSelect
                  placeholder={searchLabel || 'Search options'}
                  // Ariakit's Combobox manually triggers a blur event on virtually
                  // blurred items, making them work as if they had actual DOM
                  // focus. These blur events might happen after the corresponding
                  // focus events in the capture phase, leading Radix Select to
                  // close the popover. This happens because Radix Select relies on
                  // the order of these captured events to discern if the focus was
                  // outside the element. Since we don't have access to the
                  // onInteractOutside prop in the Radix SelectContent component to
                  // stop this behavior, we can turn off Ariakit's behavior here.
                  onBlurCapture={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                />
              </ComboboxWrapper>
            )}
            {((open && renderOptionsIfOpen) || !renderOptionsIfOpen) && (
              <StyledComboboxList>
                {matches?.map(({ label, value, disabled }) => (
                  <StyledItem
                    css={comboboxItemStyles}
                    disabled={disabled}
                    key={value}
                    value={value as string}
                    asChild
                  >
                    <ComboboxItem>
                      <RadixSelect.ItemText>{label}</RadixSelect.ItemText>
                      {SelectItemIndicatorComponent ? (
                        <SelectItemIndicatorComponent />
                      ) : (
                        <StyledItemIndicator>
                          <HiCheck />
                        </StyledItemIndicator>
                      )}
                    </ComboboxItem>
                  </StyledItem>
                )) || 'No matches found.'}
              </StyledComboboxList>
            )}
          </StyledPopover>
        </ComboboxProvider>
      </RadixSelect.Root>
    </Flex>
  );
};

export const StyledPopover = styled(RadixSelect.Content, {
  zIndex: 50,
  maxHeight: 'min(var(--radix-select-content-available-height), 336px)',
  borderRadius: '0.5rem',
  backgroundColor: 'hsl(204 20% 100%)',
  boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.25), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
  colorScheme: 'light',
});

export const StyledSelect = styled(RadixSelect.Trigger, {
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: 'white',
  justifyContent: 'space-between',
  borderRadius: 4,
  color: 'hsl(204 10% 10%)',
  border: '1px solid $slate7',
  padding: '$space$1 $space$3',
  width: '100%',
  height: '100%',
  fontSize: '14px',
  minWidth: 'max-content',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const StyledComboboxList = styled(ComboboxList, {
  overflowY: 'auto',
  padding: '0.25rem',
  fontSize: '14px',
});

export const StyledComboboxIcon = styled('div', {
  pointerEvents: 'none',
  position: 'absolute',
  left: '0.625rem',
  color: 'hsl(204 10% 10% / 0.6)',
});

export const StyledItem = styled(RadixSelect.Item, {
  position: 'relative',
  fontSize: '14px',
  pointer: 'cursor',
  display: 'flex',
  height: '2rem',
  cursor: 'default',
  scrollMarginTop: '0.25rem',
  scrollMarginBottom: '0.25rem',
  backgroundColor: 'white',
  alignItems: 'center',
  borderRadius: '0.25rem',
  paddingLeft: '1.75rem',
  paddingRight: '1.75rem',
  color: 'hsl(204 10% 10%)',
  outline: '2px solid transparent',
  outlineOffset: '2px',
  '&[data-active-item]': {
    backgroundColor: '#3A5CCC',
    color: '#fff',
  },
  '&[data-disabled]': {
    opacity: 0.5,
  },
});

export const StyledCombobox = styled(Combobox, {
  height: '2rem',
  appearance: 'none',
  borderRadius: '0.25rem',
  backgroundColor: 'hsl(204 10% 10% / 0.05)',
  paddingLeft: '1.75rem',
  color: 'hsl(204 10% 10%)',
  outline: '2px solid transparent',
  outlineOffset: '2px',
  width: '100%',
});

export const ComboboxWrapper = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.25rem',
  paddingBottom: '0px',
  fontSize: '14px',
});

export const StyledItemIndicator = styled(RadixSelect.ItemIndicator, {
  position: 'absolute',
  left: '0.375rem',
});
