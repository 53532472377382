import { FilterItem } from '@/shared/types/filter';

export function appendFilterItemsToExcludedSystemDefault(
  filterItems: FilterItem[],
  channelId: string
) {
  return [
    // remove blocked contacts
    {
      column: 'blocked',
      resource: 'contact',
      comparison: '==',
      value: true,
      or: [
        // # remove archived contacts
        {
          column: 'state',
          resource: 'contact',
          comparison: 'in',
          value: ['archived', 'blocked'],
          or: [
            {
              column: 'opt_in_sms',
              resource: 'contact',
              comparison: '==',
              value: false,
              or: [
                {
                  column: 'opt_out_of_all',
                  resource: 'contact',
                  comparison: '==',
                  value: 'true',
                  or: [
                    {
                      resource: 'communication_preference',
                      column: 'opt_in',
                      comparison: '==',
                      value: 'false',
                      or: structuredClone(filterItems),
                    },
                    {
                      resource: 'communication_preference',
                      column: 'location_id',
                      comparison: '==',
                      value: channelId,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
}

/**
 * This function will return a new copy of the list with the newItem
 * appending to a new or field in the original filterItems.
 */
export function appendOrCondition(list: FilterItem[], newItem: FilterItem) {
  const copyList = structuredClone(list);
  if (list.length == 0) return [newItem];

  function appendCondition(currentItem: FilterItem) {
    if (!currentItem.or) {
      currentItem.or = [
        {
          resource: newItem.resource,
          column: newItem.column,
          comparison: newItem.comparison,
          value: newItem.value,
        },
      ];
      return;
    } else {
      appendCondition(currentItem.or[0]);
      return;
    }
  }
  appendCondition(copyList[0]);
  return copyList;
}

type FilterItemPredicate = (item: FilterItem) => boolean;
/**
 * Potentially removes a filter item by traversing through the or nodes. This will perform
 * a deep copy of the list that we pass in.
 *
 * NOTE: In the case the item we're looking for is not in an 'or' field, then we will replace
 * that current node with the next filter item in the current level, or else we will
 * try to replace the item with a node from the 'and' field if it exists.
 *
 * @param list - An array of FilterItems
 * @param predicate - A function that takes a FilterItem and returns true or false.
 * @returns A copy of the FilterItem list that was passed in, with a single condition removed.
 */
export function removeOrCondition(list: FilterItem[], predicate: FilterItemPredicate) {
  const copyList = structuredClone(list);
  if (copyList.length == 0) return [];

  function removeCondition(index: number, filters?: FilterItem[]) {
    if (!filters) return;
    if (index >= filters.length) return;
    let currentItem = filters[index];
    // if we have an 'or' field has a child that matches the predicate
    // delete that index from the list
    if (
      currentItem.or &&
      currentItem.or.length == 1 &&
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      predicate(currentItem.or[0])
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      delete currentItem['or'];
      return;
    }
    if (!currentItem.or && predicate(currentItem)) {
      if (filters.length > 1) {
        filters = filters.splice(index + 1);
      } else if (currentItem.and && currentItem.and.length > 0) {
        currentItem = currentItem.and[0];
        currentItem.and = currentItem.and?.splice(index + 1);
      } else {
        filters = filters.splice(index);
      }
      return;
    }
    // handle the case that none of the top level filter items
    // in the list of 'or' filter items matched, search recursively
    // within those filter items
    else if (currentItem.or) {
      removeCondition(0, currentItem.or);
    }
    removeCondition(index + 1, copyList);
  }
  removeCondition(0, copyList);
  return copyList;
}

export function removeExcludedSystemDefault(
  filterItems: FilterItem[],
  defaultItems: FilterItem[]
): FilterItem[] {
  if (filterItems.length == 0) return [];
  // There are 4 ors in the defaults
  // If there's an additional filters then it's an additional or
  return filterItems[0]?.or?.[0]?.or?.[0]?.or?.[0]?.or?.[0]?.or ?? defaultItems;
}

export function hasMoreThanSystemExcludedFilter(filterItems: FilterItem[]): boolean {
  if (filterItems[0]?.or?.[0]?.or?.[0]?.or?.[0]?.or?.[0]?.or) {
    return true;
  } else {
    return false;
  }
}

export function deepCompare(one?: FilterItem[], two?: FilterItem[]): boolean {
  if (!one && two) return false;
  if (one && !two) return false;
  if (!one && !two) return true;
  // handles both null and undefined
  if (one == null && two == null) return true;
  if (one == null || two == null || one.length != two.length) return false;
  if (one.length != two.length) return false;
  if (one.length == 0 && two.length == 0) return false;
  const itemOne = one[0];
  const itemTwo = two[0];
  if (
    itemOne.resource != itemTwo.resource ||
    itemOne.column != itemTwo.column ||
    itemOne.comparison != itemTwo.comparison ||
    itemOne.value != itemTwo.value
  )
    return false;
  return deepCompare(itemOne.and, itemTwo.and) && deepCompare(itemOne.or, itemTwo.or);
}

export function findFilterItem(
  filterItems: FilterItem[],
  predicate: FilterItemPredicate
): FilterItem | null {
  if (filterItems.length === 0) return null;
  for (const filterItem of filterItems) {
    if (predicate(filterItem)) {
      return filterItem;
    }
  }

  for (const filterItem of filterItems) {
    const filterItemInOr = findFilterItem(filterItem.or ?? [], predicate);
    if (filterItemInOr) return filterItemInOr;
    const filterItemInAnd = findFilterItem(filterItem.and ?? [], predicate);
    if (filterItemInAnd) return filterItemInAnd;
  }
  return null;
}
export function translateToAdvancedFilter(filterItems: FilterItem[]): FilterItem[] {
  const validQuickFilterResource = ['contact', 'tag', 'list'];
  const copyFilterItems = structuredClone(filterItems);
  if (filterItems.length === 0) return [];
  function helper(filterItems: FilterItem[]) {
    for (const filterItem of filterItems) {
      if (
        filterItem.comparison == 'in' &&
        Array.isArray(filterItem.value) &&
        validQuickFilterResource.includes(filterItem.resource)
      ) {
        // then it's a list of IDs
        const values = filterItem.value as string[];
        filterItem.comparison = '==';
        filterItem.value = values[0];
        let current = filterItem;
        const childNode = current.or;
        values.slice(1).forEach((value) => {
          current.or = [
            {
              resource: filterItem.resource,
              column: filterItem.column,
              comparison: '==',
              value: value,
            },
          ];
          current = current.or[0];
        });
        if (childNode && childNode.length > 0) current.or = childNode;
      }
    }

    for (const filterItem of filterItems) {
      if (filterItem.or) helper(filterItem.or);
    }
  }
  helper(copyFilterItems);
  return copyFilterItems;
}

/**
 * This determines whether a list of filter items can be properly displayed by the AudienceQuickFilter.
 *
 * A Included QuickFilter Audience is valid if it meets the following criteria
 *   - resource type can only be 'contact', 'tag', and/or 'list'
 *   - only supported column is 'id'
 *   - only supported comparison operations are 'in' and '=='
 *
 * A Excluded QuickFilter Audience is valid if it meets the following criteria
 *   - we can support the rules for the Included Audience Filter and the ones below
 *   - we can support type 'communication_preference', column: 'last_campaign_date', and comparison being '>'
 *       - This is needed to support the exclude rule for excluding contacts that have been in a recent campaign.
 *   - we can support type 'conversation', column: 'status', and comparison being 'in', and value being ['open', 'automated']
 *       - This is needed to support the exclude rule for excluding contacts that have open conversations.
 * @param filterItems - The list of filter items that represent the audience
 * @type audienceType - Whether the audience represents the list of people to include or exclude.
 *
 * NOTE: For excluded filter we should remove the system defaults rules before passing into this function.
 */
export function isValidQuickFilter(
  filterItems: FilterItem[] | null,
  audienceType: 'included' | 'excluded'
): boolean {
  if (!filterItems) return true;
  if (filterItems.length == 0) return true;
  for (const filterItem of filterItems) {
    const isValid =
      audienceType == 'included'
        ? isValidEntity(filterItem)
        : isValidEntity(filterItem) ||
          isValidOpenConversation(filterItem) ||
          isValidExcludeTimeSince(filterItem);
    if (!isValid) return false;
  }
  for (const filterItem of filterItems) {
    const isValidOrField = isValidQuickFilter(filterItem.or ?? [], audienceType);
    if (!isValidOrField) return false;
    const isValidAndField = isValidQuickFilter(filterItem.and ?? [], audienceType);
    if (!isValidAndField) return false;
  }
  return true;
}

function isValidEntity(filterItem: FilterItem) {
  const validResources = ['contact', 'tag', 'list'];
  const validComparison = ['in', '=='];
  const isValidResource = validResources.includes(filterItem.resource);
  const isValidComparison = validComparison.includes(filterItem.comparison ?? '');
  const isValidColumn = filterItem.column == 'id';
  return isValidColumn && isValidComparison && isValidResource;
}

function isValidExcludeTimeSince(filterItem: FilterItem) {
  const isValidResource = filterItem.resource == 'communication_preference';
  const isValidComparison = filterItem.comparison == '>';
  const isValidColumn = filterItem.column == 'last_campaign_date';
  return isValidColumn && isValidComparison && isValidResource;
}

function isValidOpenConversation(filterItem: FilterItem) {
  const isValidResource = filterItem.resource == 'conversation';
  const isValidComparison = filterItem.comparison == 'in';
  const isValidColumn = filterItem.column == 'status';
  const isValidValue =
    Array.isArray(filterItem.value) &&
    filterItem.value.length == 2 &&
    filterItem.value[0] == 'open' &&
    filterItem.value[1] == 'automated';
  return isValidColumn && isValidComparison && isValidResource && isValidValue;
}
