/* eslint-disable react-hooks/exhaustive-deps */
import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';

import { useAuth } from '@/auth/context/AuthProvider';
import ContactState from '@/contacts/context/ContactContext';
import GroupState from '@/contacts/groups/context/GroupContext';
import UploadsState from '@/contacts/uploads/context/UploadContext';
import SocketProvider from '@/pages/auth/context/socket/SocketProvider';
import CampaignsState from '@/pages/campaigns/context/CampaignsContext';
import UnsubscribePreferenceState from '@/pages/contacts/editor/preferences/context/UnsubscribePreferenceContext';
import DataState from '@/pages/data/context/DataContext';
import DomainsProvider from '@/pages/domains/context/DomainsContext';
import EmbedState from '@/pages/embed/context/EmbedContext';
import ConversationState from '@/pages/inbox/context/ConversationContext';
import DraftMessageState from '@/pages/inbox/drafts/context/DraftMessagesContext';
import SequenceState from '@/pages/sequences/context/SequenceContext';
import CustomDataProvider from '@/pages/settings/organization/data/context/CustomDataContext';
import SettingsProvider from '@/pages/settings/organization/general/context/SettingsContext';
import TenDlcState from '@/pages/settings/organization/onboarding/context/TenDlcContext';
import SignatureState from '@/pages/settings/organization/signatures/context/SignaturesContext';
import TagState from '@/pages/settings/organization/tags/context/TagsContext';
import TeamsProvider from '@/pages/settings/organization/teams/context/TeamsContext';
import TemplateState from '@/pages/settings/organization/templates/context/TemplatesContext';
import UserState from '@/pages/settings/organization/users/context/UserContext';
import UserPreferencesState from '@/pages/settings/user/preferences/context/PreferencesContext';
import VoIPState from '@/pages/voip/context/VoIPContext';
import { AIProvider } from '@/shared/components/editor/v2/context/AIContext';
import { FocusedProvider } from '@/shared/components/editor/v2/context/FocusedContext';
import { SideNavigationContextProvider } from '@/shared/components/navigation/context/SideNavigationContext';
import UserWebNotificationsProvider from '@/shared/components/notifications/context/UserWebNotificationsContext';
import { GlobalSearchProvider } from '@/shared/globalSearch/SearchContext';
import { isDev, isPreview } from '@/shared/utils/config';

export const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();

  const ldClient = useLDClient();

  // generate different user keys for dev, preview, and prod
  // this prevent feature flag conflicts between environments
  const generateUserKey = (id: number) => {
    if (isDev) {
      return `user-dev-${id.toString()}`;
    } else if (isPreview) {
      return `user-preview-${id.toString()}`;
    } else {
      return `user-${id.toString()}`;
    }
  };

  useEffect(() => {
    const userId = auth?.tokens?.user_id;
    if (!userId) return;
    ldClient?.identify({
      kind: 'user',
      key: generateUserKey(userId),
      name: auth?.tokens?.name || '',
      email: auth?.tokens?.email || '',
    });
  }, [auth?.tokens?.organization_id]);

  return (
    <SocketProvider refresh={auth.refresh}>
      <DomainsProvider>
        <SettingsProvider>
          <UserPreferencesState>
            <SignatureState>
              <TenDlcState>
                <DataState>
                  <GroupState>
                    <UploadsState>
                      <UserState>
                        <TeamsProvider>
                          <CustomDataProvider>
                            <DraftMessageState>
                              <TagState>
                                <ContactState>
                                  <VoIPState>
                                    <TemplateState>
                                      <CampaignsState>
                                        <SequenceState>
                                          <ConversationState>
                                            <FocusedProvider>
                                              <UserWebNotificationsProvider>
                                                <EmbedState>
                                                  <UnsubscribePreferenceState>
                                                    <SideNavigationContextProvider>
                                                      <AIProvider>
                                                        <GlobalSearchProvider>
                                                          {children}
                                                        </GlobalSearchProvider>
                                                      </AIProvider>
                                                    </SideNavigationContextProvider>
                                                  </UnsubscribePreferenceState>
                                                </EmbedState>
                                              </UserWebNotificationsProvider>
                                            </FocusedProvider>
                                          </ConversationState>
                                        </SequenceState>
                                      </CampaignsState>
                                    </TemplateState>
                                  </VoIPState>
                                </ContactState>
                              </TagState>
                            </DraftMessageState>
                          </CustomDataProvider>
                        </TeamsProvider>
                      </UserState>
                    </UploadsState>
                  </GroupState>
                </DataState>
              </TenDlcState>
            </SignatureState>
          </UserPreferencesState>
        </SettingsProvider>
      </DomainsProvider>
    </SocketProvider>
  );
};
