import {
  ConversationActions,
  ConversationItemType,
  ConversationsActionTypes,
  ConversationScheduledMessageType,
  ConversationStatusTypes,
  ConversationType,
  ConversationWithStateType,
} from '@/shared/types/conversations';

import { ConversationsState, PartialWithId } from './ConversationContext';
import { formatState, sortConversationItems } from './utils';

export const ConversationReducer: React.Reducer<
  ConversationsState,
  ConversationActions
> = (state: ConversationsState, action: ConversationActions) => {
  switch (action.type) {
    case ConversationsActionTypes.APPEND_CONVERSATIONS:
      return {
        ...state,
        conversations: formatState(
          mergeConversations(state.conversations, action.payload)
        ),
        loading: false,
      };
    case ConversationsActionTypes.SET_CONVERSATION:
      // Ensure that action.payload has a string id before updating current
      return {
        ...state,
        current: action.payload
          ? {
              ...action.payload,
              topOffset:
                action.payload.topOffset ??
                action.payload?.conversationItemsPage.conversationItems.length,
            }
          : action.payload,
      };
    case ConversationsActionTypes.CLEAR_CONVERSATION:
      return {
        ...state,
        current: null,
      };
    case ConversationsActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ConversationsActionTypes.SET_CONVERSATIONS:
      return {
        ...state,
        conversations: formatState(action.payload),
        loading: false,
      };
    case ConversationsActionTypes.UPDATE_CONVERSATION:
      return {
        ...state,
        conversations: formatState(
          updateConversationInState(state.conversations, action.payload)
        ),
        current:
          state.current?.id === action.payload.id
            ? { ...state.current, ...action.payload }
            : state.current,
      };
    case ConversationsActionTypes.CHANGE_TAB:
      return {
        ...state,
        tabIndex: action.payload,
      };
    case ConversationsActionTypes.REMOVE_CONVERSATION:
      return {
        ...state,
        current: state.current?.id === action.payload.id ? null : state.current,
        conversations: state.conversations.filter(
          (conversation) => conversation.id !== action.payload.id
        ),
      };
    case ConversationsActionTypes.ADD_MESSAGE:
      // by type its allow null so if no current return state
      if (!state.current) return state;
      return {
        ...state,
        current: upsertMultipleMessages(state.current, [action.payload], true),
        conversations: formatState(
          state.conversations.map((c) =>
            upsertMultipleMessages(c, [action.payload], false)
          )
        ),
      };
    case ConversationsActionTypes.UPSERT_MESSAGES:
      // by type its allow null so if no current return state
      if (!state.current) return state;
      return {
        ...state,
        current: upsertMultipleMessages(state.current, action.payload, false),
        conversations: formatState(
          state.conversations.map((c) => upsertMultipleMessages(c, action.payload, false))
        ),
      };
    case ConversationsActionTypes.UPSERT_MESSAGES_BEFORE:
      // by type its allow null so if no current return state
      if (!state.current) return state;
      return {
        ...state,
        current: upsertMultipleMessages(state.current, action.payload, false, true),
        conversations: formatState(
          state.conversations.map((c) =>
            upsertMultipleMessages(c, action.payload, false, true)
          )
        ),
      };
    case ConversationsActionTypes.ADD_SCHEDULED_MESSAGE: {
      // by type its allow null so if no current.id return state
      if (!state.current?.id) return state;
      const scheduled_messages = state.current.scheduled_messages || [];
      return {
        ...state,
        current: {
          ...state.current,
          ...{
            scheduled_messages: state.current
              ? [...scheduled_messages, action.payload.params]
              : [action.payload.params],
          },
        },
      };
    }
    case ConversationsActionTypes.UPDATE_SCHEDULED_MESSAGE:
      if (!state.current?.scheduled_messages) return state;
      return {
        ...state,
        current: {
          ...state.current,
          scheduled_messages: state.current?.scheduled_messages.map(
            (scheduled_message: ConversationScheduledMessageType) => {
              return scheduled_message.job_id === action.payload.params.job_id
                ? action.payload.params
                : scheduled_message;
            }
          ),
        },
      };
    case ConversationsActionTypes.DELETE_SCHEDULED_MESSAGE:
      // by type its allow null so if no current return state
      if (!state.current?.scheduled_messages) return state;
      return {
        ...state,
        current: {
          ...state.current,
          scheduled_messages: state.current?.scheduled_messages.filter(
            (scheduled_message) =>
              scheduled_message.job_id !== action.payload.params.job_id
          ),
        },
      };
    case ConversationsActionTypes.SEND_SCHEDULED_MESSAGE_NOW:
      if (!state.current?.scheduled_messages) return state;
      return {
        ...state,
        current: {
          ...state.current,
          scheduled_messages: state.current?.scheduled_messages.filter(
            (scheduled_message) =>
              scheduled_message.job_id !== action.payload.params.job_id
          ),
        },
      };
    case ConversationsActionTypes.NEW_CONVERSATION:
      return {
        ...state,
        conversations: formatState([...state.conversations, action.payload]),
      };
    case ConversationsActionTypes.UPDATE_CONVERSATION_CONTACT:
      // by type its allow null so if no current.id return state
      if (!state.current?.id) return state;
      return {
        ...state,
        current: {
          ...state.current,
          contact: { ...state.current?.contact, phone: action.payload },
        },
      };
    case ConversationsActionTypes.UPDATE_CONTACT_LANGUAGE:
      // by type its allow null so if no current.id return state
      if (!state.current?.id) return state;
      return {
        ...state,
        current: {
          ...state.current,
          contact: { ...state.current?.contact, language: action.payload },
        },
      };
    case ConversationsActionTypes.SET_SORT:
      return {
        ...state,
        sort: action.payload,
      };
    case ConversationsActionTypes.SET_PANEL_STATE:
      return {
        ...state,
        showConversationPanel: action.payload,
      };
    case ConversationsActionTypes.SET_CONVERSATION_PANEL:
      return {
        ...state,
        conversationPanel: action.payload,
      };
    case ConversationsActionTypes.TOGGLE_FORWARD:
      return {
        ...state,
        isForwarded: action.payload.isForwarded,
        forwardingParams: action.payload.forwardingParams,
      };
    case ConversationsActionTypes.SELECT_CONVERSATION:
      return {
        ...state,
        // add the payload to the selected array
        selectedConversations: [...state.selectedConversations, action.payload],
      };
    case ConversationsActionTypes.UNSELECT_CONVERSATION:
      return {
        ...state,
        // remove the id payload from the selected array
        selectedConversations: state.selectedConversations.filter(
          (id) => id !== action.payload
        ),
      };
    case ConversationsActionTypes.CLEAR_SELECTED_CONVERSATIONS:
      return {
        ...state,
        selectedConversations: [],
      };
    // use closeConversations function to close multiple conversations in the sate conversations array
    case ConversationsActionTypes.BULK_UPDATE_CONVERSATION_STATUS:
      return {
        ...state,
        conversations: updateConversationsStatus(
          state.conversations,
          action.payload.conversation_ids,
          action.payload.status
        ) as ConversationType[],
      };
    default:
      return state;
  }
};

/**
 * This function updates the conversations array with the provided conversationUpdates.
 * It merges the conversationUpdates into the existing conversation object, ensuring that only
 * the keys present in conversationUpdates are updated, and all other keys remain the same.
 * For the conversationItems, it merges the new items into the existing ones.
 *
 * @param {Array<ConversationType>} conversations - The existing conversations array.
 * @param {ConversationType} conversationUpdates - The updates to be applied to the conversation.
 * @returns {Array<ConversationType>} The updated conversations array.
 */
export const updateConversationInState = (
  conversations: Array<ConversationType>,
  conversationUpdates: PartialWithId<ConversationType>
) => {
  return conversations.map((c) =>
    c.id === conversationUpdates.id
      ? {
          // Spread the existing conversation object
          ...c,
          // Spread the conversationUpdates object to update the keys present in conversationUpdates
          ...conversationUpdates,
          // Merge the new conversationItems into the existing ones
          conversationItemsPage: {
            conversationItems: mergeConversationItems(
              c.conversationItemsPage?.conversationItems || [],
              conversationUpdates.conversationItemsPage?.conversationItems?.filter(
                Boolean
              ) || []
            ),
          },
        }
      : c
  );
};

/**
 * This function merges the new conversation items into the existing ones.
 * It first updates the existing items that are also present in the new items.
 * Then it adds the new items that are not already present in the existing items.
 * This ensures that the conversationItems are updated but not overwritten.
 *
 * @param {Array<ConversationItemType>} currentItems - The existing conversation items.
 * @param {Array<ConversationItemType>} newItems - The new conversation items.
 * @returns {Array<ConversationItemType>} The updated conversation items array.
 */
export const mergeConversationItems = (
  currentItems: Array<ConversationItemType>,
  newItems: Array<ConversationItemType>
) => {
  // Create a Set of new item IDs
  const newItemIds = new Set(newItems.map((item) => item.id));
  // Update the existing items that are also present in the new items
  const updatedCurrentItems = currentItems
    .map((item) =>
      newItemIds.has(item.id) ? newItems.find((newItem) => newItem.id === item.id) : item
    )
    .filter(Boolean) as ConversationItemType[]; // assert as ConversationItemType[]
  // Add the new items that are not already present in the existing items
  const addedItems = newItems.filter((newItem) => !newItemIds.has(newItem.id));
  return [...updatedCurrentItems, ...addedItems];
};

export const mergeConversations = (
  stateConversations: Array<ConversationType>,
  newConversations: Array<ConversationType>
) => {
  const mergedConversations = stateConversations.map((stateConv) => {
    const newConv = newConversations.find((newConv) => newConv.id === stateConv.id);
    if (newConv) {
      // Merge conversationItems from both conversations without duplicates
      const mergedItems = [
        ...(stateConv.conversationItemsPage?.conversationItems || []),
        ...(newConv.conversationItemsPage?.conversationItems || []).filter(
          (item) =>
            !(
              (stateConv.conversationItemsPage
                ?.conversationItems as ConversationItemType[]) || []
            ).some((stateItem) => stateItem.id === item.id)
        ),
      ];

      // Return new conversation with updated state and merged conversationItems
      return { ...newConv, conversationItemsPage: { conversationItems: mergedItems } };
    }
    // If no matching new conversation, return the state conversation as is
    return stateConv;
  });

  // Add any new conversations that were not already in state
  const newIds = new Set(mergedConversations.map((c) => c.id));
  const newOnlyConversations = newConversations.filter((c) => !newIds.has(c.id));

  return [...mergedConversations, ...newOnlyConversations];
};

export const upsertMultipleMessages = (
  conversation: ConversationWithStateType,
  messages: ConversationItemType[],
  isNewMessages: boolean,
  loadBefore?: boolean
) => {
  // if no messages return the conversation
  if (!messages.length) return conversation;

  // filter out messages without events incase the first item is not a message
  const filteredMessages: ConversationItemType[] = messages.filter(
    (m: ConversationItemType) =>
      m !== undefined && 'conversation_id' in m && m.conversation_id
  );

  const filteredEvents: ConversationItemType[] = messages.filter(
    (m: ConversationItemType) =>
      m !== undefined && !('conversation_id' in m) && 'contact_id' in m
  );

  // if the message is not for this conversation return the conversation
  if (
    !filteredEvents.length &&
    (!filteredMessages.length ||
      ('conversation_id' in filteredMessages[0] &&
        conversation?.id !== filteredMessages[0]?.conversation_id))
  ) {
    return conversation;
  }

  // if the bottomOffset is > 0 and isNewMessages is true return the conversation with newMessagesCount
  if (!!conversation.bottomOffset && isNewMessages) {
    return { ...conversation, newMessagesCount: isNewMessages ? messages.length : 0 };
  }

  // If the first message is not for this conversation
  // Return the current conversation without any changes to the conversationItems
  const firstMessage = messages[0];
  if (
    firstMessage &&
    'conversation_id' in firstMessage &&
    conversation.id !== firstMessage.conversation_id
  ) {
    return conversation;
  }

  // if the message is for this conversation return the conversation with the new messages and events
  return updateConversationItems(
    { ...conversation, newMessagesCount: isNewMessages ? messages.length : 0 },
    messages,
    loadBefore
  );
};

const updateConversationItems = (
  conversation: ConversationWithStateType,
  items: Array<ConversationItemType>,
  loadBefore?: boolean
): ConversationWithStateType => {
  return {
    ...conversation,
    ...{
      conversationItemsPage: {
        conversationItems: sortConversationItems(
          loadBefore
            ? merge(items, conversation.conversationItemsPage.conversationItems, 'id')
            : merge(conversation.conversationItemsPage.conversationItems, items, 'id')
        ),
      },
    },
    topOffset: loadBefore
      ? conversation.topOffset
      : (conversation.topOffset ?? 0) + items.length,
    bottomOffset: loadBefore
      ? (conversation.bottomOffset ?? 0) - items.length > 0
        ? (conversation.bottomOffset ?? 0) - items.length
        : 0
      : conversation.bottomOffset,
  };
};

/**
 * Merges two arrays based on a specific property.
 *
 * @param {Array<T>} a - The first array.
 * @param {Array<T>} b - The second array.
 * @param {keyof T} p - The property to base the merge on.
 *
 * @returns {Array<T>} The merged array.
 */
const merge = <T extends { [key: string]: any }>(
  a: Array<T>,
  b: Array<T>,
  p: keyof T
): Array<T> => {
  // Filter out items from the first array that are also present in the second array
  // based on the property 'p'
  const filteredA = a.filter((aa) => !b.find((bb) => aa[p] === bb[p]));

  // Concatenate the filtered first array with the second array
  return filteredA.concat(b);
};

// take the conversation state list of conversations and
// mark the conversation.status as closed if the conversation.id matches the payload
const updateConversationsStatus = (
  conversations: Array<ConversationType>,
  payload: Array<string>,
  status: ConversationStatusTypes
) => {
  return conversations.map((c) => {
    if (payload.includes(c.id)) {
      return { ...c, status: status };
    }
    return c;
  });
};

export default ConversationReducer;
