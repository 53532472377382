/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ChangeEvent, FormEvent } from 'react';
import { useLocation } from 'react-use';

import { Channel } from '@/shared/types/channels';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Box,
  Button,
  Divider,
  Fieldset,
  Flex,
  Input,
  Label,
  Text,
  VStack,
} from '@/shared/ui';

import { useChannels } from '../context/ChannelContext';
import { AddressInput } from './AddressInput';

export const ChannelDetails = () => {
  const { channelsState, updateChannel, setCurrent } = useChannels();
  const { current } = channelsState;

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!current) return;
    setCurrent({ ...current, [e.target.name]: e.target.value });
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>, location: Channel) => {
    e.preventDefault();
    updateChannel({ ...current, ...location });
  };

  // Get the query parameters from the URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const open = queryParams.get('open');

  return (
    <Accordion
      css={{ background: 'white' }}
      type="single"
      collapsible
      // If locationDetails is in the query params, open the accordion
      defaultValue={open === 'locationDetails' ? 'basic-information' : undefined}
      data-testid="location-details"
    >
      <AccordionItem value="basic-information" variant="neumorphic">
        <Flex justify="between" align="center">
          <VStack gap={2} css={{ lineHeight: 1.5 }}>
            <Text css={{ fontWeight: 600 }}>Channel Details</Text>
            <Text>{"Update information about this channel's name and address"}</Text>
          </VStack>
          <AccordionTrigger />
        </Flex>
        <AccordionContent variant="neumorphic">
          <Divider css={{ mt: -20, mb: 20 }} />
          <form
            onSubmit={(e) => onSubmit(e, current as Channel)}
            data-testid="location-details-form"
          >
            <VStack gap={2}>
              <Fieldset>
                <Label htmlFor="name">Channel Name</Label>
                <Input
                  id="name"
                  placeholder="Northridge"
                  name="name"
                  value={current?.name || ''}
                  onChange={(e) => onChange(e)}
                />
              </Fieldset>
              <Fieldset>
                <Label htmlFor="address">Address</Label>
                <AddressInput
                  channel={current as Channel}
                  setChannel={
                    setCurrent as React.Dispatch<React.SetStateAction<Partial<Channel>>>
                  }
                  onChange={onChange}
                />
              </Fieldset>
              <Fieldset>
                <Label htmlFor="google_place_id">Place ID</Label>
                <Input
                  id="google_place_id"
                  placeholder="GhIJQWDl0CIeQUARxks3icF8U8A"
                  name="google_place_id"
                  value={current?.google_place_id || ''}
                  onChange={(e) => onChange(e)}
                  // If locationDetails is in the query params, autofocus the Google Place ID input
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={open == 'locationDetails' ? true : false}
                />
              </Fieldset>
              <Box>
                <Button type="submit">Update Channel</Button>
              </Box>
            </VStack>
          </form>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
