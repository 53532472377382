import { Formik } from 'formik';
import React from 'react';

import { FormFieldWrapper, PhoneNumberInput } from '@/shared/components/forms';
import { Box, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

export const VoIPKeypad = () => {
  const keys = [
    {
      value: '1',
    },
    {
      value: '2',
      label: 'ABC',
    },
    {
      value: '3',
      label: 'DEF',
    },
    {
      value: '4',
      label: 'GHI',
    },
    {
      value: '5',
      label: 'JKL',
    },
    {
      value: '6',
      label: 'MNO',
    },
    {
      value: '7',
      label: 'PQRS', // spell-checker:disable-line
    },
    {
      value: '8',
      label: 'TUV',
    },
    {
      value: '9',
      label: 'WXYZ', // spell-checker:disable-line
    },
    {
      value: '*',
    },
    {
      value: '0',
      label: '+',
    },
    {
      value: '#',
    },
  ];

  return (
    <Box css={{ flex: 1, paddingTop: 112 }}>
      <Formik
        enableReinitialize
        initialValues={{
          phone: '',
        }}
        onSubmit={async (values) => {
          console.log(values);
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <FormFieldWrapper name="phone">
              <PhoneNumberInput
                css={{
                  background: 'transparent',
                  color: '#fff',
                  boxShadow: 'none',
                  textAlign: 'center',
                  fontSize: 28,
                  '&:focus': {
                    boxShadow: 'none',
                  },
                }}
              />
            </FormFieldWrapper>
            <Box css={{ px: 40, py: 32 }}>
              <Flex align="center" wrap="wrap" justify="center">
                {keys.map((key) => (
                  <KeypadButton
                    key={key.value}
                    onClick={() =>
                      formik.setFieldValue(
                        'phone',
                        formik.getFieldProps('phone').value + key.value
                      )
                    }
                  >
                    <Box>{key.value}</Box>
                    <Box css={{ fontSize: 12, color: '#ADB1B8' }}>{key.label}</Box>
                  </KeypadButton>
                ))}
              </Flex>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export const KeypadButton = styled('div', {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  width: 56,
  height: 56,
  background: '#BDC8FF17',
  borderRadius: '50%',
  margin: 12,
  alignItems: 'center',
  fontSize: 20,
  lineHeight: '20px',
  fontWeight: 700,
  color: '#fff',
  padding: 8,
  '&:hover': {
    background: '#C7D6FE20',
  },
});
