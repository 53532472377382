import React, { useState } from 'react';
import { ChangeEvent, useEffect, useRef } from 'react';

import { Channel } from '@/shared/types/channels';
import { Input } from '@/shared/ui';
import { handleScriptLoad, loadScript, removeScript } from '@/shared/utils/setup/google';

type AddressInputParams = {
  /** A channel object in Whippy */
  channel: Partial<Channel>;
  /** Set the channel object in Whippy */
  setChannel: React.Dispatch<React.SetStateAction<Partial<Channel>>>;
  /** call function when address input changes */
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

export const AddressInput = ({ channel, setChannel, onChange }: AddressInputParams) => {
  const autoCompleteRef = useRef(null);
  const [address, setAddress] = useState({
    formatted_address: '',
    place_id: '',
  });

  useEffect(() => {
    // If the google script is not loaded, load it
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyDvZkCD8dE8AHfGya8Z_l8coxbhcsKiDvM&libraries=places`,
      () => handleScriptLoad(setAddress, autoCompleteRef)
    );

    // Remove the script tag from the document body on unmount
    return () => {
      removeScript(
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyDvZkCD8dE8AHfGya8Z_l8coxbhcsKiDvM&libraries=places`
      );
    };
  }, []);

  useEffect(() => {
    if (address.formatted_address && address.place_id) {
      handleAutocomplete(address);
    }
  }, [address]);

  const handleAutocomplete = (details: {
    formatted_address: string;
    place_id: string;
  }) => {
    setChannel({
      ...channel,
      address: details.formatted_address,
      google_place_id: details.place_id,
    });
  };

  return (
    <Input
      id="address"
      name="address"
      ref={autoCompleteRef}
      placeholder="19240 Nordhoff St Suite C2, Northridge, CA 91324"
      value={channel?.address || ''}
      onChange={(e) => onChange(e)}
    />
  );
};
