/* eslint-disable @typescript-eslint/no-unused-vars */
import i18next from 'i18next';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { toast } from 'sonner';

import { CreateContact } from '@/pages/data/contacts/CreateContact';
import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import { getUploadsV2 } from '@/shared/api/contacts/uploads';
import * as V1ContactsAPI from '@/shared/api/contacts/v1';
import { searchContacts } from '@/shared/api/contacts/v2';
import { getTagsV2 } from '@/shared/api/tags';
import { Contact } from '@/shared/types';
import { SearchFilters } from '@/shared/types/contacts';
import { Group } from '@/shared/types/contacts/groups';
import { FilterItem, Sort } from '@/shared/types/filter';
import { Tag } from '@/shared/types/tags';
import { Badge, Flex, Skeleton, Text } from '@/shared/ui';
import { phoneFormatting } from '@/shared/utils/validations/validations';
import TabbedCombobox from '@/shared/v2/components/tabbedCombobox/TabbedCombobox';

import { appendNewFilterType, getFilterItemsByType, groupFiltersByType } from './utils';

type AudienceResourceType = 'contact' | 'tag' | 'list';
type Tab = 'contacts' | 'tags' | 'uploads';
export type AudienceItem = {
  data: Contact | Tag | Group;
  type: AudienceResourceType;
};

type RemoveSystemDefaultFunction = (filters: FilterItem[]) => FilterItem[];

type AudienceQuickFilterProps = {
  onSelectedItemsChanged: (filter: FilterItem[]) => void;
  /*
   * This list of FilterItems should be "simple" and related to contact, tag, and list ids.
   * It's important to filter out filter items not related.
   */
  selectedItems: FilterItem[] | null;
  /*
   * This function is used to filter out any filter items not related to the contact, tag, and list
   * ids. We use function to filter non relevant items before managing the state for this component.
   */
  removeSystemDefaultItems?: RemoveSystemDefaultFunction;
  channelId: string | null;
};

const AudienceQuickFilter = ({
  selectedItems: selectedItems,
  onSelectedItemsChanged,
  channelId,
  removeSystemDefaultItems,
}: AudienceQuickFilterProps): JSX.Element => {
  /*
   * We want to wait until the filter is not null.
   * Null indicates that the audience is still potentially pending.
   * For example, when a user visits the campaign edit screen we only
   * have the campaign id at first. We'd need to wait until the
   * campaign object properly loads.
   */
  const [isLoading, setLoading] = useState(false);
  // We need some way to determine was the last form of requesting more search data.
  // The two options are we scrolled to the bottom and we're need more data OR
  // we typed some new text. This helps us prevent invalid states such as
  // requesting more data in the situation where searching causes us to hit the bottom reached
  // state.
  const [searchEvent, setSearchEvent] = useState<'scroll' | 'search' | null>();
  const [_loadedResultItems, setLoadedResultItems] = useState(false);
  const [comboboxInputValue, setComboboxInputValue] = useState('');
  const [selectedComboboxItems, setSelectedComboboxItems] = useState<
    {
      id: string;
      value: string;
      type: AudienceResourceType;
    }[]
  >([]);

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [totalContacts, setTotalContacts] = useState<number>(0);
  const [contactsOffset, setContactsOffset] = useState(0);
  // in some cases we want to be able to partially load result items
  // so we keep track
  const [resultItemsToLoad, setResultItemsToLoad] = useState(0);

  const [tags, setTags] = useState<Tag[]>([]);
  const [totalTags, setTotalTags] = useState<number>(0);
  const [tagsOffset, setTagsOffset] = useState(0);

  const [uploadLists, setUploadLists] = useState<Tag[]>([]);
  const [totalUploadLists, setTotalUploadLists] = useState<number>(0);
  const [uploadListsOffset, setUploadListsOffset] = useState(0);

  useEffect(() => {
    // If we selectedItems is still null, that means we have not yet
    // loaded the filter items so we should wait.
    // We know the filter items are properly loaded, we can proceed.
    // We only have selected items if we manually selected
    // items or if we have filters that we converted to selected items.
    // if (selectedComboboxItems.length > 0)
    // does the selected combo boxes know about
    // all the ides in our quick filter items
    // if (compare(selectedComboboxItems, selectedItems, removeSystemDefaultItems))
    const fetchInitiallySelectedItems = async (initialFilter: FilterItem[]) => {
      const contactsFilter = getFilterItemsByType('contact', initialFilter);
      const tagsFilter = getFilterItemsByType('tag', initialFilter);
      const listsFilter = getFilterItemsByType('list', initialFilter);
      const selectedItems: {
        id: string;
        value: string;
        type: AudienceResourceType;
      }[] = [];
      if (contactsFilter.length > 0) {
        const contacts = await getContacts({
          filter: contactsFilter,
          limit: 100,
          offset: 0,
          sort: [],
          searchFilter: [],
        });
        contacts.data.forEach((contact) => {
          selectedItems.push({
            type: 'contact',
            id: contact.id,
            value: contact.name ?? '',
          });
        });
      }
      if (tagsFilter.length > 0) {
        const tags = await getTags({
          filter: tagsFilter,
          limit: 100,
          offset: 0,
          sort: [],
          searchFilter: [],
        });
        tags.data.forEach((tag) => {
          selectedItems.push({
            type: 'tag',
            id: tag.id,
            value: tag.name ?? '',
          });
        });
      }
      if (listsFilter.length > 0) {
        const lists = await getUploadLists({
          filter: listsFilter,
          limit: 100,
          offset: 0,
          sort: [],
          searchFilter: [],
        });
        lists.data.forEach((listUpload) => {
          selectedItems.push({
            type: 'list',
            id: listUpload.id,
            value: listUpload.name ?? '',
          });
        });
      }
      // setPendingSelectedItems()
      setResultItemsToLoad(0);
      setSelectedComboboxItems(selectedItems);
      onSelectedItemsChanged(initialFilter);
      setLoading(false);
      setLoadedResultItems(true);
    };

    // if the selected items has not been loaded
    if (!selectedItems) return;
    // don't fetch more data if we're already loading
    // if (loadedResultItems) return;

    const difference = countDifference(
      selectedComboboxItems,
      selectedItems,
      removeSystemDefaultItems
    );
    if (selectedItems && selectedItems.length > 0 && difference > 0) {
      setLoadedResultItems(false);
      setResultItemsToLoad(difference);
      setLoading(true);
      const items = getUserSelectedItems(selectedItems, removeSystemDefaultItems);
      fetchInitiallySelectedItems(items);
    } else {
      setLoading(false);
      setLoadedResultItems(true);
      return;
    }
  }, [selectedItems]);

  // make data requests for the new data
  const onSearch = async (searchInput: string) => {
    if (!isInputValid(searchInput)) return;
    setSearchEvent('search');
    const baseSearchFilter = {
      filter: [] as FilterItem[],
      sort: [] as Sort[],
      limit: 100,
      offset: 0,
    };
    const { data: contactsData, total: totalContacts } = await getContacts({
      ...baseSearchFilter,
      searchFilter: createSearchFilter('contact', searchInput),
    });
    const { data: tagsData, total: totalTags } = await getTags({
      ...baseSearchFilter,
      searchFilter: createSearchFilter('tag', searchInput),
    });
    const { data: uploadListData, total: totalUploadLists } = await getUploadLists({
      ...baseSearchFilter,
      searchFilter: createSearchFilter('list', searchInput),
    });
    setContacts(contactsData);
    setTags(tagsData);
    setUploadLists(uploadListData);

    setTotalContacts(totalContacts);
    setTotalTags(totalTags);
    setTotalUploadLists(totalUploadLists);
    setLoading(false);
  };

  const debouncedOnSearch = useCallback(debounce(onSearch, 1000), []);

  const fetchData = async (tab: Tab, searchInput: string) => {
    const baseSearchFilter = {
      filter: [] as FilterItem[],
      sort: [] as Sort[],
      limit: 100,
    };
    switch (tab) {
      case 'contacts': {
        setLoading(true);
        const offset = calculateOffset(contactsOffset, totalContacts);
        const { data: contactsData } = await getContacts({
          ...baseSearchFilter,
          searchFilter: createSearchFilter('contact', searchInput),

          sort: [],
          offset,
        });
        updateStateAfterScrollLoad(tab, contactsData, totalContacts);
        break;
      }
      case 'tags': {
        setLoading(true);
        const offset = calculateOffset(tagsOffset, totalTags);
        const { data: tagsData } = await getTags({
          ...baseSearchFilter,
          searchFilter: createSearchFilter('tag', searchInput),
          sort: [],
          offset,
        });
        updateStateAfterScrollLoad(tab, tagsData, totalTags);
        break;
      }
      case 'uploads': {
        setLoading(true);
        const offset = calculateOffset(uploadListsOffset, totalUploadLists);
        const { data: uploadListsData } = await getUploadLists({
          ...baseSearchFilter,
          searchFilter: createSearchFilter('list', searchInput),
          sort: [],
          offset,
        });
        updateStateAfterScrollLoad(tab, uploadListsData, totalUploadLists);
        break;
      }
    }
  };

  const updateStateAfterScrollLoad = (
    tab: Tab,
    data: Contact[] | Tag[] | Group[],
    totalCount: number
  ) => {
    switch (tab) {
      case 'contacts':
        setTotalContacts(totalCount);
        setContacts((prev) => [...prev, ...(data as Contact[])]);
        setContactsOffset((prev) => Math.min(prev + 100, totalCount));
        setLoading(false);
        break;
      case 'tags':
        setTotalTags(totalCount);
        setTags((prev) => [...prev, ...(data as Tag[])]);
        setTagsOffset((prev) => Math.min(prev + 100, totalCount));
        setLoading(false);
        break;
      case 'uploads':
        setTotalUploadLists(totalCount);
        setUploadLists((prev) => [...prev, ...(data as Tag[])]);
        setUploadListsOffset((prev) => Math.min(prev + 100, totalCount));
        setLoading(false);
        break;
    }
  };

  return (
    <Flex css={{ width: '100%', position: 'relative' }}>
      <TabbedCombobox
        defaultTab="contacts"
        onEndReached={(tab, searchInput) => {
          if (searchEvent == 'scroll') {
            fetchData(tab as Tab, searchInput);
          }
        }}
        isScrolling={(isScrolling) =>
          setSearchEvent((prev) => {
            if (isScrolling) {
              return 'scroll';
            }
            return prev;
          })
        }
        selectedItems={selectedComboboxItems}
        setValue={(value) => setComboboxInputValue(value)}
        setSelectedItems={(selectedItems) => {
          const newFilter = differenceFilterComboboxItems(
            selectedItems as {
              id: string;
              type: AudienceResourceType;
              value: string;
              data: Contact | Group | Tag;
            }[]
          );
          onSelectedItemsChanged(newFilter);
          setSelectedComboboxItems(
            selectedItems as {
              id: string;
              value: string;
              type: AudienceResourceType;
              data: Contact | Tag | Group;
            }[]
          );
        }}
        onSearch={(input: string) => {
          if (isInputValid(input)) {
            setLoading(true);
            debouncedOnSearch(input);
          } else {
            setLoading(false);
          }
        }}
      >
        <Flex
          direction="column"
          css={{
            border: '1px solid #0134DB72 ',
            borderRadius: '3px',
            width: '100%',
          }}
        >
          <TabbedCombobox.ResultsList
            style={{
              flexWrap: 'wrap',
              gap: '8px',
              padding: selectedComboboxItems.length == 0 ? '0px 4px' : '8px 4px',
            }}
          >
            <ResultItemsSkelton count={resultItemsToLoad} />
            {selectedComboboxItems.map((value) => (
              <TabbedCombobox.ResultItem
                key={value.id}
                value={value.value}
                id={value.id}
              />
            ))}
          </TabbedCombobox.ResultsList>
          <TabbedCombobox.Input placeholder="Search" />
        </Flex>
        <TabbedCombobox.Popover
          style={{
            background: '#FFF',
            borderRadius: '8px',
            backgroundColor: '$panel',
            border: '1px solid var(--colors-slate4)',
            boxShadow:
              '0px 10px 38px -10px rgba(22, 23, 24, 0), var(--colors-shadowDark) 0px 10px 20px -15px',
            marginTop: '16px',
            // hardcoded negative margins to align the popover with the input
            marginLeft: '-6px',
            marginRight: '-80px',
            zIndex: '999999',
            position: 'relative',
          }}
        >
          <TabbedCombobox.Tabs>
            <TabbedCombobox.TabsList style={{ width: '100%' }}>
              <Flex justify="between" align="center" css={{ width: '100%' }}>
                <Flex css={{ width: '100%', whiteSpace: 'nowrap' }}>
                  {isLoading && isInputValid(comboboxInputValue) && (
                    <TabbedCombobox.TabsTrigger value="contacts" style={{ flexGrow: 0 }}>
                      <Badge
                        css={{ zIndex: 1, marginRight: 8, width: '20px' }}
                        variant="blue"
                      />
                      Contacts
                    </TabbedCombobox.TabsTrigger>
                  )}
                  {isLoading && isInputValid(comboboxInputValue) && (
                    <TabbedCombobox.TabsTrigger value="tags" style={{ flexGrow: 0 }}>
                      <Badge
                        css={{ zIndex: 1, marginRight: 8, width: '20px' }}
                        variant="blue"
                      />
                      Tags
                    </TabbedCombobox.TabsTrigger>
                  )}
                  {isLoading && isInputValid(comboboxInputValue) && (
                    <TabbedCombobox.TabsTrigger value="uploads" style={{ flexGrow: 0 }}>
                      <Badge
                        css={{ zIndex: 1, marginRight: 8, width: '20px' }}
                        variant="blue"
                      />
                      Lists
                    </TabbedCombobox.TabsTrigger>
                  )}
                  {!isLoading && !isInputValid(comboboxInputValue) && (
                    <TabbedCombobox.TabsTrigger value="contacts" style={{ flexGrow: 0 }}>
                      <Badge
                        css={{ zIndex: 1, marginRight: 8, width: '20px' }}
                        variant="blue"
                      >
                        {'-'}
                      </Badge>
                      Contacts
                    </TabbedCombobox.TabsTrigger>
                  )}
                  {!isLoading && !isInputValid(comboboxInputValue) && (
                    <TabbedCombobox.TabsTrigger value="tags" style={{ flexGrow: 0 }}>
                      <Badge
                        css={{ zIndex: 1, marginRight: 8, width: '20px' }}
                        variant="blue"
                      >
                        {'-'}
                      </Badge>
                      Tags
                    </TabbedCombobox.TabsTrigger>
                  )}
                  {!isLoading && !isInputValid(comboboxInputValue) && (
                    <TabbedCombobox.TabsTrigger value="uploads" style={{ flexGrow: 0 }}>
                      <Badge
                        css={{ zIndex: 1, marginRight: 8, width: '20px' }}
                        variant="blue"
                      >
                        {'-'}
                      </Badge>
                      Lists
                    </TabbedCombobox.TabsTrigger>
                  )}
                  {!isLoading && isInputValid(comboboxInputValue) && (
                    <TabbedCombobox.TabsTrigger value="contacts" style={{ flexGrow: 0 }}>
                      <Badge css={{ zIndex: 1, marginRight: 8 }} variant="blue">
                        {totalContacts}
                      </Badge>
                      Contacts
                    </TabbedCombobox.TabsTrigger>
                  )}
                  {!isLoading && isInputValid(comboboxInputValue) && (
                    <TabbedCombobox.TabsTrigger value="tags" style={{ flexGrow: 0 }}>
                      <Badge css={{ zIndex: 1, marginRight: 8 }} variant="blue">
                        {totalTags}
                      </Badge>
                      Tags
                    </TabbedCombobox.TabsTrigger>
                  )}
                  {!isLoading && isInputValid(comboboxInputValue) && (
                    <TabbedCombobox.TabsTrigger value="uploads" style={{ flexGrow: 0 }}>
                      <Badge css={{ zIndex: 1, marginRight: 8 }} variant="blue">
                        {totalUploadLists}
                      </Badge>
                      Lists
                    </TabbedCombobox.TabsTrigger>
                  )}
                </Flex>
                <AddNewContactButton
                  name={comboboxInputValue}
                  onSubmit={async (campaignParams) => {
                    try {
                      const contact = await V1ContactsAPI.createContact(campaignParams);
                      // reset the combobox value
                      setComboboxInputValue('');

                      toast.success(i18next.t('contact_added') as string);
                      const newSelectedComboboxItem = {
                        id: contact.id,
                        type: 'contact',
                        value: contact.name ?? '',
                      } as {
                        id: string;
                        type: AudienceResourceType;
                        value: string;
                      };
                      const newFilter = differenceFilterComboboxItems([
                        ...selectedComboboxItems,
                        newSelectedComboboxItem,
                      ]);
                      onSelectedItemsChanged(newFilter);
                      setSelectedComboboxItems((prev) => [
                        ...prev,
                        newSelectedComboboxItem,
                      ]);
                    } catch (error) {
                      console.error(error);
                      toast.error(i18next.t('contact_added_failure') as string);
                    }
                  }}
                />
              </Flex>
            </TabbedCombobox.TabsList>
            {channelId == null && <NoChannelState />}
            {channelId != null && !isInputValid(comboboxInputValue) && (
              <InvalidInputState />
            )}
            {channelId != null && isInputValid(comboboxInputValue) && (
              <Flex>
                <TabbedCombobox.TabsContent
                  value="contacts"
                  isLoading={isLoading && isInputValid(comboboxInputValue)}
                  items={contacts}
                  totalItems={totalContacts}
                />
                <TabbedCombobox.TabsContent
                  value="tags"
                  isLoading={isLoading && isInputValid(comboboxInputValue)}
                  items={tags}
                  totalItems={totalTags}
                />
                <TabbedCombobox.TabsContent
                  value="uploads"
                  isLoading={isLoading && isInputValid(comboboxInputValue)}
                  items={uploadLists}
                  totalItems={totalUploadLists}
                />
              </Flex>
            )}
          </TabbedCombobox.Tabs>
        </TabbedCombobox.Popover>
      </TabbedCombobox>
    </Flex>
  );
};

const ResultItemsSkelton = ({ count }: { count: number }) => {
  if (count == 0) {
    return <></>;
  }
  return (
    <Flex
      css={{
        flexWrap: 'wrap',
        padding: '0px 4px',
        gap: '8px',
      }}
    >
      {Array.from({ length: count }, (_, index) => (
        <Skeleton
          key={index}
          variant="tag"
          css={{ height: 24, width: 95, margin: '0px', border: '0px' }}
        />
      ))}
    </Flex>
  );
};

// serves as a wrapper component around the existing CreateContact
const AddNewContactButton = ({
  onSubmit,
  name,
}: {
  name: string;
  onSubmit: (params: any) => void;
}) => {
  return (
    <CreateContact handleCreateContact={onSubmit} name={name}>
      <Flex
        align="center"
        css={{
          cursor: 'pointer',
          color: '#00259ECB',
          paddingLeft: '12px',
          paddingRight: '12px',
          gap: '8px',
        }}
      >
        <HiPlus />
        <button
          data-testid="audience-quick-filter-add-contact"
          style={{ whiteSpace: 'nowrap' }}
        >
          Add New Contact
        </button>
      </Flex>
    </CreateContact>
  );
};

const NoChannelState = () => {
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      css={{
        width: '100%',
        height: '360px',
      }}
    >
      <Text variant="bold" size="3">
        {' '}
        No Channel Selected{' '}
      </Text>
      <p>
        Please choose a channel for the campaign before searching through your contacts.
      </p>
    </Flex>
  );
};

const InvalidInputState = () => {
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      css={{
        width: '100%',
        height: '360px',
      }}
    >
      <Text variant="bold" size="3">
        Enter 2 or more characters to search...
      </Text>
    </Flex>
  );
};

async function getContacts(params: SearchFilters) {
  const filters = prepareFilters(params);
  return await searchContacts(
    filters.filter,
    [
      ...filters.sort,
      {
        resource: 'contact',
        column: 'updated_at',
        order: 'desc',
      } as Sort,
    ],
    filters.limit,
    filters.offset
  );
}

async function getTags(params: SearchFilters) {
  const defaultSort = {
    resource: 'tag',
    column: 'updated_at',
    order: 'desc',
  } as Sort;
  const filters = prepareFilters({
    ...params,
    sort: [...params.sort, defaultSort],
    filter: [...params.filter, ...defaultTagFilter],
  });
  return await getTagsV2(filters);
}

async function getUploadLists(params: SearchFilters) {
  const defaultSort = {
    resource: 'list',
    column: 'updated_at',
    order: 'desc',
  } as Sort;
  const filters = prepareFilters({
    ...params,
    sort: [...params.sort, defaultSort],
    filter: [...params.filter, ...defaultUploadListFilter],
  });
  return await getUploadsV2(filters);
}

const defaultUploadListFilter: FilterItem[] = [
  { column: 'type', comparison: '==', resource: 'list', value: 'upload' },
  { column: 'state', comparison: '==', resource: 'list', value: 'active' },
];

const defaultTagFilter: FilterItem[] = [
  { column: 'type', comparison: '==', resource: 'tag', value: 'standard' },
  { column: 'state', comparison: '==', resource: 'tag', value: 'active' },
];

function differenceFilterComboboxItems(
  selectedComboboxItems: {
    id: string;
    value: string;
    type: AudienceResourceType;
  }[]
): FilterItem[] {
  const newFilter = [] as FilterItem[];
  ['contact', 'tag', 'list'].forEach((type) => {
    // get each filter by type in a simplistic format
    const filtersByType = selectedComboboxItems
      .filter((item) => item.type == type)
      .map((newlySelectedItem) => {
        return {
          resource: type,
          column: 'id',
          comparison: '==',
          value: newlySelectedItem.id,
        };
      });
    // takes the simplistic format and reasonably groups the filters together
    const groupedByType = groupFiltersByType(filtersByType);
    appendNewFilterType(newFilter, groupedByType);
  });
  return newFilter;
}

// function differenceFilterFilterItems(filterItems: FilterItem[]): FilterItem[] {
//   const newFilter = [] as FilterItem[];
//   ['contact', 'tag', 'list'].forEach((type) => {
//     // get each filter by type in a simplistic format
//
//     const filtersByType = getFilterItemsByType(type, filterItems);
//     // takes the simplistic format and reasonably groups the filters together
//     const groupedByType = groupFiltersByType(filtersByType);
//     appendNewFilterType(newFilter, groupedByType);
//   });
//   return newFilter;
// }

function createSearchFilter(
  tab: AudienceResourceType,
  searchInput: string
): FilterItem[] {
  if (searchInput == '') return [];
  if (tab == 'contact') {
    return [
      {
        column: 'name',
        comparison: 'ilike',
        resource: 'contact',
        value: `%${searchInput}%`,
        or: [
          {
            column: 'email',
            comparison: 'ilike',
            resource: 'contact',
            value: `%${searchInput}%`,
            or: [
              {
                column: 'phone',
                comparison: 'ilike',
                resource: 'contact',
                value: `%${phoneFormatting(searchInput)}%`,
              },
            ],
          },
        ],
      },
    ];
  } else {
    return [
      {
        column: 'name',
        comparison: 'ilike',
        resource: tab,
        value: `%${searchInput}%`,
      },
    ];
  }
}

function getNumberOfTags(
  filters: FilterItem[] | null,
  removeExcludedSystemDefault?: RemoveSystemDefaultFunction
): number {
  if (!filters) return 0;
  let count = 0;
  function countIds(item: FilterItem) {
    if (item.comparison == '==') {
      count += 1;
    } else if (item.comparison == 'in' && Array.isArray(item.value)) {
      count += item.value.length;
    }
    if (item.or && item.or.length === 1) {
      countIds(item.or[0]);
    }
  }

  // remove system default if possible, before starting the count
  const items = getUserSelectedItems(filters, removeExcludedSystemDefault);
  items.forEach(countIds);
  return count;
}

function calculateOffset(currentOffset: number, totalCount: number) {
  // cap the offset to be below the total count
  const offset = Math.min(currentOffset + 100, totalCount - 100);
  // don't let the offset be negative
  return Math.max(offset, 0);
}

function getUserSelectedItems(
  items: FilterItem[],
  removeExcludedSystemDefault?: RemoveSystemDefaultFunction
): FilterItem[] {
  if (!removeExcludedSystemDefault) return items;
  return removeExcludedSystemDefault(items);
}

function isInputValid(input: string): boolean {
  if (input.length >= 2) return true;
  return false;
}

// compare the length of items in both lists
function countDifference(
  comboboxItems: { id: string; value: string; type: AudienceResourceType }[],
  filterItems: FilterItem[],
  removeExcludedSystemDefault?: RemoveSystemDefaultFunction
): number {
  const countOfFilterItems = getNumberOfTags(filterItems, removeExcludedSystemDefault);
  return countOfFilterItems - comboboxItems.length;
}

export default AudienceQuickFilter;
