/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { debounce, DebouncedFunc } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { handleFilterChange, handleSortChange } from '@/pages/data/utils/filterActions';
import {
  SequenceSubNavigationItem,
  StepsContainer,
  TabsContainer,
} from '@/pages/sequences/sequence/SequenceContainer';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { default_locations_object } from '@/shared/components/filterBuilder/objects/locations';
import { usePageView } from '@/shared/hooks';
import { Channel } from '@/shared/types/channels';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterType, Sort } from '@/shared/types/filter';
import { Box, Flex, HStack } from '@/shared/ui';
import { TooltipAvatar } from '@/shared/ui/TooltipAvatar';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';
import { styled } from '@/stitches.config';

import {
  ConfirmationDialogDescription,
  CustomDropdownMenuItem,
  CustomDropdownMenuItemWarning,
} from '../users/UsersTable';
import { AddChannel } from './AddChannel';
import { initialChannelsState, PAGE_SIZE, useChannels } from './context/ChannelContext';
import { sortConfig } from './filterConfig';

const channelType = (channel: Channel) => {
  switch (channel.type) {
    case 'phone':
      return formatPhoneNumber(channel.phone);
    case 'email':
      return 'Email Channel';
    case 'whatsapp':
      return 'WhatsApp';
  }
};

export const ChannelsTable = () => {
  usePageView();

  const {
    channelsState,
    disableChannel,
    setCurrent,
    updateFilterParams,
    enableChannel,
    archiveChannel,
  } = useChannels();
  const { filteredChannels, totalCount, filterParams, loading } = channelsState;

  const [isEnabledTabActive, setIsEnabledTabActive] = useState(true);
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const history = useHistory();

  const clickHandler = (channel: Channel) => {
    setCurrent(channel);
    history.push(`/settings/channels/${channel.id}`);
    return channel;
  };

  useEffect(() => {
    updateFilterParams({
      ...initialChannelsState.filterParams,
    });
  }, []);

  const data = useMemo(() => filteredChannels, [filteredChannels]);

  const columns: Array<TableColumn<Channel>> = useMemo(
    () => [
      {
        Header: 'Channel Name',
        colWidth: '100%',
        accessor: 'channels',
        Cell: (props: { row: { original: Channel } }) => (
          <Flex direction="column">
            <ChannelName>{props.row.original.name}</ChannelName>
          </Flex>
        ),
      },
      {
        Header: 'Channel Type',
        accessor: 'channelType',
        Cell: (props: { row: { original: Channel } }) => (
          <Box css={{ minWidth: 150 }}>{channelType(props.row.original)}</Box>
        ),
      },
      {
        Header: 'Created by',
        accessor: 'created_b',
        Cell: (props: { row: { original: Channel } }) => (
          <>
            {!!props.row.original.created_by && (
              <TooltipAvatar user={props.row.original.created_by} />
            )}
          </>
        ),
      },
      {
        Header: 'Updated by',
        accessor: 'updated_by',
        Cell: (props: { row: { original: Channel } }) => (
          <>
            {!!props.row.original.updated_by && (
              <TooltipAvatar user={props.row.original.updated_by} />
            )}
          </>
        ),
      },
      {
        Header: 'Updated At',
        id: 'updated_at',
        accessor: 'updated_at',
        colWidth: '100%',
        Cell: (props: { row: { original: Channel } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>
              {props.row.original.updated_at &&
                dayjs(props.row.original.updated_at).format('MMM D YYYY')}
            </Box>
          </Flex>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: (props: { row: { original: Channel } }) => {
          const channel = props.row.original;
          const state = channel.state;
          const isChannelEnabled = state === 'enabled';
          const isChannelDisabled = state === 'disabled';

          return (
            <TableActionMenu>
              <>
                <CustomDropdownMenuItem
                  data-testid="edit-option"
                  onClick={() => clickHandler(props.row.original)}
                >
                  Edit Channel
                </CustomDropdownMenuItem>
                {isChannelEnabled && (
                  <ConfirmationDialog
                    width="432px"
                    title="Disable this Channel?"
                    description={
                      <ConfirmationDialogDescription
                        value={channel.name ?? ''}
                        description="channel will be disabled"
                      />
                    }
                    onConfirm={() => disableChannel({ id: channel.id })}
                    confirmButtonTitle="Confirm"
                    cancelButtonTitle="Cancel"
                    confirmButtonVariant="redBackground"
                    cancelButtonVariant="grayBackground"
                  >
                    <CustomDropdownMenuItemWarning
                      data-testid="disable-option"
                      onClick={(e) => e.preventDefault()}
                    >
                      Disable Channel
                    </CustomDropdownMenuItemWarning>
                  </ConfirmationDialog>
                )}
                {isChannelDisabled && (
                  <>
                    <ConfirmationDialog
                      width="432px"
                      title="Enable this Channel"
                      description={
                        <ConfirmationDialogDescription
                          value={channel.name ?? ''}
                          description="channel will be enabled"
                        />
                      }
                      onConfirm={() =>
                        enableChannel({
                          id: channel.id,
                        })
                      }
                      confirmButtonTitle="Confirm"
                      cancelButtonTitle="Cancel"
                      confirmButtonVariant="primary"
                      cancelButtonVariant="grayBackground"
                    >
                      <CustomDropdownMenuItem
                        data-testid="enable-option"
                        onClick={(e) => e.preventDefault()}
                      >
                        Enable Channel
                      </CustomDropdownMenuItem>
                    </ConfirmationDialog>
                    <ConfirmationDialog
                      title="Archive Channel"
                      description="You can only delete steps when there are no active contacts in the sequence. Are you sure you want to delete this sequence step?"
                      confirmButtonTitle="Confirm"
                      cancelButtonTitle="Cancel"
                      confirmButtonVariant="redBackground"
                      cancelButtonVariant="grayBackground"
                      onConfirm={() =>
                        archiveChannel({
                          id: channel.id,
                        })
                      }
                    >
                      <CustomDropdownMenuItemWarning
                        data-testid="archive-option"
                        onClick={(e) => e.preventDefault()}
                      >
                        Archive Channel
                      </CustomDropdownMenuItemWarning>
                    </ConfirmationDialog>
                  </>
                )}
              </>
            </TableActionMenu>
          );
        },
      },
    ],
    [filteredChannels]
  );

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateFilterParams(props);
    }, 1000),
    []
  );

  const handleQuickSearch = (
    debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
    filters: SearchFilters,
    value: string
  ) => {
    const formattedValue = value.trim();
    const quickFilters = formattedValue
      ? [
          {
            column: 'name',
            comparison: 'ilike',
            value: `%${formattedValue}%`,
            resource: 'location',
          },
        ]
      : [];
    debouncedUpdate({
      ...filters,
      searchFilter: quickFilters,
      offset: 0,
    });
  };

  return (
    <Box>
      <TabsContainer align="center">
        <HStack>
          <SequenceSubNavigationItem
            selected={isEnabledTabActive}
            onClick={() => {
              updateFilterParams({
                ...filterParams,
                defaultFilters: [
                  {
                    column: 'state',
                    comparison: '==',
                    resource: 'location',
                    value: 'enabled',
                  },
                ],
              });
              setIsEnabledTabActive(true);
            }}
          >
            Enabled
          </SequenceSubNavigationItem>
          <SequenceSubNavigationItem
            selected={!isEnabledTabActive}
            onClick={() => {
              setIsEnabledTabActive(false);
              updateFilterParams({
                ...filterParams,
                defaultFilters: [
                  {
                    column: 'state',
                    comparison: '==',
                    resource: 'location',
                    value: 'disabled',
                  },
                ],
              });
            }}
          >
            Disabled
          </SequenceSubNavigationItem>
        </HStack>
      </TabsContainer>
      <StepsContainer
        direction="column"
        css={{
          padding: 24,
          height: 'calc(100vh - 205px)',
        }}
      >
        <Flex direction="column" css={{ flex: '1 1 auto' }}>
          <Box css={{ paddingBottom: 24 }}>
            <CombinedFilters
              quickSearchPlaceholder="Search Channels"
              quickSearchValue={quickSearchValue}
              setQuickSearchValue={(value: string) => {
                setQuickSearchValue(value);
                handleQuickSearch(debouncedUpdate, filterParams, value);
              }}
              defaultObjects={[default_locations_object]}
              customObjects={[]}
              activeFilters={activeFilters}
              setFilters={(value: Array<FilterType>) => {
                setActiveFilters(value);
                handleFilterChange(debouncedUpdate, filterParams, value);
              }}
              sortConfig={sortConfig}
              activeSort={filterParams.sort}
              onSortUpdate={(value: Array<Sort>) =>
                handleSortChange(debouncedUpdate, filterParams, value)
              }
            />
          </Box>
          <Table
            data={data}
            columns={columns}
            caption="Channels Table"
            totalCount={totalCount}
            emptyTitle="No Channels Match Search"
            setOffset={(offset) => {
              updateFilterParams({
                ...filterParams,
                offset,
              });
            }}
            isLoading={loading}
            pageSize={PAGE_SIZE}
            empty={
              <Box>
                <Box
                  css={{
                    width: 250,
                    fontSize: 16,
                    marginTop: 8,
                    marginBottom: 16,
                    fontWeight: 400,
                  }}
                >
                  <Box css={{ padding: '6.5px 0' }}>No channels were found.</Box>
                  <Box>
                    <AddChannel />
                  </Box>
                </Box>
              </Box>
            }
          />
        </Flex>
      </StepsContainer>
    </Box>
  );
};

export const ChannelAddress = styled(Box, {
  color: '#718196',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 250,
  textOverflow: 'ellipsis',
});

export const ChannelName = styled(Box, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 250,
  textOverflow: 'ellipsis',
});

export const EmptyStateDescription = () => (
  <>
    <Box css={{ mt: 12, mb: 18, fontSize: 15 }}>
      {
        "Channels are the unique physical addresses of your business, which customers interact with. Each channel has it's own phone number for customers and employees to communicate via sms."
      }
    </Box>
    <Box css={{ mb: 18, fontSize: 15 }}>
      {"You don't have any channels yet. Add a new channel to get started."}
    </Box>
  </>
);
