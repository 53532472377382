import { Call } from '@twilio/voice-sdk';
import React, { useEffect, useState } from 'react';
import {
  HiCog,
  HiDotsHorizontal,
  HiMicrophone,
  HiPhone,
  HiUserAdd,
} from 'react-icons/hi';

import { searchContacts } from '@/shared/api/contacts/v2';
import {
  KeypadIcon,
  MutedMicIcon,
  PauseCallIcon,
  TransferCallIcon,
} from '@/shared/components/Icons';
import { Contact } from '@/shared/types';
import {
  Avatar,
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Flex,
  IconButton,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { getAuthTokens } from '@/shared/utils/storage';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';

import { showContactIcon } from '../inbox/list/ConversationPreview';
import { CustomDropdownMenuItem } from '../settings/organization/users/UsersTable';
import { useVoIP } from './context/VoIPContext';
import { Timer } from './Timer';
import { CallHeader, VoIPDialog } from './VoIPDialog';
import { VoIPKeypad } from './VoIPKeypad';
import { VoIPSettings } from './VoIPSettings';

export const VoIPCall = ({ call }: { call?: Call | null }) => {
  const voip = useVoIP();

  const [contact, setContact] = useState<Contact | null>(null);

  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [showKeypad, setShowKeypad] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);

  const tokens = getAuthTokens();

  useEffect(() => {
    if (call && (call?.parameters?.From || call?.customParameters?.get('To'))) {
      findContact(call?.parameters?.From || call?.customParameters?.get('To') || '');
    }
  }, [call]);

  const findContact = async (phone: string) => {
    const contacts = await searchContacts([
      {
        column: 'phone',
        comparison: '==',
        resource: 'contact',
        value: phone,
      },
    ]);
    if (contacts.data.length) {
      setContact(contacts.data[0]);
    }
  };

  const muteCall = () => {
    call?.mute(!isMuted);
    setIsMuted(!isMuted);
  };

  const openKeypad = () => {
    setShowSettings(false);
    setShowKeypad(!showKeypad);
  };

  const openSettings = () => {
    setShowKeypad(false);
    setShowSettings(!showSettings);
  };

  return (
    <VoIPDialog
      resizable={!showKeypad && !showSettings}
      defaultSize={{ width: 280, height: 275 }}
      defaultStyles={{
        maxWidth: 420,
        maxHeight: 360,
        minWidth: 270,
        minHeight: 190,
      }}
      header={
        <CallHeader justify="between">
          <Flex align="center" justify="between" css={{ width: '100%' }}>
            <Flex>
              <Box css={{ mr: 8 }}>{tokens?.name || tokens?.email}</Box>
              <Timer start={new Date()} />
            </Flex>
            <ComingSoonTooltip>
              <IconButton variant="darkGray" css={{ background: 'transparent' }}>
                <HiUserAdd />
              </IconButton>
            </ComingSoonTooltip>
          </Flex>
        </CallHeader>
      }
      sidePanel={showKeypad ? <VoIPKeypad /> : showSettings ? <VoIPSettings /> : null}
    >
      <Box
        data-testid="voip-call"
        css={{ width: '100%', height: '100%', py: 8, fontSize: 14, color: 'white' }}
      >
        <Box css={{ overflowY: 'scroll', height: 'calc(100% - 40px)' }}>
          <Box>
            <Flex align="center" css={{ px: 12, py: 8 }}>
              <Avatar
                data-testid="current-user-avatar"
                size="2"
                variant="lightGray"
                src={tokens?.attachment?.url}
                fallback={initials(tokens?.name || tokens?.email)}
              />
              <Box data-testid="current-user-name" css={{ ml: 8 }}>
                {tokens?.name || tokens?.email}
              </Box>
              <Box css={{ color: '#ADB1B8', ml: 8 }}>You</Box>
            </Flex>
            <Flex align="center" css={{ px: 12, py: 8 }}>
              <Avatar
                data-testid="user-avatar"
                size="2"
                variant="lightGray"
                src={
                  showContactIcon(contact?.name || '')
                    ? `${window.location.origin}/outline.svg`
                    : ''
                }
                alt={contact?.name || 'No name'}
                fallback={initials(contact?.name || '')}
              />
              <Box data-testid="user-phone" css={{ ml: 8 }}>
                {formatPhoneNumber(
                  call?.parameters?.From || call?.customParameters?.get('To') || ''
                ) || '-'}
              </Box>
            </Flex>
            {isMuted && (
              <Flex align="center" css={{ color: '#FF8589', fontSize: 12, p: 12 }}>
                <HiMicrophone />
                <Box css={{ ml: 4 }}>Microphone muted</Box>
              </Flex>
            )}
          </Box>
        </Box>
        <Flex gap="2" justify="between" css={{ px: 12, py: 4, fontSize: 16 }}>
          <IconButton data-testid="mute-call-btn" variant="green" onClick={muteCall}>
            {isMuted ? <MutedMicIcon fill="#fff" /> : <HiMicrophone />}
          </IconButton>
          <Flex gap="2">
            <ComingSoonTooltip>
              <IconButton data-testid="record-call-btn" variant="darkGray">
                <Box>
                  <Box
                    css={{ width: 8, height: 8, borderRadius: 4, background: '#E5484D' }}
                  ></Box>
                  <Box css={{ color: '#E5484D', marginTop: 4, fontSize: 11 }}>Rec</Box>
                </Box>
              </IconButton>
            </ComingSoonTooltip>
            <IconButton data-testid="keypad-btn" variant="darkGray" onClick={openKeypad}>
              <KeypadIcon fill="#ADB1B8" />
            </IconButton>
            <ComingSoonTooltip>
              <IconButton data-testid="pause-call-btn" variant="darkGray">
                <PauseCallIcon fill="#ADB1B8" />
              </IconButton>
            </ComingSoonTooltip>
            <Flex align="center" direction="column" css={{ width: '100%' }}>
              <DropdownMenu modal={false}>
                <DropdownMenuTrigger asChild>
                  <IconButton
                    data-testid="actions-trigger"
                    variant="darkGray"
                    css={{ width: 32, height: 32, fontSize: 12 }}
                  >
                    <HiDotsHorizontal />
                  </IconButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  hideWhenDetached
                  css={{
                    padding: 8,
                    width: 200,
                    minWidth: 150,
                    boxShadow: '0px 12px 60px 0px #0000000E',
                    background: '#1B1B1F',
                    border: '1px solid #BDC8FF17',
                  }}
                  align="start"
                  side="left"
                  sideOffset={5}
                >
                  <ComingSoonTooltip>
                    <CustomDropdownMenuItem
                      css={{ color: '#ADB1B8', fontSize: 16 }}
                      data-testid="put-call-on-hold-option"
                    >
                      <>
                        <PauseCallIcon fill="#ADB1B8" />
                        <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
                          Put call on hold
                        </Box>
                      </>
                    </CustomDropdownMenuItem>
                  </ComingSoonTooltip>
                  <ComingSoonTooltip>
                    <CustomDropdownMenuItem
                      css={{ color: '#ADB1B8', fontSize: 16 }}
                      data-testid="add-people-option"
                    >
                      <HiUserAdd />
                      <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
                        Add people
                      </Box>
                    </CustomDropdownMenuItem>
                  </ComingSoonTooltip>
                  <ComingSoonTooltip>
                    <CustomDropdownMenuItem
                      css={{ color: '#ADB1B8', fontSize: 16 }}
                      data-testid="transfer-call-option"
                    >
                      <TransferCallIcon fill="#ADB1B8" />
                      <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
                        Transfer call
                      </Box>
                    </CustomDropdownMenuItem>
                  </ComingSoonTooltip>
                  <hr style={{ color: '#D2DFFF28', margin: 8 }} />
                  <CustomDropdownMenuItem
                    css={{ color: '#ADB1B8', fontSize: 16 }}
                    data-testid="mute-option"
                  >
                    <MutedMicIcon fill="#ADB1B8" />
                    <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
                      {isMuted ? 'Unmute mic' : 'Mute mic'}
                    </Box>
                  </CustomDropdownMenuItem>
                  <CustomDropdownMenuItem
                    css={{ color: '#ADB1B8', fontSize: 16 }}
                    data-testid="keypad-option"
                    onClick={openKeypad}
                  >
                    <KeypadIcon fill="#ADB1B8" />
                    <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
                      Show keypad
                    </Box>
                  </CustomDropdownMenuItem>
                  <CustomDropdownMenuItem
                    onClick={openSettings}
                    css={{ color: '#ADB1B8', fontSize: 16 }}
                    data-testid="settings-option"
                  >
                    <HiCog />
                    <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
                      Audio settings
                    </Box>
                  </CustomDropdownMenuItem>
                  <hr style={{ color: '#D2DFFF28', margin: 8 }} />
                  <CustomDropdownMenuItem
                    onClick={voip.hangUp}
                    css={{ color: '#FF858A', fontSize: 16 }}
                    data-testid="end-call-option"
                  >
                    <HiPhone style={{ transform: 'rotate(135deg)', margin: 0 }} />
                    <Box style={{ marginLeft: 12, fontSize: 14 }}>End call</Box>
                  </CustomDropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </Flex>
          </Flex>
          <IconButton data-testid="end-call-btn" variant="red" onClick={voip.hangUp}>
            <HiPhone style={{ transform: 'rotate(135deg)', margin: 0 }} />
          </IconButton>
        </Flex>
      </Box>
    </VoIPDialog>
  );
};

const ComingSoonTooltip = ({ children }: { children: React.ReactNode }) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent side="bottom">
        Coming Soon
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};
