/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { HiMinus, HiPaperAirplane, HiPlus, HiSave } from 'react-icons/hi';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { CampaignAutomations } from '@/pages/campaigns/automations';
import { useCampaignsContext } from '@/pages/campaigns/context/CampaignsContext';
import { Description, getIntervalValue } from '@/pages/campaigns/create';
import { Accordion, CampaignAccordion } from '@/pages/campaigns/create/CampaignAccordion';
import { CampaignBasicInfo } from '@/pages/campaigns/create/CampaignBasicInfo';
import { CampaignConfirmation } from '@/pages/campaigns/create/CampaignConfirmation';
import { CampaignMessageEditor } from '@/pages/campaigns/create/CampaignMessageEditor';
import { CampaignPreview } from '@/pages/campaigns/create/CampaignPreview';
import { CampaignSchedule } from '@/pages/campaigns/create/CampaignSchedule';
import { CampaignToolbar } from '@/pages/campaigns/create/CampaignToolbar';
import { V2CampaignPanel } from '@/pages/campaigns/create/panel/V2CampaignPanel';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { getAutomationTemplate } from '@/shared/api/automations/templates';
import { getAudienceV2 } from '@/shared/api/campaigns';
import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { fillPipeFields } from '@/shared/components/editor/Pipes';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { AdvancedFilterBuilder } from '@/shared/components/filterBuilder/AdvancedFilterItem';
import {
  campaign_contact_campaign_id,
  default_campaign_contact_object,
} from '@/shared/components/filterBuilder/objects/campaign_contact';
import { default_communication_preference_object } from '@/shared/components/filterBuilder/objects/communication_preference';
import { default_contact_object } from '@/shared/components/filterBuilder/objects/contact';
import { default_contact_list_object } from '@/shared/components/filterBuilder/objects/contact_list';
import { default_contact_tag_object } from '@/shared/components/filterBuilder/objects/contact_tag';
import { default_conversation_object } from '@/shared/components/filterBuilder/objects/conversation';
import { default_sequence_run_object } from '@/shared/components/filterBuilder/objects/sequence_run';
import { areFiltersValid } from '@/shared/components/filterBuilder/utils/areValidFilters';
import { cleanFilters } from '@/shared/components/filterBuilder/utils/cleanFilters';
import { rebuildFilters } from '@/shared/components/filterBuilder/utils/rebuildFilters';
import { SidebarNavigationContainer } from '@/shared/components/navigation/SideNavigationContainer';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { AutomationTemplate } from '@/shared/types/automations';
import {
  AccordionValue,
  BatchDelayTimeUnit,
  Campaign,
  CampaignSettings,
  CampaignStatus,
  PanelType,
  ScheduleOptions,
} from '@/shared/types/campaigns';
import { ChannelTypes } from '@/shared/types/channels';
import { Contact } from '@/shared/types/contacts';
import { FilterItem, FilterType } from '@/shared/types/filter';
import {
  Box,
  Button,
  ControlGroup,
  Drawer,
  DrawerContent,
  DrawerPortal,
  Fieldset,
  Flex,
  HStack,
  IconButton,
  Input,
  Label,
  Switch,
  SwitchThumb,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
  VStack,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import AudienceQuickFilter from '../components/AudienceQuickFilter';
import { getAllFilterItemsByType, replaceFirstFilterItem } from '../components/utils';
import {
  appendFilterItemsToExcludedSystemDefault,
  appendOrCondition,
  findFilterItem,
  hasMoreThanSystemExcludedFilter,
  isValidQuickFilter,
  removeExcludedSystemDefault,
  removeOrCondition,
  translateToAdvancedFilter,
} from '../utils';

const customContactObject = structuredClone(default_contact_object);
customContactObject.custom_properties.unshift({
  default: '',
  id: 'contact-id',
  label: 'ID',
  type: 'text',
  key: 'id',
  required: true,
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_object_id: 'unique-id-for-contact',
});

const CreateSMSCampaign = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const campaignRouteParams = useParams<{ id?: string }>();
  // if the route specifies an id, make sure we're only editing
  if (campaignRouteParams.id && !location.pathname.endsWith('/edit')) {
    history.replace(`${location.pathname}/edit`);
  }
  // props needed for advanced
  const [_loading, setLoading] = useState(true);

  // props needed for advanced
  // campaign state
  const [currentEditor, setCurrentEditor] = useState();
  const {
    campaignsState: { current },
    setCurrentCampaign,
    setShowConfetti,
    getCampaignV2,
    createV2Campaign,
    updateV2Campaign,
  } = useCampaignsContext();
  const [includeAudienceTabValue, setIncludeAudienceTabValue] = useState<
    'included-advanced-filter' | 'included-quick-filter'
  >(() => {
    const includedFilter = current?.included_audience_filter?.filter ?? [];
    return !isValidQuickFilter(includedFilter, 'included')
      ? 'included-advanced-filter'
      : 'included-quick-filter';
  });
  const [excludeAudienceTabValue, setExcludeAudienceTabValue] = useState<
    'excluded-advanced-filter' | 'excluded-quick-filter'
  >(() => {
    const excludedFilterWithoutSystem = removeExcludedSystemDefault(
      current?.excluded_audience_filter?.filter ?? [],
      []
    );
    return !isValidQuickFilter(excludedFilterWithoutSystem, 'excluded')
      ? 'excluded-advanced-filter'
      : 'excluded-quick-filter';
  });
  const [includedContacts, setIncludedContacts] = useState<Contact[]>([]);
  const [excludedContacts, setExcludedContacts] = useState<Contact[]>([]);
  const [includedAudienceFilter, setIncludedAudienceFilter] = useState<
    FilterItem[] | null
  >(current?.included_audience_filter?.filter ?? null);
  const [excludedAudienceFilter, setExcludedAudienceFilter] = useState<FilterItem[]>(
    current?.excluded_audience_filter?.filter ?? []
  );

  const customDefaultObjects = useMemo(() => {
    return [
      customContactObject,
      default_contact_tag_object,
      default_contact_list_object,
      {
        ...default_campaign_contact_object,
        custom_properties: [
          campaign_contact_campaign_id,
          ...default_campaign_contact_object.custom_properties,
        ],
      },
      default_sequence_run_object,
      default_conversation_object,
      default_communication_preference_object,
    ];
  }, []);
  const [includedFilterType, setIncludedAudienceFilterType] = useState<FilterType[]>(
    () => {
      const advancedFilter = translateToAdvancedFilter(
        includedAudienceFilter ?? []
      ) as FilterType[];
      return rebuildFilters([], advancedFilter, customDefaultObjects);
    }
  );
  const [excludedFilterType, setExcludedAudienceFilterType] = useState<FilterType[]>(
    () => {
      const excludedFilterWithoutSystem = removeExcludedSystemDefault(
        excludedAudienceFilter,
        []
      );
      const advancedFilter = translateToAdvancedFilter(
        excludedFilterWithoutSystem ?? []
      ) as FilterType[];
      return rebuildFilters([], advancedFilter, customDefaultObjects);
    }
  );

  // all as-is templates under this campaign
  const [existingAllAutomationTemplates, setExistingAllAutomationTemplates] = useState<
    Array<AutomationTemplate>
  >([]);

  const maybeUpdateLastCampaignSinceFilter = (
    showExcludeCampaignSince: boolean,
    excludeCampaignSince: number | null
  ) => {
    if (showExcludeCampaignSince) {
      const daysAgo = moment().subtract(excludeCampaignSince, 'day').format('YYYY-MM-DD');
      setExcludeCampaignTimeSinceFilter({
        column: 'last_campaign_date',
        comparison: '>',
        resource: 'communication_preference',
        value: daysAgo,
      });
    } else {
      setExcludeCampaignTimeSinceFilter(null);
    }
  };

  // fetch the existing as-is templates under this campaign
  const fetchTemplates = async () => {
    const existingAssociatedAutomationTemplates =
      current?.associated_automation_templates || [];
    // extract the automation_template_id from each existingAssociatedAutomationTemplates
    const existingAllAutomationTemplateIds = existingAssociatedAutomationTemplates.map(
      (template: {
        automation_template_id: string;
        campaign_automation_template_id: string;
      }) => template.automation_template_id
    );
    const existingAllAutomationTemplatesPromises: Array<Promise<AutomationTemplate>> =
      existingAllAutomationTemplateIds.map((id: string) => getAutomationTemplate(id));
    try {
      const templates = await Promise.all(existingAllAutomationTemplatesPromises);
      setExistingAllAutomationTemplates(templates);
    } catch (error) {
      console.error('Error fetching automation templates:', error);
    }
  };

  /*
   * This useEffect is used to handle updating the state after
   * asynchronously loading the campaign. So if the campaign starts as null then
   * later gets loaded we should update things like
   * - Included Audience Filter
   * - The Quick Filter or Advanced Filter (in case the newly loaded audience filter is complex)
   * - The Audience itself
   */
  useEffect(() => {
    fetchTemplates();
    if (current) {
      const { included_audience_filter, excluded_audience_filter } = current;
      setCampaignTitle(current.title ?? '');
      setChannelId(current.channel_id ?? '');
      const isIncludedAudienceValidQuickFilter = isValidQuickFilter(
        included_audience_filter?.filter ?? [],
        'included'
      );
      // remove the system defaults from the excludedFilter
      const excludedFilterWithoutSystem = removeExcludedSystemDefault(
        excluded_audience_filter?.filter ?? [],
        []
      );
      const isExcludedAudienceValidQuickFilter = isValidQuickFilter(
        excludedFilterWithoutSystem,
        'excluded'
      );
      setIncludeAudienceTabValue(
        isIncludedAudienceValidQuickFilter
          ? 'included-quick-filter'
          : 'included-advanced-filter'
      );
      setExcludeAudienceTabValue(
        isExcludedAudienceValidQuickFilter
          ? 'excluded-quick-filter'
          : 'excluded-advanced-filter'
      );
      setIncludedAudienceFilter(current.included_audience_filter?.filter ?? null);
      setExcludedAudienceFilter(current.excluded_audience_filter?.filter ?? []);
      const advancedIncludeFilter = translateToAdvancedFilter(
        included_audience_filter?.filter ?? []
      ) as FilterType[];
      setIncludedAudienceFilterType(
        rebuildFilters([], advancedIncludeFilter, customDefaultObjects)
      );
      const advancedExcludeFilter = translateToAdvancedFilter(
        excludedFilterWithoutSystem
      ) as FilterType[];
      setExcludedAudienceFilterType(
        rebuildFilters([], advancedExcludeFilter, customDefaultObjects)
      );
      const maybeCampaignTimeSinceFilter = hasCampaignTimeSinceFilter(
        current.excluded_audience_filter?.filter ?? []
      );
      const maybeOpenConversationFilter = hasOpenConversationFilter(
        current.excluded_audience_filter?.filter ?? []
      );
      if (maybeOpenConversationFilter) {
        setExcludeContactsWithConversations(true);
        setExcludeOpenConversationFilter(maybeOpenConversationFilter);
      }
      if (maybeCampaignTimeSinceFilter) {
        setShowExcludeContactsWithOtherCampaigns(true);
        const today = moment();
        const daysDifference: number = today.diff(
          maybeCampaignTimeSinceFilter.value as string,
          'days'
        );
        setExcludeCampaignSince(daysDifference);
      }
      setExcludedAudienceFilter(current.excluded_audience_filter?.filter ?? []);
      setMessage(current.body ?? '');
    }
  }, [current]);

  async function fetchCampaign(id: string) {
    getCampaignV2(id);
    setLoading(false);
  }

  useEffect(() => {
    // if no campaign is set, or the set campaign
    // is complete, there load the campaign and
    // the campaign results if they exist
    if (
      !current ||
      // there's a chance that if we come from the v1 list campaign screen
      // that this does not include the current.included audience filter
      // so we need an additional fetch to the v2 route in this case
      !current.included_audience_filter ||
      current.id !== campaignRouteParams.id ||
      (current.status === CampaignStatus.COMPLETE && !current.analytics) ||
      (current.status === CampaignStatus.IN_PROGRESS && !current.analytics)
    ) {
      if (campaignRouteParams.id) {
        fetchCampaign(campaignRouteParams.id);
        setAccordion(AccordionValue.SELECT_AUDIENCE);
      }
    }
  }, [campaignRouteParams]);

  // set accordion state
  const [accordion, setAccordion] = useState<AccordionValue>(
    campaignRouteParams.id
      ? AccordionValue.SELECT_AUDIENCE
      : AccordionValue.SELECT_LOCATION
  );

  const { getChannelById } = useChannels();

  // campaign title
  const [campaignTitle, setCampaignTitle] = useState(current?.title || '');

  // channel to set the campaign from
  const [channelId, setChannelId] = useState<string>('');
  const locationObject = getChannelById(channelId);

  // is this a big screen?
  const isLargeDesktop = useMedia('(min-width: 1350px)');
  const isSmallScreen = !useMedia('(min-width: 1130px)');
  const isMobile = !useMedia('(min-width: 968px)');

  // should we exclude contacts with open conversation from the campaign?
  const [excludeContactsWithConversations, setExcludeContactsWithConversations] =
    useState<boolean>(false);
  const [excludeOpenConversationFilter, setExcludeOpenConversationFilter] =
    useState<FilterItem | null>(() => hasOpenConversationFilter(excludedAudienceFilter));
  // is the user excluding contacts that received other campaigns
  const [
    showExcludeContactsWithOtherCampaigns,
    setShowExcludeContactsWithOtherCampaigns,
  ] = useState<boolean>(false);

  // how many days if excludeContactsWithOtherCampaigns is true
  const [excludeCampaignSince, setExcludeCampaignSince] = useState<number | null>(() => {
    return initialTimeSinceCampaign(current);
  });
  const [excludeCampaignTimeSinceFilter, setExcludeCampaignTimeSinceFilter] =
    useState<FilterItem | null>(() => hasCampaignTimeSinceFilter(excludedAudienceFilter));

  // exclude an individual contact from included audience panel
  const handleExcludeContactFromAudience = (contact: Contact) => {
    const id = contact.id;
    let excludeFilterWithNoSystemDefaults = removeExcludedSystemDefault(
      excludedAudienceFilter,
      []
    );
    const contactFilterItems = getAllFilterItemsByType(
      'contact',
      excludeFilterWithNoSystemDefaults
    );
    if (contactFilterItems.length == 1) {
      const filterItem = contactFilterItems[0];
      const comparison = Array.isArray(filterItem.value) ? 'in' : '==';
      const value = Array.isArray(filterItem.value)
        ? [...filterItem.value, id]
        : [filterItem.value, id];
      replaceFirstFilterItem(
        excludeFilterWithNoSystemDefaults,
        {
          resource: filterItem.resource,
          column: 'id',
          comparison,
          value: value as string[],
        },
        (filterItem) => filterItem.resource == 'contact'
      );
    }
    // if the quick filter items has a resource contact that's one item
    // make it an in
    // if the quick filter items has a resource contact that's already more than one item
    // make append to the in
    else if (contactFilterItems.length > 1) {
      const filterItem = contactFilterItems[0];
      replaceFirstFilterItem(
        excludeFilterWithNoSystemDefaults,
        {
          resource: filterItem.resource,
          column: 'id',
          comparison: 'in',
          value: [...(filterItem.value as string[]), id],
        },
        (filterItem) => filterItem.resource == 'contact'
      );
    }
    // OTHERWISE make it a new filter item with ==
    else {
      excludeFilterWithNoSystemDefaults = appendOrCondition(
        excludeFilterWithNoSystemDefaults,
        {
          resource: 'contact',
          column: 'id',
          comparison: 'in',
          value: [id],
        }
      );
    }
    // we update the excluded contact
    setExcludedAudienceFilter(
      appendFilterItemsToExcludedSystemDefault(
        excludeFilterWithNoSystemDefaults,
        current?.channel_id ?? ''
      )
    );
    setExcludedAudienceFilterType(
      rebuildFilters(
        [],
        cleanFilters(excludeFilterWithNoSystemDefaults as FilterType[]),
        customDefaultObjects
      )
    );
  };

  // does the reduceAudienceToParams objects keys have a value
  // used to check if the audience is empty
  const hasIncludeAudience = (messageCount: number, campaign?: Campaign | null) => {
    // if we have a campaign but no channel id is set we can't determine the audience
    if (campaign && (campaign.channel_id == null || campaign.channel_id == undefined)) {
      return false;
    } else {
      // we can't create a campaign with no audience
      return messageCount > 0;
    }
  };

  // the number of messages that will be sent
  const [messageCount, setMessageCount] = useState<number>(0);

  // are we loading the contacts in the audience?
  const [loadingAudience, setLoadingAudience] = useState<boolean>(false);

  // handle campaign side panel state
  const [showPanel, setShowPanel] = useState(false);
  const [panel, setPanel] = useState<PanelType>(PanelType.VIEW_AUDIENCE);

  // open or close the campaign side panel
  const handlePanelClick = (panelType: PanelType): void => {
    if (panelType === panel) {
      setShowPanel(!showPanel);
    } else {
      setPanel(panelType);
      setShowPanel(true);
    }
  };

  // if an upload is added to the audience, and its still pending processing
  // then we want to disable the audience calculation
  const [disableAudienceCalculation, _setDisableAudienceCalculation] = useState(false);

  // get the list of contacts that will be included or excluded
  // if the disableAudienceCalculation state changes to
  // true then set the message count to 0 so that we don't show
  // the old message count or an in correct message count
  useEffect(() => {
    if (disableAudienceCalculation) {
      setMessageCount(0);
    }
  }, [disableAudienceCalculation]);

  useEffect(() => {
    if (!channelId) return;
    if (!includedAudienceFilter) return;

    setLoadingAudience(true);
    const totalIncludedAudienceFilter = structuredClone(includedAudienceFilter);
    let excludeWithoutSystemDefault = structuredClone(excludedAudienceFilter);
    if (hasMoreThanSystemExcludedFilter(excludedAudienceFilter)) {
      excludeWithoutSystemDefault = removeExcludedSystemDefault(
        excludedAudienceFilter,
        []
      );
    } else {
      excludeWithoutSystemDefault = [];
    }
    if (
      excludeOpenConversationFilter &&
      !hasOpenConversationFilter(excludeWithoutSystemDefault)
    ) {
      excludeWithoutSystemDefault = appendOrCondition(
        excludeWithoutSystemDefault,
        excludeOpenConversationFilter
      );
    }
    if (
      excludeCampaignTimeSinceFilter &&
      !hasCampaignTimeSinceFilter(excludeWithoutSystemDefault)
    ) {
      excludeWithoutSystemDefault = appendOrCondition(
        excludeWithoutSystemDefault,
        excludeCampaignTimeSinceFilter
      );
    }

    getAudienceV2(
      channelId,
      totalIncludedAudienceFilter ?? [],
      excludeWithoutSystemDefault
    ).then((data) => {
      setMessageCount(data.data.data.audience_count);
      setLoadingAudience(false);
      setIncludedContacts(data.data.data.audience);
      setExcludedContacts(data.data.data.excluded_contacts);
    });
  }, [
    includedAudienceFilter,
    excludedAudienceFilter,
    excludeOpenConversationFilter,
    excludeCampaignTimeSinceFilter,
    channelId,
  ]);

  // message body state
  const [message, setMessage] = useState(current?.body || '');

  // message attachments state
  const [attachments, setAttachments] = useState<Attachments>({
    attachment_urls: current?.attachment_urls || [],
  });

  // schedule campaign state
  const [isScheduled, setIsScheduled] = useState(
    current?.schedule_options?.day ? true : false
  );

  // set the date from the schedule params if it exists
  // otherwise set the date to the current date
  const [date, setDate] = useState<string>(
    current?.schedule_options?.day &&
      current?.schedule_options?.month &&
      current?.schedule_options?.year
      ? `${current.schedule_options?.month}/${current?.schedule_options.day}/${current?.schedule_options.year}`
      : dayjs(new Date()).format('MM/DD/YYYY')
  );

  // set the time from the schedule params if it exists
  // if it doesn't exist, set the time to the current time
  const [time, setTime] = useState(
    current?.schedule_options?.hour && current?.schedule_options?.minute
      ? `${current?.schedule_options?.hour}:${current?.schedule_options?.minute}`
      : ''
  );

  // set the timezone from the schedule params if it exists
  // or use the default browser timezone
  const [time_zone, setTimezone] = useState(
    current?.schedule_options?.timezone
      ? current?.schedule_options?.timezone
      : Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  // send campaign in batches?
  const [enableBatchSend, setEnableBatchSend] = useState(
    current?.delivery_options?.period && current?.delivery_options?.messages_per_period
      ? true
      : false
  );

  // number of messages to send per batch
  const [batchSize, setBatchSize] = useState<number | null>(
    current?.delivery_options?.messages_per_period || 100
  );

  // period time unit for batch sending
  const [batchDelayTimeUnit, setBatchDelayTimeUnit] = useState(
    BatchDelayTimeUnit.MINUTES
  );

  // number of minutes/hours/days between batches, default is minutes
  const [batchDelay, setBatchDelay] = useState<number | null>(
    current?.delivery_options?.period ? current?.delivery_options?.period / 60 : 5
  );

  // show the user the campaign settings
  const [enableCampaignSettings, setEnableCampaignSettings] = useState(false);
  // show the user the campaign link tracking settings
  const [enableLinkTrackingSettings, setEnableLinkTrackingSettings] = useState(false);

  // id any of the three current campaign settings are enabled, then show the user the campaign settings on mount
  useEffect(() => {
    if (
      current?.settings?.support_sms_quiet_hours?.enabled ||
      current?.settings?.skip_sending_on_weekend?.enabled ||
      current?.settings?.only_send_during_business_hours?.enabled
    ) {
      setEnableCampaignSettings(true);
    }
    if (
      current?.settings?.link_tracking?.disabled ||
      current?.settings?.link_tracking?.domain_id
    ) {
      setEnableLinkTrackingSettings(true);
    }
  }, []);

  // campaign settings options for sending and scheduling messages
  const [campaignSettings, setCampaignSettings] = useState<CampaignSettings>({
    support_sms_quiet_hours: {
      enabled: current?.settings?.support_sms_quiet_hours?.enabled || false,
      use_contact_timezone:
        current?.settings?.support_sms_quiet_hours?.use_contact_timezone || false,
    },
    skip_sending_on_weekend: {
      enabled: current?.settings?.skip_sending_on_weekend?.enabled || false,
      use_contact_timezone:
        current?.settings?.skip_sending_on_weekend?.use_contact_timezone || false,
    },
    only_send_during_business_hours: {
      enabled: current?.settings?.only_send_during_business_hours?.enabled || false,
      use_contact_timezone:
        current?.settings?.only_send_during_business_hours?.use_contact_timezone || false,
    },
    link_tracking: {
      disabled: current?.settings?.link_tracking?.disabled || false,
      domain_id: current?.settings?.link_tracking?.domain_id || null,
    },
  });

  // combine date, time and the timezone state and check it is a
  // validate time in the future use dayjs.tz timezone
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const [isValidSchedule, setIsValidSchedule] = useState(false);

  // when the schedule state changes, check if the schedule is valid
  useEffect(() => {
    if (date && time && time_zone) {
      const validTime = dayjs.tz(`${date} ${time}`, time_zone).isValid();
      // is the time valid and in the future
      if (validTime) {
        setIsValidSchedule(true);
      } else {
        setIsValidSchedule(false);
      }
    }
  }, [date, time, time_zone]);

  // show the batch delay in correct time units
  useEffect(() => {
    if (current?.delivery_options?.period) {
      const delayInMinutes = current?.delivery_options?.period / 60;
      const hourInMinutes = getIntervalValue(BatchDelayTimeUnit.HOURS);
      const dayInMinutes = getIntervalValue(BatchDelayTimeUnit.DAYS);
      if (delayInMinutes >= hourInMinutes && delayInMinutes % hourInMinutes === 0) {
        setBatchDelay(delayInMinutes / hourInMinutes);
        setBatchDelayTimeUnit(BatchDelayTimeUnit.HOURS);
      }
      if (delayInMinutes >= dayInMinutes && delayInMinutes % dayInMinutes === 0) {
        setBatchDelay(delayInMinutes / dayInMinutes);
        setBatchDelayTimeUnit(BatchDelayTimeUnit.DAYS);
      }
    }
  }, [current?.delivery_options?.period]);

  // if the channel is specified make sure to update the campaign type
  useEffect(() => {
    const channelFromLocalState = getChannelById(channelId);
    if (channelFromLocalState) {
      switch (channelFromLocalState.type) {
        case ChannelTypes.EMAIL: {
          campaign_params.type = 'email';
          break;
        }
        default: {
          campaign_params.type = 'sms';
          break;
        }
      }
    }
  }, [channelId]);

  // create schedule object
  const schedule_params: ScheduleOptions = {
    day: `${dayjs(date).date()}`,
    month: `${dayjs(date).month() + 1}`,
    year: `${dayjs(date).year()}`,
    hour: `${dayjs(`${date} ${time}`).hour()}`,
    minute: `${dayjs(`${date} ${time}`).format('mm')}`,
    timezone: time_zone,
  };

  const delivery_options = {
    // convert minutes to seconds
    period: batchDelay ? batchDelay * getIntervalValue(batchDelayTimeUnit) * 60 : 300,
    messages_per_period: batchSize,
  };

  const campaign_params: Campaign = {
    title:
      campaignTitle ||
      `untitled-campaign-${dayjs(new Date()).format('YYYY-MM-DD')}T${dayjs(
        new Date()
      ).format('H:mm:ss')}`,
    status: CampaignStatus.DRAFT,
    body: message,
    attachment_urls: attachments.attachment_urls,
    channel_id: channelId,
    // if we have not set the included audience filter, start it as null
    included_audience_filter: includedAudienceFilter
      ? {
          filter: includedAudienceFilter,
        }
      : null,
    excluded_audience_filter: excludedAudienceFilter
      ? {
          filter: excludedAudienceFilter,
        }
      : null,
    schedule_options: isScheduled ? schedule_params : null,
    delivery_options: enableBatchSend ? delivery_options : null,
    settings: campaignSettings,
  };

  const updateCurrentCampaign = async (params: Campaign, id?: string) => {
    // update the campaign if not null
    if (!id) return;
    // calculate and update the logic of appending the two campaign exclude rules separately
    let currentExcludedAudienceFilter = excludedAudienceFilter ?? [];
    if (excludeOpenConversationFilter) {
      currentExcludedAudienceFilter = appendOrCondition(
        currentExcludedAudienceFilter,
        excludeOpenConversationFilter
      );
    }
    if (excludeCampaignTimeSinceFilter) {
      currentExcludedAudienceFilter = appendOrCondition(
        currentExcludedAudienceFilter,
        excludeCampaignTimeSinceFilter
      );
    }
    updateV2Campaign(
      id,
      {
        ...params,
        excluded_audience_filter: {
          filter: currentExcludedAudienceFilter,
        },
      },
      true
    );
    setExcludedAudienceFilter(currentExcludedAudienceFilter);
    // updateV2Campaign(id, params, true);
  };

  // save the current campaign, either create or update
  const handleSave = async (accordion: AccordionValue) => {
    if (current && current?.id) {
      await updateCurrentCampaign(campaign_params, current.id);
      setAccordion(accordion);
    } else {
      const campaign = await createV2Campaign(campaign_params);
      setCurrentCampaign(campaign);
      setAccordion(accordion);

      // if the campaign has and id then redirect to the campaign page
      if (campaign && campaign.id) {
        history.push(`/campaigns/sms/${campaign.id}/edit`);
      }
    }
  };

  // send or schedule the current campaign
  const handleSendCampaign = async () => {
    const send_campaign_params = {
      ...campaign_params,
      status: isScheduled ? CampaignStatus.SCHEDULED : CampaignStatus.IN_PROGRESS,
    };

    if (current && current.id) {
      const response = await updateV2Campaign(current.id, send_campaign_params, true);
      // if request does not fail show confetti and redirect
      if (response) {
        setShowConfetti();
        history.push(`/campaigns`);
      }
    }
  };

  const auth = useAuth();

  const [previewName, setPreviewName] = useState(auth?.tokens?.name || 'John Doe');

  const isValidAudience = useMemo(() => {
    const campaign = current;
    if (campaign && (campaign.channel_id == null || campaign.channel_id == undefined)) {
      return false;
    } else if (!includedAudienceFilter || includedAudienceFilter.length == 0) {
      return false;
      // we can not submit an empty included advanced filter
    } else if (cleanFilters(includedFilterType).length === 0) {
      return false;
    } else if (
      !areFiltersValid(includedFilterType) ||
      !areFiltersValid(excludedFilterType)
    ) {
      return false;
    } else {
      // we can't create a campaign with no audience
      return messageCount > 0;
    }
  }, [
    current,
    includedAudienceFilter,
    includedFilterType,
    excludedAudienceFilter,
    messageCount,
  ]);

  const isIncludedFilterValidQuickFilter = useMemo(() => {
    return isValidQuickFilter(includedAudienceFilter, 'included');
  }, [includedAudienceFilter]);

  const isExcludedFilterValidQuickFilter = useMemo(() => {
    const excludedFilterWithoutSystem = removeExcludedSystemDefault(
      excludedAudienceFilter,
      []
    );
    return isValidQuickFilter(excludedFilterWithoutSystem, 'excluded');
  }, [excludedAudienceFilter]);

  const CustomStyleTabsTrigger = styled(TabsTrigger, {
    '&:hover': { cursor: isIncludedFilterValidQuickFilter ? 'pointer' : 'not-allowed' },
  });

  const fillDemoMessage = () => {
    const organizationName = auth?.organizationName;

    // fill message pipe fields
    const filled_body = fillPipeFields(
      message,
      previewName || 'John Doe',
      locationObject?.name || '',
      locationObject?.address || '',
      locationObject?.google_place_id || '',
      organizationName || ''
    );

    return filled_body;
  };

  const hasTriggersOrTemplates =
    (current && current.triggers && current.triggers.length > 0) ||
    (current &&
      current.associated_automation_templates &&
      current.associated_automation_templates.length > 0)
      ? true
      : false;

  return (
    <Flex css={{ minHeight: '100%' }}>
      <PageLayout
        breadcrumbs={[
          { title: 'Campaigns', path: '/campaigns' },
          {
            title: `${current?.title || 'Create New Campaign'}`,
            path: `/campaigns/${current?.id || 'create'}`,
          },
        ]}
        actions={
          <HStack gap={2}>
            {messageCount > 0 &&
              !loadingAudience &&
              !disableAudienceCalculation &&
              isValidAudience && (
                <Text css={{ mr: 10, display: isSmallScreen ? 'none' : undefined }}>
                  {`${messageCount || 0} Contacts`}
                </Text>
              )}
            {!isMobile ? (
              <Button
                variant="gray"
                onClick={() => handleSave(AccordionValue.DEFAULT_VALUE)}
                disabled={campaignTitle.length < 3}
              >
                Save as Draft
              </Button>
            ) : (
              <ToolTipIconButton
                variant="outline"
                size={2}
                icon={<HiSave />}
                description="Save as Draft"
                onClick={() => handleSave(AccordionValue.DEFAULT_VALUE)}
              />
            )}
            <CampaignPreview
              body={message}
              attachments={attachments.attachment_urls}
              location_id={channelId}
              campaignTitle={campaignTitle}
              previewName={previewName}
              setPreviewName={setPreviewName}
              fillDemoMessage={fillDemoMessage}
            />
            <CampaignConfirmation
              onConfirm={handleSendCampaign}
              numberOfContacts={disableAudienceCalculation ? 0 : messageCount}
              buttonCopy={isScheduled ? 'Schedule Campaign' : 'Send Campaign'}
              isScheduled={isScheduled}
            >
              {!isMobile ? (
                <Button
                  variant="send"
                  disabled={
                    !channelId ||
                    !hasIncludeAudience(messageCount, current) ||
                    message.length < 20 ||
                    campaignTitle.length < 2 ||
                    (isScheduled && !isValidSchedule) ||
                    time_zone == ''
                  }
                >
                  {isScheduled ? 'Schedule Campaign' : 'Send Campaign'}
                </Button>
              ) : (
                <ToolTipIconButton
                  variant="send"
                  size={2}
                  disabled={
                    !channelId ||
                    !hasIncludeAudience(messageCount, current) ||
                    message.length < 20 ||
                    campaignTitle.length < 2 ||
                    (isScheduled && !isValidSchedule) ||
                    time_zone == ''
                  }
                  icon={
                    <HiPaperAirplane
                      style={{
                        transform: 'rotate(90deg)',
                      }}
                    />
                  }
                  description={isScheduled ? 'Schedule Campaign' : 'Send Campaign'}
                  onClick={() => handleSave(AccordionValue.DEFAULT_VALUE)}
                />
              )}
            </CampaignConfirmation>
          </HStack>
        }
        width={`calc(100% - ${showPanel && isLargeDesktop ? '550px' : '55px'})`}
      >
        <Flex direction="column" css={{ pb: 200 }}>
          <Accordion
            type="single"
            defaultValue={AccordionValue.SELECT_LOCATION}
            value={accordion}
          >
            <VStack css={{ p: 20 }} gap="2">
              <CampaignBasicInfo
                accordion={accordion}
                setAccordion={setAccordion}
                campaignTitle={campaignTitle}
                setCampaignTitle={setCampaignTitle}
                location={channelId}
                setLocation={setChannelId}
                channelType={['phone']}
                handleSave={handleSave}
              />
              <CampaignAccordion
                index={2}
                title="Audience"
                description="Contacts who would receive this campaign"
                currentAccordionValue={accordion}
                itemValue={AccordionValue.SELECT_AUDIENCE}
                isValid={isValidAudience}
                isError={false}
                setItemValue={setAccordion}
              >
                <VStack css={{ gap: '24px' }}>
                  <Label css={{ marginBottom: '0px' }}>Include Contacts</Label>
                  <Tabs
                    value={includeAudienceTabValue}
                    onValueChange={(value) => {
                      setIncludeAudienceTabValue(
                        value as 'included-advanced-filter' | 'included-quick-filter'
                      );
                    }}
                    css={{
                      display: 'flex',
                      gap: '16px',
                      borderRadius: '8px',
                      border: '1px solid rgba(2, 2, 52, 0.08)',
                      marginTop: '0px',
                      background: '#FFF',
                    }}
                  >
                    <TabsList
                      css={{
                        whiteSpace: 'nowrap',
                        marginBottom: '0px',
                      }}
                    >
                      <CustomStyleTabsTrigger
                        value="included-quick-filter"
                        disabled={!isIncludedFilterValidQuickFilter}
                      >
                        Quick filters
                      </CustomStyleTabsTrigger>
                      <CustomStyleTabsTrigger value="included-advanced-filter">
                        Advanced filters
                      </CustomStyleTabsTrigger>
                    </TabsList>
                    <TabsContent
                      forceMount
                      hidden={includeAudienceTabValue == 'included-advanced-filter'}
                      value="included-quick-filter"
                      css={{ paddingLeft: '16px', paddingRight: '16px' }}
                    >
                      <Fieldset data-testid="field-include-audience">
                        <AudienceQuickFilter
                          data-testid="included_audience_filter"
                          channelId={current?.channel_id ?? null}
                          onSelectedItemsChanged={async (filterItems) => {
                            if (filterItems.length == 0) {
                              setMessageCount(0);
                            } else if (current?.channel_id) {
                              setIncludedAudienceFilter(
                                filterItems.length > 0 ? filterItems : null
                              );
                              const advancedIncludeFilter = translateToAdvancedFilter(
                                filterItems ?? []
                              ) as FilterType[];
                              setIncludedAudienceFilterType(
                                rebuildFilters(
                                  [],
                                  advancedIncludeFilter,
                                  customDefaultObjects
                                )
                              );
                            }
                          }}
                          selectedItems={includedAudienceFilter}
                        />
                      </Fieldset>
                    </TabsContent>
                    <TabsContent
                      forceMount
                      hidden={includeAudienceTabValue == 'included-quick-filter'}
                      value="included-advanced-filter"
                      css={{ paddingLeft: '16px', paddingRight: '16px' }}
                    >
                      <Fieldset>
                        <AdvancedFilterBuilder
                          defaultObjects={customDefaultObjects}
                          customObjects={[]}
                          filters={includedFilterType}
                          setFilters={(filters) => {
                            setIncludedAudienceFilter(cleanFilters(filters));
                            setIncludedAudienceFilterType(filters);
                          }}
                        />
                      </Fieldset>
                    </TabsContent>
                  </Tabs>
                  <Label css={{ margin: 0 }}>Exclude Contacts</Label>
                  <Tabs
                    value={excludeAudienceTabValue}
                    onValueChange={(value) => {
                      setExcludeAudienceTabValue(
                        value as 'excluded-advanced-filter' | 'excluded-quick-filter'
                      );
                    }}
                    css={{
                      display: 'flex',
                      gap: '16px',
                      borderRadius: '8px',
                      border: '1px solid rgba(2, 2, 52, 0.08)',
                      background: '#FFF',
                      margin: 0,
                    }}
                  >
                    <TabsList
                      css={{
                        whiteSpace: 'nowrap',
                        marginBottom: '0px',
                        lineHeight: '0px',
                      }}
                    >
                      <TabsTrigger
                        value="excluded-quick-filter"
                        disabled={!isExcludedFilterValidQuickFilter}
                      >
                        Quick filters
                      </TabsTrigger>
                      <TabsTrigger value="excluded-advanced-filter">
                        Advanced filters
                      </TabsTrigger>
                    </TabsList>
                    <TabsContent
                      forceMount
                      hidden={excludeAudienceTabValue == 'excluded-advanced-filter'}
                      value="excluded-quick-filter"
                      css={{ paddingLeft: '16px', paddingRight: '16px' }}
                    >
                      <Fieldset data-testid="field-exclude-audience">
                        <Box
                          css={{
                            p: 0,
                            m: 0,
                            background: 'white',
                          }}
                        >
                          <AudienceQuickFilter
                            data-testid="excluded_audience_filter"
                            channelId={current?.channel_id ?? null}
                            onSelectedItemsChanged={async (items) => {
                              if (current?.channel_id) {
                                const fullFilter =
                                  appendFilterItemsToExcludedSystemDefault(
                                    items,
                                    current.channel_id
                                  );
                                setExcludedAudienceFilter(fullFilter);
                                // also update the Filter Types for the advanced filter
                                const advancedExcludeFilter = translateToAdvancedFilter(
                                  items ?? []
                                ) as FilterType[];
                                setExcludedAudienceFilterType(
                                  rebuildFilters(
                                    [],
                                    advancedExcludeFilter,
                                    customDefaultObjects
                                  )
                                );
                              }
                            }}
                            selectedItems={excludedAudienceFilter}
                            removeSystemDefaultItems={(filterItems) => {
                              let currentExcludedAudienceFilter =
                                removeExcludedSystemDefault(filterItems, []);
                              // remove the contacts in recent campaigns rule
                              currentExcludedAudienceFilter = removeOrCondition(
                                currentExcludedAudienceFilter,
                                (filterItem: FilterItem) => {
                                  return (
                                    filterItem.column == 'last_campaign_date' &&
                                    filterItem.comparison == '>' &&
                                    filterItem.resource == 'communication_preference'
                                  );
                                }
                              );
                              // remove the open conversation rule
                              currentExcludedAudienceFilter = removeOrCondition(
                                currentExcludedAudienceFilter,
                                (filterItem: FilterItem) => {
                                  return (
                                    filterItem.column == 'status' &&
                                    filterItem.comparison == 'in' &&
                                    filterItem.resource == 'conversation' &&
                                    Array.isArray(filterItem.value) &&
                                    filterItem.value[0] == 'open' &&
                                    filterItem.value[1] == 'automated'
                                  );
                                }
                              );
                              return currentExcludedAudienceFilter;
                            }}
                          />
                        </Box>
                      </Fieldset>
                    </TabsContent>
                    <TabsContent
                      forceMount
                      hidden={excludeAudienceTabValue == 'excluded-quick-filter'}
                      value="excluded-advanced-filter"
                      css={{ paddingLeft: '16px', paddingRight: '16px' }}
                    >
                      <Fieldset>
                        <AdvancedFilterBuilder
                          defaultObjects={customDefaultObjects}
                          customObjects={[]}
                          filters={excludedFilterType}
                          setFilters={(filters) => {
                            setExcludedAudienceFilter(cleanFilters(filters));
                            setExcludedAudienceFilterType(filters);
                          }}
                        />
                      </Fieldset>
                    </TabsContent>
                  </Tabs>
                  <Fieldset css={{ margin: '0px' }}>
                    <Flex align="center" justify="between">
                      <Flex direction="column">
                        <Label>
                          Exclude Contacts with Open Conversations in any Location
                          (regardless of access)
                        </Label>
                        <Description>
                          Exclude contacts that have an open conversation with any phone
                          number in your organization regardless of your access.
                        </Description>
                      </Flex>
                      <Switch
                        data-testid="exclude-contacts-with-conversations-switch"
                        checked={excludeContactsWithConversations}
                        onCheckedChange={(checked) => {
                          setExcludeContactsWithConversations(checked);
                          if (checked) {
                            setExcludeOpenConversationFilter({
                              column: 'status',
                              comparison: 'in',
                              resource: 'conversation',
                              value: ['open', 'automated'],
                            });
                          } else {
                            setExcludeOpenConversationFilter(null);
                          }
                        }}
                      >
                        <SwitchThumb />
                      </Switch>
                    </Flex>
                  </Fieldset>
                  <Fieldset css={{ margin: '0px' }}>
                    <Flex align="center" justify="between">
                      <Flex direction="column">
                        <Label>
                          Exclude Contacts who recently received another Campaign
                        </Label>
                        <Description>
                          Excludes contacts from this campaign that have recently received
                          another campaign.
                        </Description>
                      </Flex>
                      <Box css={{ mt: 5 }}>
                        <Switch
                          data-testid="exclude-contacts-with-recent-campaigns"
                          checked={showExcludeContactsWithOtherCampaigns}
                          onCheckedChange={(checked) => {
                            setShowExcludeContactsWithOtherCampaigns(checked);
                            maybeUpdateLastCampaignSinceFilter(
                              checked,
                              excludeCampaignSince
                            );
                            if (!checked) setExcludeCampaignSince(0);
                          }}
                        >
                          <SwitchThumb />
                        </Switch>
                      </Box>
                    </Flex>
                    {showExcludeContactsWithOtherCampaigns && (
                      <Box
                        css={{
                          mt: 10,
                          p: 16,
                          backgroundColor: '#F6F6F6',
                          borderRadius: 4,
                        }}
                      >
                        <HStack>
                          <ControlGroup>
                            <IconButton
                              data-testid="subtract-from-campaign-time-since"
                              size={2}
                              css={{ backgroundColor: 'white' }}
                              onClick={() => {
                                excludeCampaignSince
                                  ? setExcludeCampaignSince(excludeCampaignSince - 1)
                                  : setExcludeCampaignSince(excludeCampaignSince);

                                const daysAgo = moment()
                                  .subtract(
                                    excludeCampaignSince
                                      ? excludeCampaignSince - 1
                                      : excludeCampaignSince,
                                    'day'
                                  )
                                  .format('YYYY-MM-DD');
                                setExcludeCampaignTimeSinceFilter({
                                  column: 'last_campaign_date',
                                  comparison: '>',
                                  resource: 'communication_preference',
                                  value: daysAgo,
                                });
                              }}
                            >
                              <HiMinus />
                            </IconButton>
                            <Input
                              type="number"
                              value={excludeCampaignSince ?? 0}
                              onChange={(e) => {
                                maybeUpdateLastCampaignSinceFilter(
                                  showExcludeContactsWithOtherCampaigns,
                                  parseInt(e.target.value)
                                );
                                setExcludeCampaignSince(Number(e.target.value));
                              }}
                              css={{ textAlign: 'center', width: 50 }}
                            />
                            <IconButton
                              data-testid="add-to-campaign-time-since"
                              size={2}
                              css={{ backgroundColor: 'white' }}
                              onClick={() => {
                                const nextValue = excludeCampaignSince
                                  ? excludeCampaignSince + 1
                                  : 1;
                                maybeUpdateLastCampaignSinceFilter(
                                  showExcludeContactsWithOtherCampaigns,
                                  nextValue
                                );
                                setExcludeCampaignSince(nextValue);
                              }}
                            >
                              <HiPlus />
                            </IconButton>
                          </ControlGroup>
                          <Box css={{ fontSize: 13 }}>days</Box>
                        </HStack>
                      </Box>
                    )}
                  </Fieldset>
                  <HStack css={{ mt: 0 }}>
                    <Button
                      onClick={() => handleSave(AccordionValue.CREATE_MESSAGE)}
                      disabled={!isValidAudience}
                    >
                      Save
                    </Button>
                    <Button
                      variant="gray"
                      ghost={true}
                      onClick={() => setAccordion(AccordionValue.DEFAULT_VALUE)}
                    >
                      Cancel
                    </Button>
                  </HStack>
                </VStack>
              </CampaignAccordion>
              <CampaignMessageEditor
                message={message}
                setMessage={setMessage}
                attachments={attachments}
                setAttachments={setAttachments}
                accordion={accordion}
                setAccordion={setAccordion}
                setCurrentEditor={setCurrentEditor}
                handleSave={handleSave}
                channel_type={getChannelById(channelId)?.type || 'phone'}
              />
              <CampaignSchedule
                accordion={accordion}
                showSchedulePicker={true}
                setAccordion={setAccordion}
                isScheduled={isScheduled}
                setIsScheduled={setIsScheduled}
                date={date}
                setDate={setDate}
                time={time}
                setTime={setTime}
                time_zone={time_zone}
                setTimezone={setTimezone}
                isValidSchedule={isValidSchedule}
                handleSave={handleSave}
                enableBatchSend={enableBatchSend}
                setEnableBatchSend={setEnableBatchSend}
                batchSize={batchSize}
                setBatchSize={setBatchSize}
                batchDelay={batchDelay}
                setBatchDelay={setBatchDelay}
                batchDelayTimeUnit={batchDelayTimeUnit}
                setBatchDelayTimeUnit={setBatchDelayTimeUnit}
                enableCampaignSettings={enableCampaignSettings}
                setEnableCampaignSettings={setEnableCampaignSettings}
                campaignSettings={campaignSettings}
                setCampaignSettings={setCampaignSettings}
                enableLinkTrackingSettings={enableLinkTrackingSettings}
                setEnableLinkTrackingSettings={setEnableLinkTrackingSettings}
              />
              <CampaignAccordion
                index={5}
                title="Automations"
                description="Automate campaign follow up tasks."
                currentAccordionValue={accordion}
                itemValue={AccordionValue.CREATE_AUTOMATION}
                setItemValue={setAccordion}
                isValid={hasTriggersOrTemplates}
              >
                <VStack gap="2">
                  <Fieldset>
                    <Flex direction="column">
                      <Label>Create Campaign Automation (optional)</Label>
                      <Description>
                        Campaign automations let you take actions when contacts respond to
                        your campaigns.
                      </Description>
                    </Flex>
                  </Fieldset>
                  <CampaignAutomations
                    current={current}
                    setCurrentCampaign={setCurrentCampaign}
                    updateCurrentCampaign={() =>
                      updateCurrentCampaign(campaign_params, current?.id)
                    }
                    accordion={accordion}
                    setAccordion={setAccordion}
                    existingAllAutomationTemplates={existingAllAutomationTemplates}
                    setExistingAllAutomationTemplates={setExistingAllAutomationTemplates}
                  />
                </VStack>
              </CampaignAccordion>
            </VStack>
          </Accordion>
        </Flex>
      </PageLayout>
      <Flex css={{ height: '100%' }}>
        {showPanel && isLargeDesktop && (
          <SidebarNavigationContainer
            defaultWidth={360}
            minWidth={300}
            maxWidth={500}
            dragDirection="right"
            name="CREATE_CAMPAIGN"
            disableCollapse
          >
            <V2CampaignPanel
              setShowPanel={setShowPanel}
              panel={panel}
              includedContacts={includedContacts}
              excludedContacts={excludedContacts}
              attachments={attachments.attachment_urls || []}
              preview={fillDemoMessage()}
              body={message}
              handleExclude={handleExcludeContactFromAudience}
              editor={currentEditor}
            />
          </SidebarNavigationContainer>
        )}
        {!isLargeDesktop && (
          <Drawer open={showPanel}>
            <DrawerPortal>
              <DrawerContent
                side="right"
                css={{ width: 360, overflowY: 'scroll' }}
                onEscapeKeyDown={() => setShowPanel(false)}
                onPointerDownOutside={() => setShowPanel(false)}
              >
                <V2CampaignPanel
                  setShowPanel={setShowPanel}
                  panel={panel}
                  includedContacts={includedContacts}
                  excludedContacts={excludedContacts}
                  attachments={attachments.attachment_urls || []}
                  preview={fillDemoMessage()}
                  body={message}
                  handleExclude={handleExcludeContactFromAudience}
                  editor={currentEditor}
                />
              </DrawerContent>
            </DrawerPortal>
          </Drawer>
        )}
        <CampaignToolbar handlePanelClick={handlePanelClick} open={showPanel} />
      </Flex>
    </Flex>
  );
};

// if a filter item has last_campaign_date then this is the same as last time since in campaign
function initialTimeSinceCampaign(campaign: Campaign | null): number | null {
  if (campaign && campaign?.included_audience_filter?.filter) {
    const timeSinceLastCampaignFilter = campaign.included_audience_filter.filter.find(
      (filterItem) => filterItem.column == 'last_campaign_date'
    );
    return (timeSinceLastCampaignFilter?.value as number) ?? null;
  }
  return null;
}

function hasOpenConversationFilter(excludedFilterItems: FilterItem[]): FilterItem | null {
  const withoutDefault = removeExcludedSystemDefault(excludedFilterItems, []);
  return findFilterItem(withoutDefault, (filterItem: FilterItem) => {
    return (
      filterItem.resource == 'conversation' &&
      filterItem.column == 'status' &&
      filterItem.comparison == 'in' &&
      Array.isArray(filterItem.value) &&
      filterItem.value.length == 2 &&
      filterItem.value[0] == 'open' &&
      filterItem.value[1] == 'automated'
    );
  });
}

function hasCampaignTimeSinceFilter(
  excludedFilterItems: FilterItem[]
): FilterItem | null {
  const withoutDefault = removeExcludedSystemDefault(excludedFilterItems, []);
  return findFilterItem(withoutDefault, (filterItem: FilterItem) => {
    return (
      filterItem.resource == 'communication_preference' &&
      filterItem.column == 'last_campaign_date' &&
      filterItem.comparison == '>'
    );
  });
}

export default CreateSMSCampaign;
