/* eslint-disable react-hooks/exhaustive-deps */
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
  HiChevronLeft,
  HiClipboard,
  HiDotsHorizontal,
  HiPhoneOutgoing,
  HiUserAdd,
  HiX,
} from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { useVoIP } from '@/pages/voip/context/VoIPContext';
import { CopyToClipboard } from '@/shared/components/CopyToClipboard';
import { ToggleIconClose } from '@/shared/components/Icons';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { useDisclosure } from '@/shared/hooks';
import { Channel, ProviderTypes } from '@/shared/types/channels';
import { ConversationType } from '@/shared/types/conversations';
import { User } from '@/shared/types/users';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerPortal,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Fieldset,
  Flex,
  HStack,
  IconButton,
  Label,
} from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { useContacts } from '../../../contacts/context/ContactContext';
import { ContactEditor } from '../../../contacts/editor';
import { useChannels } from '../../../settings/organization/channels/context/ChannelContext';
import { useUsers } from '../../../settings/organization/users/context/UserContext';
import { useConversation } from '../../context/ConversationContext';
import { AssignConversationModal } from '../assign/AssignConversationModal';
import { ConversationDetailsPanel } from '../panels/ConversationDetailsPanel';
import { onCsvExport, onPdfExport } from './ConversationExport';
import { ConversationStatus } from './ConversationStatus';

export function ConversationHeader(): JSX.Element {
  const {
    conversationState,
    handleMarkUnread,
    handleTransferLocation,
    getAllConversationMessages,
  } = useConversation();
  const { current } = conversationState;
  const { contactState, updateViewSettings } = useContacts();
  const { viewSettings } = contactState;

  const { channelsState, getChannelById } = useChannels();
  const { allChannels } = channelsState;

  const { userState } = useUsers();
  const { users } = userState;

  const { isOpen, onClose, onOpen } = useDisclosure();
  const isDesktop = useMedia('(min-width: 912px)');
  const history = useHistory();
  const auth = useAuth();
  const voip = useVoIP();
  const { enableVoip } = useFlags();

  const markAsUnread = () => {
    if (current && current.unread_count < 1) {
      handleMarkUnread();
    }
  };

  const [isTransferLocationOpen, setTransferLocationOpen] = useState(false);
  const [location, setLocation] = useState<Channel | null>(null);
  const [locationId, setLocationId] = useState<string>('');
  const [csvData, setCsvData] = useState<[]>([]);
  const [mobileDrawer, setMobileDrawer] = useState<'contact' | 'conversation' | null>(
    null
  );

  const csvLinkRef = useRef<{
    link: HTMLAnchorElement | null;
  }>();

  useEffect(() => {
    const location = current?.location_id ? getChannelById(current.location_id) : null;

    if (location) {
      setLocation(location);
      setLocationId(location.id);
    }
  }, [current?.location_id]);

  const onTransferOpen = () => {
    setTransferLocationOpen(true);
  };

  const onTransferClose = () => {
    setTransferLocationOpen(false);
  };

  const handleTransfer = (id: string) => {
    if (id && id !== current?.location_id) {
      handleTransferLocation(id);
    }
    onTransferClose();
  };

  const handleDownloadAllMessagesToPDF = async () => {
    if (current && current.id) {
      const data = await getAllConversationMessages(current?.id || '', 50);
      await onPdfExport(current, data, users, allChannels);
    }
  };

  const handleDownloadAllMessagesToCSV = async () => {
    if (current && current.id) {
      const messages = await getAllConversationMessages(current?.id || '', 50);
      const data = onCsvExport(current, messages, users, allChannels);
      setCsvData(data as []);
      csvLinkRef.current && csvLinkRef.current.link
        ? csvLinkRef.current.link.click()
        : null;
    }
  };

  const handleOpenPanel = useCallback(
    (panel: 'contact' | 'conversation') => () => {
      setMobileDrawer(panel);
      onOpen();
    },
    []
  );

  const filteredUsers = useMemo(() => {
    return users.filter((user: User) => {
      const userLocations = user?.locations || [];
      const userInLocation = userLocations.find(
        (location: Channel) => location?.id === current?.location_id
      );
      return !!userInLocation; // Return true if userInLocation exists, false otherwise
    });
  }, [users, current?.location_id]);

  const assignedUser = useMemo(() => {
    return current?.assigned_user_id
      ? filteredUsers.find((user: User) => user?.id === current?.assigned_user_id)
      : null;
  }, [filteredUsers, current?.assigned_user_id]);

  return (
    <>
      <ConversationHeaderContainer justify="between" align="center">
        <Flex align="center">
          {!isDesktop && (
            <IconButton
              aria-label="close conversation"
              onClick={history.goBack}
              css={{
                mr: 10,
              }}
            >
              <HiChevronLeft size="20px" />
            </IconButton>
          )}
          {current && location && (
            <ConversationHeaderContact
              conversation={current}
              channel={location}
              onClick={() => updateViewSettings('showPreview', !viewSettings.showPreview)}
            />
          )}
        </Flex>
        <Flex direction="row">
          {auth &&
            current &&
            location &&
            location.provider === ProviderTypes.TWILIO &&
            enableVoip && (
              <IconButton
                css={{ m: 20 }}
                size="2"
                variant="ghost"
                onClick={() =>
                  voip.makeOutgoingCall(
                    location.phone ?? '',
                    current?.contact.phone ?? '',
                    auth?.tokens?.user_id?.toString() ?? ''
                  )
                }
              >
                <HiPhoneOutgoing />
              </IconButton>
            )}
          {current !== null ? (
            <Flex direction="row" css={{ alignItems: 'center' }}>
              <HStack gap={4}>
                {/* User avatar is inside <AssignConversationModal> */}
                {isDesktop ? (
                  <AssignConversationModal isSmall />
                ) : (
                  // Mobile button to open conversation panel
                  <IconButton
                    size={2}
                    aria-label="Open conversation panel"
                    onClick={handleOpenPanel('conversation')}
                  >
                    <>
                      {current &&
                        (current?.assigned_user_id === null || !assignedUser) && (
                          <HiUserAdd size={15} />
                        )}
                      {current && current?.assigned_user_id !== null && assignedUser && (
                        <Avatar
                          src={assignedUser?.attachment?.url || ''}
                          fallback={initials(assignedUser?.name || assignedUser?.email)}
                          variant="lightGray"
                          size="assign"
                        />
                      )}
                    </>
                  </IconButton>
                )}
                <ConversationStatus closeAndNavigate={true} openAndNavigate={true} />
                {!isDesktop && (
                  <IconButton
                    aria-label="Open contact"
                    onClick={handleOpenPanel('contact')}
                    size={2}
                  >
                    <ToggleIconClose />
                  </IconButton>
                )}
                <Dialog open={isTransferLocationOpen} modal={false}>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <IconButton size={2}>
                        <HiDotsHorizontal size={15} />
                      </IconButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent sideOffset={10}>
                      <DropdownMenuItem onSelect={() => markAsUnread()}>
                        Mark as Unread
                      </DropdownMenuItem>
                      {allChannels.length > 1 && (
                        <DialogTrigger asChild>
                          <DropdownMenuItem onSelect={() => onTransferOpen()}>
                            Transfer Channel
                          </DropdownMenuItem>
                        </DialogTrigger>
                      )}
                      <DropdownMenuItem onSelect={() => handleDownloadAllMessagesToPDF()}>
                        Export as PDF
                      </DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => handleDownloadAllMessagesToCSV()}>
                        Export as CSV
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                  <DialogPortal>
                    <DialogContent
                      onEscapeKeyDown={() => onTransferClose()}
                      onPointerDownOutside={() => onTransferClose()}
                    >
                      <DialogTitle variant="bold">Transfer Channel</DialogTitle>
                      <Fieldset>
                        <Label>Select a Channel</Label>
                        <SingleSelect
                          defaultPlaceholder={
                            getChannelById(locationId)?.name || 'Select a Channel'
                          }
                          selectItem={locationId}
                          closeOnClick={true}
                          setSelectItem={setLocationId}
                          options={allChannels?.map((location) => ({
                            type: location?.name as string,
                            value: location?.id as string,
                          }))}
                          isDropdown={true}
                        />
                      </Fieldset>
                      <DialogFooter
                        justify="end"
                        css={{
                          mt: 20,
                        }}
                      >
                        <DialogClose asChild>
                          <Button
                            variant="gray"
                            size={2}
                            css={{ mr: '$1' }}
                            onClick={onTransferClose}
                          >
                            Cancel
                          </Button>
                        </DialogClose>
                        <DialogClose asChild>
                          <Button
                            type="button"
                            size={2}
                            onClick={() => handleTransfer(locationId)}
                          >
                            Transfer
                          </Button>
                        </DialogClose>
                      </DialogFooter>
                      <DialogCloseIcon onClick={() => onTransferClose()} size="2">
                        <HiX size="15px" />
                      </DialogCloseIcon>
                    </DialogContent>
                  </DialogPortal>
                </Dialog>
              </HStack>
              <CSVLink
                data={csvData}
                ref={csvLinkRef as any}
                filename={`conversation-${current.id}.csv`}
              />
            </Flex>
          ) : null}
        </Flex>
      </ConversationHeaderContainer>
      <DrawerContainer>
        <Drawer open={isOpen}>
          <DrawerPortal>
            <DrawerOverlay />
            <DrawerContent
              onEscapeKeyDown={onClose}
              onPointerDownOutside={onClose}
              side="bottom"
              css={{ height: '90%', overflowY: 'scroll' }}
            >
              {mobileDrawer === 'conversation' ? (
                <ConversationDetailsPanel />
              ) : (
                <ContactEditor contactId={current?.contact.id} isInbox={true} />
              )}
            </DrawerContent>
          </DrawerPortal>
        </Drawer>
      </DrawerContainer>
    </>
  );
}

// we use this when there is no current conversation e.g
// staring a new conversation from a drawer where we know the contact and location already
type ConversationContactHeaderProps = {
  conversation: {
    contact: {
      name: string;
      phone: string;
      email: string;
    };
  };
  channel: Channel;
  onClick?: () => void;
};

// when we know the conversation then we use this to correctly display the contact and location
type CurrentConversationContactHeaderProps = {
  conversation: ConversationType;
  channel: Channel;
  onClick?: () => void;
};

export const ConversationHeaderContact = (
  props: ConversationContactHeaderProps | CurrentConversationContactHeaderProps
) => {
  const { conversation, channel, onClick } = props;

  const isDesktop = useMedia('(min-width: 968px)');

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  // generate to name
  const generateToName = () => {
    switch (channel?.type) {
      case 'email':
        return conversation?.contact?.name || conversation.contact?.email || '';
      case 'phone':
        return (
          conversation?.contact?.name ||
          formatPhoneNumber(conversation?.contact?.phone || '') ||
          ''
        );
      default:
        return (
          conversation?.contact?.name ||
          formatPhoneNumber(conversation?.contact?.phone || '') ||
          ''
        );
    }
  };

  const generateToChannel = () => {
    switch (channel?.type) {
      case 'email':
        return conversation?.contact?.email || '';
      case 'phone':
        return formatPhoneNumber(conversation?.contact?.phone || '') || '';
      default:
        return formatPhoneNumber(conversation?.contact?.phone || '') || '';
    }
  };

  // generate from name
  const generateFromName = () => {
    switch (channel?.type) {
      case 'email':
        return channel?.name || `${channel.id}@whippymail.com` || '';
      case 'phone':
        return channel?.name || formatPhoneNumber(channel?.phone || '') || '';
      default:
        return channel?.name || formatPhoneNumber(channel?.phone || '') || '';
    }
  };

  // generate copy tooltip
  const generateCopyTooltip = () => {
    switch (channel?.type) {
      case 'email':
        return "Copy contact's email";
      case 'phone':
        return "Copy contact's phone number";
      default:
        return "Copy contact's phone number";
    }
  };

  return (
    <ContactContainer onClick={handleClick}>
      <Flex direction="column" align="center" justify="start">
        <Flex
          css={{ fontSize: 14, fontWeight: 600, textAlign: 'left', width: '100%' }}
          align="center"
          justify="start"
        >
          {generateToName()}
        </Flex>
        <Flex
          css={{
            fontSize: 12,
            fontWeight: 400,
            textAlign: 'left',
            width: '100%',
            color: '$slate11',
          }}
          align="center"
        >
          {isDesktop && (
            <CopyToClipboard
              copy={generateToChannel() || ''}
              description={generateCopyTooltip()}
              successMessage="Copied to clipboard"
            >
              <Flex align="center">
                <Flex>To: {generateToChannel()}</Flex>
                <Flex css={{ marginLeft: 4, marginRight: 12 }}>
                  <HiClipboard />
                </Flex>
              </Flex>
            </CopyToClipboard>
          )}
          <Flex>From: {generateFromName()}</Flex>
        </Flex>
      </Flex>
    </ContactContainer>
  );
};

export const ConversationHeaderContainer = styled(Flex, {
  minHeight: 64,
  height: 64,
  width: '100%',
  flexShrink: 0,
  borderBottom: 'thin solid $gray4',
  px: 24,
});

export const ContactContainer = styled(Flex, {
  fontWeight: 500,
  fontSize: 14,
  whiteSpace: 'nowrap',
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '110px',
  '@md': {
    maxWidth: '100%',
  },
});

export const DrawerContainer = styled(Box, {
  display: 'flex',
  '@lg': {
    display: 'none',
  },
});
