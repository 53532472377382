/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';

import { MessageEditorV2 } from '@/shared/components/editor/v2';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { Spinner } from '@/shared/components/Icons';
import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import { Signature } from '@/shared/types';
import { AccordionValue } from '@/shared/types/campaigns';
import { Box, Button, Fieldset, Flex, HStack, Input, Label, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { CampaignAccordion } from './CampaignAccordion';
import { Img } from './panel/PreviewMessage';

type CampaignMessageEditorProps = {
  campaignId: string | null;
  /* message */
  message: string;
  /* set message */
  setMessage: Dispatch<SetStateAction<string>>;
  /* message attachments */
  attachments: Attachments;
  /* set message attachments */
  setAttachments: Dispatch<SetStateAction<Attachments>>;
  /* current accordion value */
  accordion: AccordionValue;
  /* set current accordion value */
  setAccordion: Dispatch<SetStateAction<AccordionValue>>;
  /* set current slate message editor */
  setCurrentEditor?: (editor: any) => void;
  /* handle save step */
  handleSave: (accordion: AccordionValue) => void;
  /* is this component used under GlobalTemplatePreview */
  showSaveButton?: boolean;
  onSubjectChange: (subject: string) => void;
  subject: string;
  /* channel type */
  channel_type?: 'phone' | 'email' | 'whatsapp';
};

export const CampaignEmailMessageEditor = (
  props: CampaignMessageEditorProps
): JSX.Element => {
  const {
    message,
    setMessage,
    campaignId,
    attachments,
    setAttachments,
    accordion,
    setAccordion,
    handleSave,
    subject,
    onSubjectChange,
    showSaveButton = true,
    channel_type = 'phone',
  } = props;
  const emailEditorRef = useRef<EditorRef>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isHTMLMessage, setIsHTMLMessage] = useState(false);
  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);
  const [textMessage, setTextMessage] = useState('');
  const [HTMLMessage, setHTMLMessage] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [signature, setSignature] = useState<Signature | null>(null);

  const onReady: EmailEditorProps['onReady'] = (unlayer) => {
    setLoading(false);
    const templateJson = getDraftFromLocalStorage(campaignId);
    if (templateJson || HTMLMessage) {
      unlayer.loadDesign(JSON.parse(HTMLMessage ?? templateJson ?? ''));
      unlayer.exportHtml((data) => {
        const { html } = data;
        setHTMLMessage(html);
        setMessage(html);
      });
    }
  };

  const onLoad: EmailEditorProps['onLoad'] = (unlayer) => {
    // eslint-disable-next-line
    unlayer.addEventListener('design:updated', (_data: any) => {
      const unlayer = emailEditorRef.current?.editor;
      if (!unlayer) return;
      unlayer.exportHtml((data) => {
        const { design, html } = data;
        const stringifyDesign = JSON.stringify(design, null, 4);
        setHTMLMessage(html);
        setMessage(html);
        saveDraftToLocalStorage(campaignId, stringifyDesign);
      });
    });
  };

  const getDraftFromLocalStorage = (campaignId: string | null) => {
    if (!campaignId) return null;
    const key = `email-campaign-${campaignId}-body`;
    return localStorage.getItem(key);
  };

  const clearDraftFromLocalStorage = (campaignId: string | null) => {
    if (!campaignId) return null;
    const key = `email-campaign-${campaignId}-body`;
    return localStorage.removeItem(key);
  };

  const saveDraftToLocalStorage = (
    campaignId: string | null,
    stringifiedHTMLDesign: string
  ) => {
    if (!campaignId) return null;
    const key = `email-campaign-${campaignId}-body`;
    localStorage.setItem(key, stringifiedHTMLDesign);
  };

  // Used to add signature to message when selected
  // As long as the signature has not already been added
  useEffect(() => {
    if (!signature) return;

    // if (emailMessage.plainText.includes(signature.body) || !emailMessage.htmlBody.includes(signature.body)) {
    if (message.includes(signature.body) && isHTMLMessage) {
      setMessage(`${message}\n${signature.body}`);
      setSignature(null);
    }
  }, [signature]);

  return (
    <CampaignAccordion
      // if this component used under GlobalTemplatePreview, showSaveButton === false, then the index should be 2
      index={!showSaveButton ? 2 : 3}
      title="Message"
      description="Campaign Message Body & Attachments"
      currentAccordionValue={accordion}
      itemValue={AccordionValue.CREATE_MESSAGE}
      setItemValue={setAccordion}
      isValid={
        isMessageTextValid(isHTMLMessage ? HTMLMessage : textMessage, subject) ||
        attachments?.attachment_urls?.length > 0
      }
      // if showSaveButton === false, then the buttonCopy should be 'Expand', otherwise, it should be 'Edit'
      buttonCopy={!showSaveButton ? 'Expand' : 'Edit'}
      forceMountContent
      contentStyle={{ padding: accordion == AccordionValue.CREATE_MESSAGE ? '' : '0px' }}
    >
      <VStack
        gap="2"
        css={{
          display: accordion == AccordionValue.CREATE_MESSAGE ? 'flex' : 'none',
          alignItems: 'stretch',
        }}
      >
        <Fieldset>
          <Label>Subject</Label>
          <Box>
            <Input
              value={subject}
              placeholder="Subject"
              onChange={(e) => {
                onSubjectChange(e.target.value);
              }}
            />
          </Box>
        </Fieldset>
        <Box>
          <Flex gap="1" css={{ marginBottom: 16 }}>
            <Button
              variant="grayBackground"
              css={isHTMLMessage ? { background: 'transparent' } : {}}
              onClick={() => {
                setIsHTMLMessage(false);
                setMessage(textMessage ?? '');
              }}
            >
              Plain Text Email
            </Button>
            <Button
              variant="grayBackground"
              css={!isHTMLMessage ? { background: 'transparent' } : {}}
              onClick={() => {
                setIsHTMLMessage(true);
                setMessage(HTMLMessage ?? '');
              }}
            >
              HTML Email
            </Button>
          </Flex>
          <Fieldset>
            <div>
              <Box css={{ display: !isHTMLMessage ? 'none' : 'block' }}>
                <Flex css={{ display: isLoading ? 'none' : 'block' }}>
                  <EmailEditorContainer>
                    <EmailEditor
                      options={{
                        id: 'email-html-editor',
                        projectId: 255767,
                      }}
                      ref={emailEditorRef}
                      onReady={onReady}
                      onLoad={onLoad}
                      style={{
                        minHeight: '700px',
                      }}
                    />
                  </EmailEditorContainer>
                </Flex>
                {isLoading && (
                  <Flex css={{ justifyContent: 'center' }}>
                    <Spinner size={3} />
                  </Flex>
                )}
              </Box>
              <Flex
                css={{
                  minHeight: 320,
                  maxHeight: 700,
                  display: isHTMLMessage ? 'none' : 'flex',
                }}
              >
                <Flex
                  css={{
                    width: '50%',
                    marginRight: 8,
                    position: 'relative',
                  }}
                >
                  <MessageEditorV2
                    placeholder="Enter email text here"
                    textareaRef={textareaRef}
                    message={textMessage}
                    setMessage={(v) => {
                      setTextMessage(v);
                      setMessage(v);
                    }}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    enableAttachments={true}
                    attachmentLoading={attachmentLoading}
                    setAttachmentLoading={setAttachmentLoading}
                    isForm={true}
                    signature={signature}
                    setSignature={setSignature}
                    showAddTemplate={true}
                    showAddSignature={true}
                    showAddAttachment={true}
                    showAddVariable={true}
                    showAddEmoji={true}
                    showCharacterCount={true}
                    showAddTranslate={true}
                    sendMessageFromTranslationDialog={false}
                    channel_type={channel_type}
                    maxHeight={700}
                    minHeight={320}
                    isCampaignTemplate
                  />
                </Flex>
                <Box
                  css={{
                    width: '50%',
                    border: '1px solid #0000301B',
                    marginLeft: 8,
                    borderRadius: 6,
                    padding: '12px 15px',
                    overflow: 'auto',
                  }}
                >
                  {attachments?.attachment_urls.map((url, index) => (
                    <Img
                      css={{ height: 'auto', maxWidth: '100%' }}
                      key={index}
                      src={url}
                      alt="image-preview"
                    />
                  ))}
                  <Box>
                    <span style={{ fontWeight: 700 }}>To:</span>{' '}
                    {'Example Contact <example@example.com>'}
                  </Box>
                  <Box>
                    <span style={{ fontWeight: 700 }}>Subject:</span> {subject}
                  </Box>
                  <Box css={{ mt: 16 }}>
                    {renderText(`${textMessage}\n${signature?.body ?? ''}`)}
                  </Box>
                </Box>
              </Flex>
            </div>
          </Fieldset>
        </Box>
        <HStack>
          {(showSaveButton === true || showSaveButton === undefined) && (
            <Button
              onClick={() => {
                clearDraftFromLocalStorage(campaignId);
                handleSave(AccordionValue.SELECT_SCHEDULE);
              }}
              disabled={
                !isMessageTextValid(isHTMLMessage ? HTMLMessage : textMessage, subject) &&
                attachments?.attachment_urls?.length < 1
              }
            >
              Save
            </Button>
          )}

          <Button
            variant="gray"
            ghost={true}
            onClick={() => setAccordion(AccordionValue.DEFAULT_VALUE)}
          >
            Cancel
          </Button>
        </HStack>
      </VStack>
    </CampaignAccordion>
  );
};

function isMessageTextValid(message: string | null, subject: string) {
  return message && message.length > 1 && subject.length > 2;
}

const EmailEditorContainer = styled('div', {
  borderStyle: 'solid',
  borderRadius: '4px',
  borderWidth: '0px',
  padding: '2px',
  boxShadow: 'inset 0 0 0 1px $colors$slate7',
  backgroundColor: 'white',
});
