/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { HiOutlineMail } from 'react-icons/hi';

import { Attachment, isValidAttachment } from '@/shared/components/attachments';
import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import {
  ConversationAttachmentType,
  ConversationItemModeTypes,
  ConversationItemVisibilityStatusTypes,
  ConversationMessageType,
} from '@/shared/types/conversations';
import { Box, Divider, Flex, HStack } from '@/shared/ui';
import { TIME_STAMP } from '@/shared/utils/timestamps';

import { StyledAttachmentContainer, StyledMessage, StyledMessageFooter } from '..';
import { ContactIcon } from '../ContactIcon';

type InboundEmailProps = {
  message: ConversationMessageType;
  /* Date of message */
  date?: string | undefined | null;
  /* Name of contact */
  contact_name: string;
};

// Combined message container and message divs for inbound messages
export const InboundEmail = (props: InboundEmailProps) => {
  const { message, date, contact_name } = props;
  const { body, attachments, mode, visibility_status } = message;

  // filter attachments we can't display
  const filteredAttachments = attachments.filter(
    (a: ConversationAttachmentType | null) =>
      a !== null && isValidAttachment(a.url) === true
  );

  // default to `visible` when the visibility is not set
  const visibility = visibility_status
    ? visibility_status
    : ConversationItemVisibilityStatusTypes.VISIBLE;

  return (
    <Box>
      {/* Show `Message Removed` bubble when the visibility is `removed` */}
      {visibility === ConversationItemVisibilityStatusTypes.REMOVED ? (
        <StyledMessage direction="inbound_email">
          {renderText('This message has been deleted...')}
        </StyledMessage>
      ) : null}

      {(body !== null || filteredAttachments.length > 0) && (
        <Box>
          {body !== null ? (
            <Flex justify="start" align="end">
              <ContactIcon contact_name={contact_name} />
              <StyledMessage
                visibility={`inbound_${visibility}`}
                direction="inbound_email"
              >
                <HStack align="center">
                  <HiOutlineMail size={14} />
                  <Box css={{ fontSize: '13px' }}>
                    Subject: {message?.email_metadata?.subject}
                  </Box>
                </HStack>
                <Divider css={{ my: 9 }} />
                {renderText(body)}
              </StyledMessage>
            </Flex>
          ) : null}
          <Box>
            {filteredAttachments.length > 0 ? (
              visibility === ConversationItemVisibilityStatusTypes.HIDDEN ? (
                'Attachments have been hidden'
              ) : (
                <Box css={{ alignItems: 'left' }}>
                  {filteredAttachments.map((a: ConversationAttachmentType | null) => {
                    return a ? (
                      <StyledAttachmentContainer key={a.url}>
                        <Flex css={{ width: '300px !important' }} justify="start">
                          <Attachment src={a.url} />
                        </Flex>
                      </StyledAttachmentContainer>
                    ) : null;
                  })}
                </Box>
              )
            ) : null}
          </Box>
          <StyledMessageFooter align="start" justify="start">
            <Flex align="center">
              <Box css={{ mr: 5 }}>{dayjs(date).format(TIME_STAMP)}</Box>
              <Box css={{ fontWeight: 500 }}>
                {!contact_name && mode === ConversationItemModeTypes.IMPORTED
                  ? 'Imported'
                  : contact_name}
              </Box>
            </Flex>
          </StyledMessageFooter>
        </Box>
      )}
    </Box>
  );
};
