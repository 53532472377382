import { HiX } from 'react-icons/hi';

import { SideNavigationHeading } from '@/inbox/list/ConversationsFilter';
import {
  PanelContainer,
  PanelContentContainer,
  PanelHeaderContainer,
} from '@/pages/inbox/conversation/panels';
import { PanelType } from '@/shared/types/campaigns';
import { Contact } from '@/shared/types/contacts';
import { CustomScrollbar, Flex, IconButton } from '@/shared/ui';

import { HelpPanel } from './Help';
import { PreviewMessagePanel } from './PreviewMessage';
import { V2AudiencePanel } from './V2Audience';

type CampaignPanelProps = {
  /* open or closed the panel */
  setShowPanel: (showPanel: boolean) => void;
  /* current view */
  panel: PanelType;
  /* a list of contacts that represents the manually excluded contacts
   * that a user selected in the audience side panel */
  /* included audience contacts */
  includedContacts: Contact[];
  /* excluded audience filter items */
  excludedContacts: Contact[];
  /* message attachments */
  attachments: Array<any>;
  /* message body */
  body: string;
  /* the message body with pipe fields filled */
  preview: string;
  /* add contact id to preselected exclusion */
  handleExclude?: (contact: Contact) => void;
  /* editor instance */
  editor?: any;
};

export const V2CampaignPanel = (props: CampaignPanelProps): JSX.Element => {
  const {
    setShowPanel,
    panel,
    includedContacts,
    excludedContacts,
    attachments,
    preview,
    handleExclude,
  } = props;

  const renderPanel = (panelType: string): JSX.Element => {
    switch (panelType) {
      case PanelType.VIEW_AUDIENCE:
        return (
          <V2AudiencePanel
            key="audience_panel"
            contacts={includedContacts}
            handleExclude={handleExclude}
          />
        );
      case PanelType.VIEW_EXCLUDED_AUDIENCE:
        return (
          <V2AudiencePanel key="excluded_audience_panel" contacts={excludedContacts} />
        );
      case PanelType.VIEW_PREVIEW:
        return <PreviewMessagePanel attachment_urls={attachments} body={preview} />;
      case PanelType.VIEW_HELP:
        return <HelpPanel />;
      default:
        return <Flex></Flex>;
    }
  };

  const renderPanelTitle = (panelType: PanelType): string => {
    switch (panelType) {
      case PanelType.VIEW_AUDIENCE:
        return 'Audience';
      case PanelType.VIEW_EXCLUDED_AUDIENCE:
        return 'Excluded Contacts';
      case PanelType.VIEW_PREVIEW:
        return 'Preview Message';
      case PanelType.VIEW_HELP:
        return 'Help';
      default:
        return 'Audience';
    }
  };

  return (
    <PanelContainer direction="column" css={{ height: '100%' }}>
      <PanelHeaderContainer align="center" justify="between">
        <SideNavigationHeading css={{ textTransform: 'capitalize' }}>
          {renderPanelTitle(props.panel)}
        </SideNavigationHeading>
        <IconButton size={2} onClick={() => setShowPanel(false)}>
          <HiX size={16} />
        </IconButton>
      </PanelHeaderContainer>
      <PanelContentContainer css={{ minHeight: '100%', minWidth: '100%' }}>
        {(panel == PanelType.VIEW_AUDIENCE ||
          panel == PanelType.VIEW_EXCLUDED_AUDIENCE) &&
          renderPanel(panel)}
        {panel != PanelType.VIEW_AUDIENCE &&
          panel != PanelType.VIEW_EXCLUDED_AUDIENCE && (
            <CustomScrollbar>
              <Flex
                align="center"
                justify="center"
                css={{
                  minHeight: '100%',
                  minWidth: '100%',
                  overflowY: 'scroll',
                  pb: 100,
                }}
              >
                {renderPanel(panel)}
              </Flex>
            </CustomScrollbar>
          )}
      </PanelContentContainer>
    </PanelContainer>
  );
};
