import {
  CallStatusTypes,
  VoIPActions,
  VoIPActionTypes,
  VoIPStateType,
} from '@/shared/types/voip';

const VoIPReducer = (state: VoIPStateType, action: VoIPActions) => {
  switch (action.type) {
    case VoIPActionTypes.ADD_DEVICE:
      return {
        ...state,
        device: action.payload,
      };
    case VoIPActionTypes.DESTROY_DEVICE:
      return {
        ...state,
        device: null,
      };
    case VoIPActionTypes.ADD_INCOMING_CALL:
      return {
        ...state,
        incomingCall: action.payload,
        callStatus: CallStatusTypes.INCOMING,
      };
    case VoIPActionTypes.DESTROY_INCOMING_CALL:
      return {
        ...state,
        incomingCall: null,
        callStatus: null,
      };
    case VoIPActionTypes.ADD_OUTGOING_CALL:
      return {
        ...state,
        outgoingCall: action.payload,
        callStatus: CallStatusTypes.OUTGOING,
      };
    case VoIPActionTypes.DESTROY_OUTGOING_CALL:
      return {
        ...state,
        outgoingCall: null,
      };
    case VoIPActionTypes.ADD_CALL_STATUS:
      return {
        ...state,
        callStatus: action.payload,
      };
    default:
      return state;
  }
};

export default VoIPReducer;
