import { api } from '../api';

export type ChannelParams = {
  id?: string;
  name?: string;
  address?: string;
  widget_setting_id?: string | null;
  phone?: string;
  state?: string;
  review_link?: string;
  google_place_id?: string | null;
  automatic_response_open?: string | null;
  automatic_response_closed?: string | null;
};
/*
  GET - the organizations's channels
*/

export const fetchChannels = (queryParams?: string) =>
  api.get(`/locations?${queryParams || ''}`).then((res) => {
    return res.data.data;
  });

export const getChannelsV2 = (params: any) =>
  api.post(`/v2/channels/search`, { ...params }).then((res) => {
    return res.data;
  });

/*
  POST - search for a channel by name
*/

export const searchChannel = (name: string) =>
  api.post('/locations/search', { name }).then((res) => {
    return res.data.data;
  });

/*
  POST - add a new location to an organization
*/

export const createChannel = (channelParams: ChannelParams) =>
  api.post('/locations', { location: channelParams }).then((res) => {
    return res.data.data;
  });

/*
  PUT - update a location in an organization
*/

export const updateChannel = (channelParams: ChannelParams) =>
  api.put(`/locations/${channelParams.id}`, { location: channelParams }).then((res) => {
    return res.data.data;
  });

/*
  PUT - disable a channel in an organization
*/

export const disableChannel = (channelParams: ChannelParams) =>
  api.put(`/locations/disable/${channelParams.id}`).then((res) => {
    return res.data.data;
  });

/*
  DELETE - remove a channel from an organization
*/

export const deleteChannel = (id: string) =>
  api.delete(`/locations/${id}`).then((res) => {
    return res.data.data;
  });

/*
  GET - get a channel by id
  */

export const getChannel = (id: string) => {
  return api.get(`/locations/${id}`).then((res) => {
    return res.data.data;
  });
};
