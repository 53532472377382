import axios from 'axios';

import { API_BASE_URL } from '../utils/config';
import { getAuthTokens, removeAuthTokens } from '../utils/storage';
import { api } from './api';

/*
  Get refresh tokens from local storage
*/

export const getRefreshToken = () => {
  const tokens = getAuthTokens();

  return tokens && tokens.renewal_token;
};

/*
  Renew session - does not use custom config
*/

export const renew = () =>
  axios
    .post(`${API_BASE_URL}/api/session/renew`, '', {
      headers: {
        Authorization: `${getRefreshToken()}`,
      },
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.warn('Refresh Request Failed', error);
      const prevLink = window.location.href;
      const link = window.location.href.includes('login?redirect')
        ? window.location.href
        : `/login?redirect=${prevLink}`;
      window.location.replace(link);
      removeAuthTokens();
    });

export type LoginParams = {
  email: string;
  inviteToken?: string;
  password: string;
};

export type LoginWithSsoParams = {
  email: string;
};

export type RegisterParams = LoginParams & {
  organizationName?: string;
  name: string;
  phone?: string;
  website: string;
};

export type InviteParams = {
  token?: string;
};

export type ResetPasswordParams = {
  password: string;
  passwordConfirmation: string;
};

/*
  POST - login user
*/

export const login = ({ email, password, inviteToken }: LoginParams) =>
  api
    .post('/session', {
      user: {
        email,
        password,
        invite_token: inviteToken,
      },
    })
    .then((res) => {
      return res.data.data;
    });

/*
  POST - Login with SSO
  Send an email to server and check if user exists in database.
  If user is found, server returns with generated authorization url.
*/

export const sso = ({ email }: LoginWithSsoParams) =>
  api.post('/session/workos/sso', { email: email }).then((res) => {
    return res.data.data.authorization_url;
  });

/*
  DELETE - logout user delete session 
*/

export const logout = () =>
  api.delete('/session').then((res) => {
    return res.data.data;
  });

export const register = ({
  organizationName,
  inviteToken,
  email,
  password,
  name,
  phone,
  website,
}: RegisterParams) =>
  api
    .post('/registration', {
      user: {
        organization_name: organizationName,
        invite_token: inviteToken,
        email,
        password,
        password_confirmation: password,
        name,
        phone,
        website,
      },
    })
    .then((res) => {
      return res.data.data;
    });

/*
  GET - get user invitation info
*/

export const getInviteInfo = (invite_token: string) =>
  api.get(`/invites/${invite_token}`).then((res) => {
    return res.data.data;
  });

/*
  POST - accept user invitation on sign up
*/

export const acceptInvite = (params: Record<string, any>) =>
  api
    .post('/invites/accept', {
      invite: params,
    })
    .then((res) => {
      return res.data.data;
    });

/*
  POST - toggle the current user's organization
*/

export const changeOrganization = (organization_id: string, token?: string | null) =>
  api
    .post(
      '/organizations/change',
      {
        organization_id: organization_id,
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    )
    .then((res) => {
      return res.data.data;
    });

/*
  GET - get organizations to which the current user has access
  as well as info about the current organization of the user.
*/

export const fetchOrganizationsInfo = () =>
  api.get('/organizations/me').then((res) => {
    return res.data.data;
  });

/*
  GET - get organizations to which the user has access to by
  using the user email
*/

export const listOrganizations = (queryParams: string, token: string) =>
  api
    .get(`/organizations?${queryParams}`, {
      headers: {
        Authorization: `${token}`,
      },
    })
    .then((res) => {
      return res.data.data;
    });

/*
  PUT - update the organization's name
*/

export const updateOrganizationInfo = (updates: Record<string, any>) =>
  api.put('/organizations/me', { organization: updates }).then((res) => {
    return res.data.data;
  });

/*
  POST - send password reset email
*/

export const sendPasswordResetEmail = (email: string) =>
  api
    .post('/reset', {
      email,
    })
    .then((res) => {
      return res.data.data;
    });

/*
  PUT - update the organization's name
*/

export const attemptPasswordReset = (
  passwordResetToken: string | null,
  { password, passwordConfirmation }: ResetPasswordParams
) =>
  api
    .put('/reset', {
      password,
      password_confirmation: passwordConfirmation,
      token: passwordResetToken,
    })
    .then((res) => {
      return res.data.data;
    });

/*
  GET - get the time the backend server last updated
*/

export const getLastUpdated = () =>
  api.get('/status').then((res) => {
    return res.data;
  });
