/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import dayjs from 'dayjs';
import { debounce, DebouncedFunc } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { handleFilterChange, handleSortChange } from '@/pages/data/utils/filterActions';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { team_object } from '@/shared/components/filterBuilder/objects/team';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterType, Sort } from '@/shared/types/filter';
import { Team } from '@/shared/types/team';
import { Box, Flex } from '@/shared/ui';
import { TooltipAvatar } from '@/shared/ui/TooltipAvatar';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';
import { styled } from '@/stitches.config';

import {
  ConfirmationDialogDescription,
  CustomDropdownMenuItem,
  CustomDropdownMenuItemWarning,
} from '../users/UsersTable';
import { AddTeam } from './AddTeam';
import { initialTeamsState, ITEMS_COUNT, useTeams } from './context/TeamsContext';
import { sortConfig } from './filterConfig';
import { TeamMembersAvatars } from './TeamMembersAvatars';

const TeamsTable = () => {
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);
  const history = useHistory();

  const {
    updateFilterParams,
    deleteTeam,
    teamsState: { teams, totalCount, loading, filterParams },
  } = useTeams();

  useEffect(() => {
    updateFilterParams({
      ...initialTeamsState.filterParams,
    });
  }, []);

  const columns: Array<TableColumn<Team>> = useMemo(
    () => [
      {
        Header: 'Teams',
        colWidth: '55%',
        accessor: 'name',
        Cell: (props: { row: { original: Team } }) => (
          <Flex direction="column">
            <TeamTitle>{props.row.original.name}</TeamTitle>
          </Flex>
        ),
      },
      {
        Header: 'Created by',
        accessor: 'created_b',
        Cell: (props: { row: { original: Team } }) => (
          <>
            {!!props.row.original.created_by && (
              <TooltipAvatar user={props.row.original.created_by} />
            )}
          </>
        ),
      },
      {
        Header: 'Updated by',
        accessor: 'updated_by',
        Cell: (props: { row: { original: Team } }) => (
          <>
            {!!props.row.original.updated_by && (
              <TooltipAvatar user={props.row.original.updated_by} />
            )}
          </>
        ),
      },
      {
        Header: 'Created At',
        id: 'inserted_at',
        accessor: 'inserted_at',
        colWidth: '100%',
        Cell: (props: { row: { original: Team } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.inserted_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Updated At',
        id: 'updated_at',
        accessor: 'updated_at',
        colWidth: '100%',
        Cell: (props: { row: { original: Team } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.updated_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Members',
        colWidth: '40%',
        accessor: 'team_members',
        Cell: (props: { row: { original: Team } }) => (
          <TeamMembers direction="row" align="center">
            <TeamMembersAvatars
              members={props.row.original.team_members || []}
              limit={5}
            />
          </TeamMembers>
        ),
      },
      {
        Header: '',
        colWidth: '5%',
        accessor: 'id',
        Cell: (props: { row: { original: Team } }) => (
          <>
            <TableActionMenu>
              <>
                <CustomDropdownMenuItem
                  data-testid="edit-team-option"
                  onClick={() =>
                    history.push(`/settings/teams/${props.row.original?.id}`)
                  }
                >
                  Edit Team
                </CustomDropdownMenuItem>
                <ConfirmationDialog
                  width="432px"
                  title="Delete Team?"
                  description={
                    <ConfirmationDialogDescription
                      value={props.row.original?.name ?? ''}
                      description="will be deleted."
                    />
                  }
                  onConfirm={() => deleteTeam(props.row.original?.id ?? '')}
                  confirmButtonTitle="Confirm"
                  cancelButtonTitle="Cancel"
                  confirmButtonVariant="redBackground"
                  cancelButtonVariant="grayBackground"
                >
                  <CustomDropdownMenuItemWarning
                    data-testid="delete-team-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete Team
                  </CustomDropdownMenuItemWarning>
                </ConfirmationDialog>
              </>
            </TableActionMenu>
          </>
        ),
      },
    ],
    [loading]
  );

  const handleQuickSearch = (
    debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
    filters: SearchFilters,
    value: string
  ) => {
    const formattedValue = value.trim();
    const quickFilters = formattedValue
      ? [
          {
            column: 'name',
            comparison: 'ilike',
            resource: 'team',
            value: `%${formattedValue}%`,
          },
        ]
      : [];
    debouncedUpdate({
      ...filters,
      searchFilter: quickFilters,
      offset: 0,
    });
  };

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateFilterParams(props);
    }, 1000),
    []
  );

  return (
    <Flex direction="column" css={{ flex: '1 1 auto' }}>
      <Box css={{ paddingBottom: 24 }}>
        <CombinedFilters
          quickSearchPlaceholder="Search Teams"
          quickSearchValue={quickSearchValue}
          setQuickSearchValue={(value: string) => {
            setQuickSearchValue(value);
            handleQuickSearch(debouncedUpdate, filterParams, value);
          }}
          defaultObjects={[team_object]}
          customObjects={[]}
          activeFilters={activeFilters}
          setFilters={(value: Array<FilterType>) => {
            setActiveFilters(value);
            handleFilterChange(debouncedUpdate, filterParams, value);
          }}
          sortConfig={sortConfig}
          activeSort={filterParams.sort}
          onSortUpdate={(value: Array<Sort>) => {
            handleSortChange(debouncedUpdate, filterParams, value);
          }}
        />
      </Box>
      <Table
        data={teams}
        columns={columns}
        caption="Teams Table"
        totalCount={totalCount}
        emptyTitle="No Teams Match Search"
        setOffset={(offset) => {
          updateFilterParams({
            ...filterParams,
            offset,
          });
        }}
        isLoading={loading}
        pageSize={ITEMS_COUNT}
        empty={
          <Box>
            <Box
              css={{
                width: 250,
                fontSize: 16,
                marginTop: 8,
                marginBottom: 16,
                fontWeight: 400,
              }}
            >
              <Box css={{ padding: '6.5px 0' }}>No teams were found.</Box>
              <Box>
                <AddTeam />
              </Box>
            </Box>
          </Box>
        }
      />
    </Flex>
  );
};

const TeamTitle = styled(Box, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 200,
  textOverflow: 'ellipsis',
});

const TeamMembers = styled(Flex, {
  maxWidth: 200,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  ml: 4,
});

export default TeamsTable;
