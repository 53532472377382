import 'react-dayjs-picker/dist/index.css';

import { Formik, FormikProps, FormikValues } from 'formik';
import { CountryCode } from 'libphonenumber-js';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { searchContacts } from '@/shared/api/contacts/v2';
import { languages } from '@/shared/components/editor/v2/utils';
import {
  FormFieldWrapper,
  PhoneInputWithCountry,
  RadioInput,
  TextInput,
} from '@/shared/components/forms';
import { Channel } from '@/shared/types/channels';
import { Contact } from '@/shared/types/contacts';
import { Box, Button, Flex, VStack } from '@/shared/ui';
import { isValidPhoneNumber, toE164 } from '@/shared/utils/validations/validations';
import { ComboboxMultiselectItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectItem';
import { ComboboxMultiselectTrigger } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectTrigger';
import { ComboboxMultiselectInput } from '@/shared/v2/components/forms/comboboxMultiselect/ComboboxMultiselectInput';
import { SelectComboboxField } from '@/shared/v2/components/forms/selectCombobox/SelectComboboxField';
import { styled } from '@/stitches.config';

import { useContacts } from '../../context/ContactContext';
import { formatContactName, formatQuickSearchFilter } from '../../utils';
import { ContactAddress } from './ContactAddress';
import { TextInputWithValidation } from './TextInputWithValidation';

type CreateContactProps = {
  /* initial state of name field */
  name?: string;
  /* initial state of phone field */
  phone?: string;
  /* add contact functionality */
  handleCreateContact?: (params: Partial<Contact>) => void;
  /* cancel add contact functionality */
  onCancel?: () => void;
};

const contactBlockOptions = [
  {
    type: 'False',
    value: 'false',
    backgroundColor: '#FF010110',
    color: '#BB0007D5',
  },
  {
    type: 'True',
    value: 'true',
    backgroundColor: '#02BA3C16',
    color: '#006B3BE7',
  },
];

export const CreateContact = (props: CreateContactProps) => {
  const { onCancel, handleCreateContact, name, phone } = props;

  const [isPhoneDuplicate, setIsPhoneDuplicate] = useState(false);
  const [isEmailDuplicate, setIsEmailDuplicate] = useState(false);
  const [isExternalIdDuplicate, setIsExternalIDuplicate] = useState(false);

  const {
    channelsState: { channels },
  } = useChannels();
  const { addContactHttp } = useContacts();
  const formikRef = useRef<FormikProps<FormikValues>>(null);

  const [countryCode, setCountryCode] = useState<CountryCode>('US');

  const handleValidateOnChange = useCallback(
    debounce(async (name: string, value: string) => {
      if (value && name) {
        const formattedValue = name === 'phone' ? toE164(value, countryCode) : value;
        const { total } = await searchContacts(
          formatQuickSearchFilter(formattedValue, name),
          [],
          5
        );
        if (name === 'phone') {
          if (total) {
            setIsPhoneDuplicate(true);
          } else {
            setIsPhoneDuplicate(false);
          }
        }
        if (name === 'email') {
          if (total) {
            setIsEmailDuplicate(true);
          } else {
            setIsEmailDuplicate(false);
          }
        }
        if (name === 'external_id') {
          if (total) {
            setIsExternalIDuplicate(true);
          } else {
            setIsExternalIDuplicate(false);
          }
        }
      }
    }, 500),
    [countryCode]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string(),
        phone: Yup.string()
          .test('phone', 'Required', function (value) {
            const { email } = this.parent;
            return value || email ? true : this.createError({ message: 'Required' });
          })
          .test('isValidPhoneNumber', 'Please provide a valid phone number', (value) =>
            value ? !!isValidPhoneNumber(value) : true
          )
          .test('isDuplicate', 'Duplicate found', (value) =>
            value ? !isPhoneDuplicate : true
          ),
        email: Yup.string()
          .email('Email is invalid')
          .test('email', 'Required', function (value) {
            const { phone } = this.parent;
            return value || phone ? true : this.createError({ message: 'Required' });
          })
          .test('isDuplicate', 'Duplicate found', (value) =>
            value ? !isEmailDuplicate : true
          ),
        locations: Yup.array().test(
          'len',
          'Required',
          (arr) => Array.isArray(arr) && arr.length > 0
        ),
        external_id: Yup.string().test('isDuplicate', 'Duplicate found', (value) =>
          value ? !isExternalIdDuplicate : true
        ),
      }),
    [isEmailDuplicate, isExternalIdDuplicate, isPhoneDuplicate]
  );

  useEffect(() => {
    if (formikRef?.current?.dirty) {
      formikRef?.current?.setFieldTouched('email', true);
      formikRef?.current?.setFieldTouched('external_id', true);
      formikRef?.current?.setFieldTouched('phone', true);
    }
  }, [isEmailDuplicate, isExternalIdDuplicate, isPhoneDuplicate]);

  return (
    <ContactEditorLayout>
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: name || '',
          phone: phone || '',
          email: '',
          locations: channels.map((location) => location.id),
          address: {
            address_line_one: '',
            address_line_two: '',
            city: '',
            state: '',
            country: '',
            post_code: '',
          },
          default_channel_id: '',
          external_id: '',
          language: 'en',
          blocked: 'false',
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnMount
        onSubmit={async (values) => {
          const params = {
            name: formatContactName(values.name),
            phone: toE164(values.phone, countryCode),
            email: values.email,
            locations: values.locations,
            address: values.address,
            default_channel_id: values.default_channel_id,
            external_id: values.external_id,
            language: values.language,
            blocked: JSON.parse(values.blocked),
          };
          try {
            const handleCreate = handleCreateContact
              ? handleCreateContact
              : addContactHttp;
            const data = await handleCreate(params);
            if (data) {
              onCancel?.();
            }
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {(formik) => (
          <form
            data-testid="create-contact-form"
            onSubmit={formik.handleSubmit}
            style={{ width: '100%', height: '100%' }}
          >
            <VStack
              gap={1}
              css={{
                overflowY: 'scroll',
                height: 'calc(100% - 64px)',
                width: '100%',
                px: 24,
                pt: 24,
              }}
            >
              <FormFieldWrapper label="Name" name="name">
                <TextInput placeholder="Add contact's name" />
              </FormFieldWrapper>
              <FormFieldWrapper label="Email" name="email">
                <TextInputWithValidation
                  placeholder="Add contact's email"
                  onValidateOnChange={handleValidateOnChange}
                />
              </FormFieldWrapper>
              <FormFieldWrapper label="Phone" name="phone">
                <PhoneInputWithCountry
                  placeholder="(000) 000-0000"
                  countryCode={countryCode}
                  setCountryCode={setCountryCode}
                  onValidateOnChange={handleValidateOnChange}
                />
              </FormFieldWrapper>
              <FormFieldWrapper label="External ID" name="external_id">
                <TextInputWithValidation
                  placeholder="Add contact’s external ID"
                  onValidateOnChange={handleValidateOnChange}
                />
              </FormFieldWrapper>
              <FormFieldWrapper label="Address" name="address">
                <ContactAddress name="address" />
              </FormFieldWrapper>
              {channels.length > 1 ? (
                <FormFieldWrapper label="Default Channel" name="default_channel_id">
                  <SelectComboboxField
                    options={channels.map((channel: Channel) => ({
                      label: channel?.name || channel?.address || '',
                      value: channel.id,
                    }))}
                    name="default_channel_id"
                    searchLabel="Search"
                    selectLabel="Add contact’s default channel"
                    error={
                      !!(
                        formik.touched.default_channel_id &&
                        formik.errors.default_channel_id
                      )
                    }
                    valueStyles={{
                      backgroundColor: '#0144FF0F',
                      color: '#00259ECB',
                    }}
                  />
                </FormFieldWrapper>
              ) : null}
              <FormFieldWrapper label="Language" name="language">
                <SelectComboboxField
                  options={languages.map((language) => ({
                    label: language.label,
                    value: language.value,
                  }))}
                  name="language"
                  searchLabel="Search"
                  selectLabel="Add contact’s language"
                  error={!!(formik.touched.language && formik.errors.language)}
                  valueStyles={{
                    backgroundColor: '#01CCFF1D',
                    color: '#005681DA',
                  }}
                />
              </FormFieldWrapper>
              {channels.length > 1 && (
                <FormFieldWrapper name="locations" label="Communication Preferences">
                  <ComboboxMultiselectInput
                    options={channels.map((channel) => ({
                      label: channel.name || channel.address || '',
                      value: channel.id,
                    }))}
                    searchLabel="Search"
                    selectLabel="Set communication preferences"
                    Trigger={ComboboxMultiselectTrigger}
                    Option={ComboboxMultiselectItem}
                    invalid={!!(formik.touched.locations && formik.errors.locations)}
                    selectAll
                  />
                </FormFieldWrapper>
              )}
              <FormFieldWrapper label="Blocked" name="blocked">
                <RadioInput
                  options={contactBlockOptions}
                  css={{
                    display: 'flex',
                    marginTop: -10,
                  }}
                />
              </FormFieldWrapper>
            </VStack>
            <ButtonsWrapper>
              <CancelButton
                aria-label="Close"
                variant="gray"
                css={{
                  height: 40,
                  flex: 1,
                  mr: 12,
                  fontSize: 16,
                  backgroundColor: '#00003B0D',
                  boxShadow: 'none',
                }}
                type="button"
                onClick={onCancel}
              >
                Cancel
              </CancelButton>
              <Button
                aria-label="Save Contact"
                type="submit"
                css={{ height: 40, flex: 1, fontSize: 16 }}
                disabled={!formik.isValid}
              >
                Create Contact
              </Button>
            </ButtonsWrapper>
          </form>
        )}
      </Formik>
    </ContactEditorLayout>
  );
};

export const ContactEditorLayout = ({ children }: { children?: React.ReactNode }) => {
  return (
    <ContactPanelLayout
      data-testid="ContactEditorLayout"
      css={{ width: '100%', minWidth: '100%' }}
    >
      {children}
    </ContactPanelLayout>
  );
};

export const ContactPanelLayout = styled(Flex, {
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  minHeight: '100%',
  maxWidth: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  '@lg': {
    width: '360px',
    maxWidth: '360px',
    display: 'initial',
  },
});

export const PanelTitle = styled(Flex, {
  fontSize: 18,
  lineHeight: 26,
  fontWeight: 700,
  mb: 2,
});

export const PanelHeaderContainer = styled(Flex, {
  minHeight: 64,
  position: 'relative',
  height: 64,
  width: '100%',
  flexShrink: 0,
  borderBottom: 'thin solid $gray4',
  px: 12,
  '@lg': {
    px: 24,
  },
});

export const PanelContentContainer = styled(Flex, {
  position: 'relative',
  height: '100%',
  flex: 1,
  overflow: 'auto',
});

export const ButtonsWrapper = styled(Flex, {
  width: '100%',
  px: 24,
  py: 11,
  alignItems: 'center',
  borderTop: 'thin solid $gray4',
});

export const CancelButton = styled(Button, {
  fontSize: 16,
  lineHeight: 24,
  fontWeight: 500,
});

export const RadioIndicator = styled(Box, {
  width: 16,
  height: 16,
  border: 'thin solid $gray4',
  borderRadius: 16,
});

export const SelectedRadioIndicator = styled(Box, {
  width: 16,
  height: 16,
  border: '4 solid $primary',
  borderRadius: 16,
});
