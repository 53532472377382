/* eslint-disable react-hooks/exhaustive-deps */
import { Call, Device } from '@twilio/voice-sdk';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import { createContext, useContext, useReducer } from 'react';

import { api } from '@/shared/api/api';
import { CallStatusTypes, VoIPActionTypes, VoIPStateType } from '@/shared/types/voip';

import VoIPReducer from './VoIPReducer';

export const initialState: VoIPStateType = {
  device: null,
  outgoingCall: null,
  incomingCall: null,
  callStatus: null,
};

export const VoIPContext = createContext<{
  voipState: VoIPStateType;
  initDevice: () => Promise<void>;
  makeOutgoingCall: (
    location_phone: string,
    contact_number: string,
    user_id: string
  ) => void;
  acceptIncomingCall: () => void;
  rejectCall: () => void;
  ignoreIncomingCall: () => void;
  hangUp: () => void;
  newCall: () => void;
  clearCallStatus: () => void;
}>({
  voipState: initialState,
  initDevice: () => Promise.resolve(),
  makeOutgoingCall: () => Promise.resolve(),
  acceptIncomingCall: () => Promise.resolve(),
  rejectCall: () => Promise.resolve(),
  ignoreIncomingCall: () => Promise.resolve(),
  hangUp: () => Promise.resolve(),
  newCall: () => Promise.resolve(),
  clearCallStatus: () => Promise.resolve(),
});

export const useVoIP = () => useContext(VoIPContext);

const VoIPState = ({ children }: { children: React.ReactNode }) => {
  const [voipState, dispatch] = useReducer(VoIPReducer, initialState);
  const { enableVoip } = useFlags();

  useEffect(() => {
    if (enableVoip) {
      initDevice();
    }
    return () => {
      if (voipState.device) {
        voipState.device.destroy();
      }
    };
  }, [enableVoip]);

  const getToken = async () => {
    const response = await api.get('/v2/calls/twilio/token');
    return response.data.data.token;
  };

  const initDevice = async () => {
    const token = await getToken();
    const device = new Device(token, { logLevel: 'info' });
    await device.register();
    device.on('tokenWillExpire', () => {
      return getToken().then((token) => device.updateToken(token));
    });
    device.on('error', (deviceError) => {
      console.log('deviceError ', deviceError);
      // the following table describes how deviceError will change with this feature flag
    });
    dispatch({
      type: VoIPActionTypes.ADD_DEVICE,
      payload: device,
    });
    device.on('incoming', handleIncomingCall);
  };

  const handleIncomingCall = (incomingCall: Call) => {
    console.log(incomingCall, 'incoming call');
    dispatch({
      type: VoIPActionTypes.ADD_INCOMING_CALL,
      payload: incomingCall,
    });

    incomingCall.on('accept', () => {
      console.log('setting call');
      dispatch({
        type: VoIPActionTypes.ADD_CALL_STATUS,
        payload: CallStatusTypes.INCOMING_ACCEPTED,
      });
      console.log('The call was accepted. Media session is set up.');
    });

    incomingCall.on('cancel', () => {
      dispatch({
        type: VoIPActionTypes.DESTROY_INCOMING_CALL,
      });
      dispatch({
        type: VoIPActionTypes.ADD_CALL_STATUS,
        payload: null,
      });
      console.log('The incoming call has been canceled.');
    });

    incomingCall.on('reject', () => {
      dispatch({
        type: VoIPActionTypes.DESTROY_INCOMING_CALL,
      });
      console.log('The incoming call has been canceled.');
    });

    incomingCall.on('error', (callError: Error) => {
      dispatch({
        type: VoIPActionTypes.DESTROY_INCOMING_CALL,
      });
      console.error('Call error:', callError);
    });

    incomingCall.on('disconnect', () => {
      dispatch({
        type: VoIPActionTypes.DESTROY_INCOMING_CALL,
      });
      console.log('The incoming call was disconnected.');
    });
  };

  const makeOutgoingCall = async (
    location_phone: string,
    contact_number: string,
    user_id: string
  ) => {
    // return, If twilio hasn't registered user device.
    if (!voipState.device) {
      console.error('Device is not initialized.');
      return;
    }

    try {
      if (location_phone && contact_number && user_id) {
        const outgoingCall = await voipState.device.connect({
          params: { From: location_phone, To: contact_number, user_id },
        });

        dispatch({
          type: VoIPActionTypes.ADD_OUTGOING_CALL,
          payload: outgoingCall,
        });

        outgoingCall.on('accept', (outgoingCall) => {
          dispatch({
            type: VoIPActionTypes.ADD_OUTGOING_CALL,
            payload: outgoingCall,
          });
          console.log('The call was accepted. Media session is set up.');
        });

        outgoingCall.on('cancel', () => {
          dispatch({
            type: VoIPActionTypes.DESTROY_OUTGOING_CALL,
          });
          console.log('The outgoing call has been canceled.');
        });

        outgoingCall.on('error', (callError: Error) => {
          dispatch({
            type: VoIPActionTypes.DESTROY_OUTGOING_CALL,
          });
          console.error('Call error:', callError);
        });

        outgoingCall.on('disconnect', () => {
          dispatch({
            type: VoIPActionTypes.DESTROY_OUTGOING_CALL,
          });
          console.log('The outgoing call was disconnected.');
        });

        outgoingCall.on('reject', () => {
          dispatch({
            type: VoIPActionTypes.DESTROY_OUTGOING_CALL,
          });
          console.log('The call was rejected.');
        });

        console.log(outgoingCall, 'outgoingCall');
      }
    } catch (error) {
      console.error('Error making the call:', error);
    }
  };

  const acceptIncomingCall = () => {
    voipState.incomingCall?.accept();
  };

  const rejectCall = () => {
    voipState.incomingCall?.reject();
  };

  const ignoreIncomingCall = () => {
    voipState.incomingCall?.ignore();
  };

  const hangUp = () => {
    voipState.callStatus == 'incoming' && voipState.incomingCall?.disconnect();
    voipState.callStatus == 'incoming_accepted' && voipState.incomingCall?.disconnect();
    voipState.callStatus == 'outgoing' && voipState.outgoingCall?.disconnect();
  };

  const newCall = () => {
    dispatch({
      type: VoIPActionTypes.ADD_CALL_STATUS,
      payload: CallStatusTypes.NEW_OUTGOING,
    });
  };

  const clearCallStatus = () => {
    dispatch({
      type: VoIPActionTypes.ADD_CALL_STATUS,
      payload: null,
    });
  };

  return (
    <VoIPContext.Provider
      value={{
        voipState,
        initDevice,
        makeOutgoingCall,
        acceptIncomingCall,
        rejectCall,
        ignoreIncomingCall,
        hangUp,
        newCall,
        clearCallStatus,
      }}
    >
      {children}
    </VoIPContext.Provider>
  );
};

export default VoIPState;
