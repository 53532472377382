import { styled } from '@/stitches.config';

const DEFAULT_TAG = 'button';

const StyledIconButton = styled(DEFAULT_TAG, {
  // Reset
  alignItems: 'center',
  appearance: 'none',
  borderWidth: '0',
  boxSizing: 'border-box',
  display: 'inline-flex',
  flexShrink: 0,
  fontFamily: 'inherit',
  fontSize: '14px',
  justifyContent: 'center',
  lineHeight: '1',
  outline: 'none',
  padding: '0',
  textDecoration: 'none',
  userSelect: 'none',
  WebkitTapHighlightColor: 'transparent',
  color: '$hiContrast',
  '&::before': {
    boxSizing: 'border-box',
  },
  '&::after': {
    boxSizing: 'border-box',
  },
  backgroundColor: '$loContrast',
  border: '1px solid $slate7',
  '@hover': {
    '&:hover': {
      borderColor: '$slate4',
    },
  },
  '&:active': {
    backgroundColor: '$slate2',
  },
  '&:focus': {
    borderColor: '$slate8',
    boxShadow: '0 0 0 1px $colors$slate8',
  },
  '&:disabled': {
    pointerEvents: 'none',
    backgroundColor: 'transparent',
    color: '$slate6',
  },

  variants: {
    size: {
      '0': {
        borderRadius: '$1',
        height: '20px',
        width: '20px',
      },
      '1': {
        borderRadius: '$1',
        height: '$5',
        width: '$5',
      },
      '2': {
        borderRadius: '$2',
        height: '$6',
        width: '$6',
      },
      '3': {
        borderRadius: '$2',
        height: '$7',
        width: '$7',
      },
      '4': {
        borderRadius: '$3',
        height: '$8',
        width: '$8',
      },
    },
    variant: {
      ghost: {
        backgroundColor: 'transparent',
        borderWidth: '0',
        '@hover': {
          '&:hover': {
            backgroundColor: '$slateA3',
          },
        },
        '&:focus': {
          boxShadow: 'inset 0 0 0 1px $colors$slateA8, 0 0 0 1px $colors$slateA8',
        },
        '&:active': {
          backgroundColor: '$slateA4',
        },
        '&[data-radix-popover-trigger][data-state="open"], &[data-radix-dropdown-menu-trigger][data-state="open"]':
          {
            backgroundColor: '$slateA4',
          },
      },
      send: {
        color: 'white',
        borderWidth: '0',
        borderRadius: '4px',
        height: 32,
        width: 32,
        backgroundColor: '$primaryButtonColor',
        '@hover': {
          '&:hover': {
            backgroundColor: '$primaryButtonColor',
          },
        },
      },
      review: {
        borderRadius: 4,
        marginLeft: '10px',
        height: '32px',
        width: '32px',
        border: '0',
        color: '$slate11',
      },
      delete: {
        backgroundColor: '$slate4',
        '@hover': {
          '&:hover': {
            backgroundColor: '$slate6',
          },
        },
      },
      outline: {},
      raised: {
        boxShadow:
          '0 0 transparent, 0 16px 32px hsl(206deg 12% 5% / 25%), 0 3px 5px hsl(0deg 0% 0% / 10%)',
        '@hover': {
          '&:hover': {
            boxShadow:
              '0 0 transparent, 0 16px 32px hsl(206deg 12% 5% / 25%), 0 3px 5px hsl(0deg 0% 0% / 10%)',
          },
        },
        '&:focus': {
          borderColor: '$slate8',
          boxShadow:
            '0 0 0 1px $colors$slate8, 0 16px 32px hsl(206deg 12% 5% / 25%), 0 3px 5px hsl(0deg 0% 0% / 10%)',
        },
        '&:active': {
          backgroundColor: '$slate4',
        },
      },
      green: {
        backgroundColor: '#30A46C',
        width: 32,
        height: 32,
        border: 'none',
        color: 'white',
        '@hover': {
          '&:hover': {
            backgroundColor: '#35B979',
          },
        },
      },
      darkGray: {
        backgroundColor: '#BDC8FF17',
        width: 32,
        height: 32,
        border: 'none',
        color: '#ADB1B8',
        '@hover': {
          '&:hover': {
            backgroundColor: '#C7D6FE20',
          },
        },
      },
      red: {
        backgroundColor: '#E5484D',
        width: 32,
        height: 32,
        border: 'none',
        color: 'white',
        '@hover': {
          '&:hover': {
            backgroundColor: '#E5484D',
          },
        },
      },
    },
    state: {
      active: {
        backgroundColor: '$slate4',
        boxShadow: 'inset 0 0 0 1px hsl(206,10%,76%)',
        '@hover': {
          '&:hover': {
            boxShadow: 'inset 0 0 0 1px hsl(206,10%,76%)',
          },
        },
        '&:active': {
          backgroundColor: '$slate4',
        },
      },
      waiting: {
        backgroundColor: '$slate4',
        boxShadow: 'inset 0 0 0 1px hsl(206,10%,76%)',
        '@hover': {
          '&:hover': {
            boxShadow: 'inset 0 0 0 1px hsl(206,10%,76%)',
          },
        },
        '&:active': {
          backgroundColor: '$slate4',
        },
      },
    },
  },
  defaultVariants: {
    size: '1',
    variant: 'ghost',
  },
});

export const IconButton = StyledIconButton;
