/* eslint-disable react-hooks/exhaustive-deps */
import { debounce, DebouncedFunc } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';

import { handleFilterChange, handleSortChange } from '@/pages/data/utils/filterActions';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { default_contact_user_state } from '@/shared/components/filterBuilder/objects/user_state';
import { default_users_object } from '@/shared/components/filterBuilder/objects/users';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterType, Sort } from '@/shared/types/filter';
import { Box, Flex } from '@/shared/ui';

import { useChannels } from '../channels/context/ChannelContext';
import { initialUsersState, useUsers } from './context/UserContext';
import { usersSortConfig } from './filterConfig';
import { UsersTable } from './UsersTable';

export const defaultSort: Array<Sort> = [
  {
    label: 'Role',
    resource: 'user_organization',
    column: 'role',
    order: 'asc',
    id: null,
  },
];

export const UsersView = () => {
  const { updateFilterParams, userState } = useUsers();

  const location = useChannels();
  const { allChannels } = location.channelsState;
  // value of the search input field
  const [searchValue, setSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  useEffect(() => {
    resetFilters();
  }, []);

  const resetFilters = () => {
    setSearchValue('');
    setActiveFilters([]);
    updateFilterParams({
      ...initialUsersState.filterParams,
      sort: defaultSort,
    });
  };

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateFilterParams(props);
    }, 1000),
    []
  );

  const handleQuickSearch = (
    debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
    filters: SearchFilters,
    value: string
  ) => {
    const formattedValue = value.trim();
    const quickFilters = [
      {
        column: 'name',
        comparison: 'ilike',
        value: `%${formattedValue}%`,
        resource: 'user',
        or: [
          {
            column: 'email',
            comparison: 'ilike',
            value: `%${formattedValue}%`,
            resource: 'user',
          },
        ],
      },
    ];

    debouncedUpdate({
      ...filters,
      searchFilter: quickFilters,
      offset: 0,
    });
  };

  return (
    <Flex direction="column" css={{ flex: '1 1 auto' }}>
      <Box css={{ paddingBottom: 24 }}>
        <CombinedFilters
          quickSearchPlaceholder="Search Name and Email"
          quickSearchValue={searchValue}
          setQuickSearchValue={(value: string) => {
            setSearchValue(value);
            handleQuickSearch(debouncedUpdate, userState.filterParams, value);
          }}
          defaultObjects={[default_users_object, default_contact_user_state]}
          customObjects={[]}
          activeFilters={activeFilters}
          setFilters={(value: Array<FilterType>) => {
            setActiveFilters(value);
            handleFilterChange(debouncedUpdate, userState.filterParams, value);
          }}
          sortConfig={usersSortConfig}
          activeSort={userState.filterParams.sort}
          onSortUpdate={(value: Array<Sort>) =>
            handleSortChange(updateFilterParams, userState.filterParams, value)
          }
        />
      </Box>
      {allChannels.length > 0 && <UsersTable />}
    </Flex>
  );
};
