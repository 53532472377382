import { Channel } from '../channels';
import { SearchFilters } from '../contacts';
import { Attachment } from '../index';
import { User } from '../users';

export type Template = {
  id?: string;
  organization_id?: string;
  title?: string | null;
  message?: string | null;
  attachments: Array<Attachment>;
  access_level: string;
  locations?: Array<Channel>;
  created_by?: User;
  updated_by?: User;
  created_at?: string;
  updated_at?: string;
  content_type?: TemplateContentType;
  is_scheduled_template?: boolean;
  scheduled_template_params?: ScheduledTemplateParams;
};

export enum TemplateContentType {
  GENERAL = 'general',
  REVIEW_REQUEST = 'review_request',
  REVIEW_RESPONSE = 'review_response',
}

export type TemplatesState = {
  /** all templates */
  allTemplates: Array<Template>;
  /** templates list */
  templates: Array<Template>;
  /** templates that have been searched for */
  searchedTemplates: Array<Template>;
  /** count */
  totalCount: number;
  /** loading state */
  loading: boolean;
  /** current template */
  current: Template | null | undefined;
  /** error state */
  error: Error | null;
  filterParams: SearchFilters;
};

export enum TemplatesActionTypes {
  CREATE_TEMPLATE = 'CREATE_TEMPLATE',
  GET_ALL_TEMPLATES = 'GET_ALL_TEMPLATES',
  EDIT_TEMPLATE = 'EDIT_TEMPLATE',
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
  SET_LOADING = 'SET_LOADING',
  SET_CURRENT = 'SET_CURRENT',
  BULK_IMPORT_TEMPLATES = 'BULK_IMPORT_TEMPLATES',
  GET_TEMPLATES = 'GET_TEMPLATES',
  UPDATE_FILTER_PARAMS = 'UPDATE_FILTER_PARAMS',
}
export type TemplatesActions =
  | { type: TemplatesActionTypes.CREATE_TEMPLATE; payload: Template }
  | {
      type: TemplatesActionTypes.GET_ALL_TEMPLATES;
      payload: { data: Array<Template>; total?: number };
    }
  | { type: TemplatesActionTypes.EDIT_TEMPLATE; payload: Template }
  | { type: TemplatesActionTypes.DELETE_TEMPLATE; payload: Template }
  | { type: TemplatesActionTypes.SET_LOADING; payload: boolean }
  | { type: TemplatesActionTypes.SET_CURRENT; payload: Template | null | undefined }
  | { type: TemplatesActionTypes.BULK_IMPORT_TEMPLATES; payload: Array<Template> }
  | {
      type: TemplatesActionTypes.GET_TEMPLATES;
      payload: { data: Array<Template>; total: number };
    }
  | { type: TemplatesActionTypes.UPDATE_FILTER_PARAMS; payload: SearchFilters };

export type CreateMessageTemplateParams = {
  title: string;
  message: string;
  location_id?: string;
  attachment_urls?: Array<string>;
  location_ids?: Array<string>;
  content_type?: TemplateContentType;
};

export type ScheduledTemplateParams = {
  /** number of days in the future to send the message */
  days_in_the_future?: number;
  /** time hour to be sent */
  hour?: number;
  /** time minute to be sent */
  minute?: number;
  /** timezone */
  timezone?: string;
};
