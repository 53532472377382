/* eslint-disable react-hooks/exhaustive-deps */
import { Channel } from 'phoenix';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useIdle, useMedia } from 'react-use';
import { toast } from 'sonner';

import * as API from '@/shared/api/conversations';
import { Signature } from '@/shared/types';
import { Channel as ChannelType } from '@/shared/types/channels';
import {
  ConversationEventType,
  ConversationFilterTypes,
  ConversationItemSourceTypes,
  ConversationItemTypeV2,
  ConversationMessageType,
  ConversationPanelTypes,
  ConversationsActionTypes,
  ConversationScheduledMessageType,
  ConversationSortTypes,
  ConversationStatusTypes,
  ConversationType,
  ConversationWebsocketEventTypes,
  ConversationWithStateType,
  NewConversationParamsType,
  NewScheduledConversationParamsType,
  ScheduleConversationItemParamsType,
  SearchV2Type,
} from '@/shared/types/conversations';
import { Team } from '@/shared/types/team';
import { User } from '@/shared/types/users';
import i18next from '@/shared/utils/translation';
import { isValidUuid } from '@/shared/utils/validations/validations';

import { useAuth } from '../../auth/context/AuthProvider';
import { useSocket } from '../../auth/context/socket/SocketProvider';
import { useChannels } from '../../settings/organization/channels/context/ChannelContext';
import { SEARCH_CONVERSATIONS_LIMIT } from '../list/utils';
import ConversationReducer from './ConversationReducer';
import { formatState } from './utils';

export type MatchParams = {
  id?: string;
  filter?: ConversationFilterTypes | string;
  tab?: ConversationStatusTypes;
};

export type ForwardParams = {
  message: string;
  attachment_urls: Array<string>;
  signature?: Signature | null;
};

export type PartialWithId<T> = { id: string } & Partial<Omit<T, 'id'>>;

export type ConversationsState = {
  conversations: ConversationType[];
  current: ConversationWithStateType | null;
  loading: boolean;
  tabIndex: string;
  error: Error | null;
  sort: ConversationSortTypes;
  showConversationPanel: boolean;
  conversationPanel: ConversationPanelTypes | null;
  isForwarded: boolean;
  forwardingParams: ForwardParams;
  selectedConversations: string[];
};

export const initialConversationsState: ConversationsState = {
  conversations: [],
  current: null,
  loading: true,
  tabIndex: ConversationStatusTypes.OPEN,
  error: null,
  sort: ConversationSortTypes.NEWEST,
  showConversationPanel: false,
  isForwarded: false,
  forwardingParams: { message: '', attachment_urls: [] },
  conversationPanel: null,
  selectedConversations: [],
};

export type SearchParams = {
  contact_value?: string;
  status?: ConversationStatusTypes;
  limit?: number;
  offset?: number;
  unread_count?: {
    operator: string | number;
    value: number | string;
  };
  sort?: {
    column: string;
    order: string;
    resource: string;
  };
  unassigned?: boolean;
  locations_ids?: Array<string>;
  assigned_users_ids?: Array<number>;
  last_message_source_type?: Array<ConversationItemSourceTypes>;
};

// the initial inbox params
// also used to reset the inbox params
const initialInboxState = {
  status: ConversationStatusTypes.OPEN,
  limit: SEARCH_CONVERSATIONS_LIMIT,
  offset: 0,
  sort: {
    resource: 'conversation',
    order: 'desc',
    column: 'last_message_timestamp',
  },
};

const initialSearchV2State: SearchV2Type = {
  inputValue: '',
};

// translate message can accept two types of params
// 1. message_id: string
// 2. message_id, and the source and target languages
type TranslateMessageParams =
  | {
      message_id: string;
      source_language: string;
      target_language: string;
    }
  | {
      message_id: string;
    };

const ConversationContext = createContext<{
  conversationState: ConversationsState;
  toggleConversationPanel: (value: boolean) => Promise<void>;
  setConversationPanel: (type: ConversationPanelTypes | null) => Promise<void>;
  getOrCreateConversation: (params: NewConversationParamsType) => Promise<void>;
  setConversation: (
    conversation: ConversationWithStateType | null,
    offset?: number,
    messageId?: string
  ) => void;
  bulkAddConversations: (messages: Array<any>) => void;
  addMessage: (message: {
    body: string;
    attachment_urls: Array<string>;
    translated_body?: string;
  }) => void;
  addNote: (note: { body?: string; attachment_urls?: Array<string> }) => void;
  updateMessage: (params: { message_id: string; visibility_status?: string }) => void;
  handleMarkedClosed: (closeAndNavigate?: boolean) => void;
  handleMarkedOpen: (openAndNavigate?: boolean) => void;
  changeTabIndex: (index: string) => void;
  formatState: (data: Array<ConversationType>) => void;
  handleAssignUser: (id: User | null) => void;
  handleAssignTeam: (team: Team | null) => void;
  addTypingUser: () => void;
  removeTypingUser: () => void;
  handleMarkUnread: () => void;
  handleTransferLocation: (locationId: string) => void;
  updateConversationContact: (phone: string) => void;
  updateConversationContactLanguage: (language: string) => void;
  inbox: SearchParams;
  getConversationById: (id: string) => Promise<void>;
  getAndSetCurrent: (id: string, messageId?: string) => Promise<void>;
  setInbox: (search: {
    unread_count?: { operator: string | number; value: number | string };
    offset?: number;
    limit?: number;
    unassigned?: boolean;
    locations_ids?: Array<string>;
    assigned_users_ids?: Array<number>;
    contact_value?: string;
    status?: ConversationStatusTypes;
  }) => void;
  showTabs: boolean;
  setShowTabs: (showTabs: boolean) => void;
  advancedSearchConversations: (search: SearchParams) => void;
  setLoading: (loading: boolean) => void;
  totalUnread: number;
  resetInbox: () => void;
  getConversationByLocationAndContact: (
    contact_id: string,
    location_id: string
  ) => Promise<void>;
  setSort: (sort: ConversationSortTypes) => void;
  addScheduledMessage: (
    message: {
      body: string;
      attachment_urls: Array<string>;
    },
    scheduleParams: ScheduleConversationItemParamsType
  ) => void;
  updateOneScheduledMessage: (
    id: number,
    scheduledMessage: {
      message: {
        body: string;
        attachment_urls: Array<string>;
      };
      scheduleParams: ScheduleConversationItemParamsType;
    }
  ) => void;
  sendScheduledMessageNow: (id: number) => void;
  deleteScheduledMessage: (id: number) => void;
  getConversationMessages: (
    id: string,
    offset: number,
    limit: number,
    loadBefore?: boolean
  ) => void;
  scheduleNewConversationMessage: (
    params: NewScheduledConversationParamsType
  ) => Promise<void>;
  toggleForwardState: (isForwarded: boolean, forwardingParams: ForwardParams) => void;
  translateMessage: (params: TranslateMessageParams) => void;
  body: string;
  setBody: (body: string) => void;
  selectConversation: (id: string) => void;
  unselectConversation: (id: string) => void;
  clearSelectedConversations: () => void;
  bulkUpdateConversationsStatus: (
    conversation_ids: Array<string>,
    status: ConversationStatusTypes.OPEN | ConversationStatusTypes.CLOSED
  ) => void;
  getAllConversationMessages: (
    id: string,
    limit: number
  ) => Promise<ConversationMessageType[]>;
  searchV2: SearchV2Type;
  setSearchV2: (search: SearchV2Type) => void;
  sideBarSearchInput: string;
  setSideBarSearchInput: (input: string) => void;
  sideBarResults: ConversationItemTypeV2[];
  setSideBarResults: (results: ConversationItemTypeV2[]) => void;
}>({
  sideBarSearchInput: '',
  setSideBarSearchInput: () => Promise.resolve(),
  sideBarResults: [],
  setSideBarResults: () => Promise.resolve(),
  conversationState: initialConversationsState,
  toggleConversationPanel: () => Promise.resolve(),
  setConversationPanel: () => Promise.resolve(),
  getOrCreateConversation: () => Promise.resolve(),
  setConversation: () => Promise.resolve(),
  bulkAddConversations: () => Promise.resolve(),
  getConversationById: () => Promise.resolve(),
  getAndSetCurrent: () => Promise.resolve(),
  addMessage: () => Promise.resolve(),
  addNote: () => Promise.resolve(),
  updateMessage: () => Promise.resolve(),
  handleMarkedClosed: () => Promise.resolve(),
  handleMarkedOpen: () => Promise.resolve(),
  changeTabIndex: () => Promise.resolve(),
  formatState: () => Promise.resolve(),
  handleAssignUser: () => Promise.resolve(),
  handleAssignTeam: () => Promise.resolve(),
  addTypingUser: () => Promise.resolve(),
  removeTypingUser: () => Promise.resolve(),
  handleMarkUnread: () => Promise.resolve(),
  handleTransferLocation: () => Promise.resolve(),
  updateConversationContact: () => Promise.resolve(),
  updateConversationContactLanguage: () => Promise.resolve(),
  inbox: initialInboxState,
  setInbox: () => Promise.resolve(),
  searchV2: initialSearchV2State,
  setSearchV2: () => Promise.resolve(),
  showTabs: true,
  setShowTabs: () => Promise.resolve(),
  advancedSearchConversations: () => Promise.resolve(),
  setLoading: () => Promise.resolve(),
  totalUnread: 0,
  resetInbox: () => Promise.resolve(),
  getConversationByLocationAndContact: () => Promise.resolve(),
  setSort: () => Promise.resolve(),
  addScheduledMessage: () => Promise.resolve(),
  updateOneScheduledMessage: () => Promise.resolve(),
  sendScheduledMessageNow: () => Promise.resolve(),
  deleteScheduledMessage: () => Promise.resolve(),
  getConversationMessages: () => Promise.resolve(),
  scheduleNewConversationMessage: () => Promise.resolve(),
  toggleForwardState: () => Promise.resolve(),
  translateMessage: () => Promise.resolve(),
  body: '',
  setBody: () => Promise.resolve(),
  selectConversation: () => Promise.resolve(),
  unselectConversation: () => Promise.resolve(),
  clearSelectedConversations: () => Promise.resolve(),
  bulkUpdateConversationsStatus: () => Promise.resolve(),
  getAllConversationMessages: () => Promise.resolve([]),
});

export const useConversation = () => useContext(ConversationContext);

export const ConversationState = ({ children }: { children: React.ReactNode }) => {
  const [conversationState, dispatch] = useReducer(
    ConversationReducer,
    initialConversationsState
  );

  // browser location and history state
  const history = useHistory();
  const location = useLocation();

  // this is used to get the conversation id from the url
  // we need this to get the conversation from the server when a user refreshes the page on a conversation
  // e.g. when they are shared a link to a conversation and do not have it in their browser history, or do
  // not click on the conversation in the sidebar
  const conversationUrlMatch = useRouteMatch<MatchParams>('/inbox/:filter/:tab/:id');
  // this is used to get the filter and tab from the url, we need this because when the user moves are the inbox,
  // and changes the tab or "inbox" e.g. filter by location, assigned to me etc, then we need to trigger a new api search call
  // to get the conversations, we cannot do this with the params above because it will not update when the user changes the tab
  // and is not on a conversation we will get null for the match and the params wrong filter will be used
  const inboxUrlMatch = useRouteMatch<MatchParams>('/inbox/:filter/:tab');
  const filter = inboxUrlMatch?.params.filter || 'all';
  const tab = inboxUrlMatch?.params.tab || 'open';

  const isDesktop = useMedia('(min-width: 912px)');
  const auth = useAuth();
  const { socket, joinChannel, handleConnect } = useSocket();

  const { channelsState } = useChannels();
  const { channels } = channelsState;

  // to keep track of the currently selected conversation
  const [conversationChannel, setConversationChannel] = useState<Channel | null>();

  // conversation inbox
  const [inbox, setInbox] = useState<SearchParams>(initialInboxState);
  const [showTabs, setShowTabs] = useState(true);

  // Sidebar conversation search
  const [sideBarSearchInput, setSideBarSearchInput] = useState('');
  const [sideBarResults, setSideBarResults] = useState<ConversationItemTypeV2[]>([]);

  // conversation search V2 (queries to OpenSearch with Postgres fallback)
  const [searchV2, setSearchV2] = useState<SearchV2Type>(initialSearchV2State);

  // track if the browser is left idle for 60 seconds?
  // we use this to refresh conversation data
  // because websocket connections can close when the browser is idle
  // and data can become stale
  const isIdle = useIdle(60e3);

  // message editor state
  const [body, setBody] = useState(
    conversationState.isForwarded ? conversationState.forwardingParams.message || '' : ''
  );

  // controls the open/close state of contacts panel
  const toggleConversationPanel = async (value: boolean) => {
    dispatch({
      type: ConversationsActionTypes.SET_PANEL_STATE,
      payload: value,
    });
  };

  const setConversationPanel = async (type: ConversationPanelTypes | null) => {
    dispatch({ type: ConversationsActionTypes.SET_CONVERSATION_PANEL, payload: type });
  };

  // when the browser is no longer idle then refresh the conversations
  useEffect(() => {
    console.log('isIdle', isIdle);
    if (isIdle === false) {
      console.log('Refreshing all conversations after tab idle');
      const searchParams = {
        ...inbox,
        sort: {
          resource: 'conversation',
          order: conversationState.sort === 'oldest' ? 'asc' : 'desc',
          column:
            conversationState.sort === 'unread'
              ? 'unread_count'
              : 'last_message_timestamp',
        },
      };
      advancedSearchConversations(searchParams);
      if (conversationState.current) {
        console.log(`refreshing current conversation - ${conversationState.current.id}`);
        // reset and get current conversation
        getAndSetCurrent(conversationState.current.id);
      }
    }
  }, [isIdle]);

  // on mount or route change set current conversation
  // get and set current conversation if it does not exist
  // in the conversations state
  useEffect(() => {
    const current = conversationState.conversations.find(
      (c: ConversationType) => c.id === conversationUrlMatch?.params.id
    ) as ConversationType;

    const queryParams = new URLSearchParams(location.search);
    const messageId = queryParams.get('message_id');

    if (
      isValidUuid(conversationUrlMatch?.params.id as string) &&
      (conversationState.current === null ||
        conversationState?.current?.id !== conversationUrlMatch?.params.id ||
        messageId) &&
      messageId
    ) {
      dispatch({
        type: ConversationsActionTypes.SET_CONVERSATION,
        payload: null,
      });
      getAndSetCurrent(conversationUrlMatch?.params.id as string, messageId ?? undefined);
    } else if (current) {
      const initialConversation = { ...current, isInitialConversation: true };
      setConversation(initialConversation);
      dispatch({
        type: ConversationsActionTypes.SET_CONVERSATION,
        payload: initialConversation,
      });
      getAndSetCurrent(conversationUrlMatch?.params.id as string, messageId ?? undefined);
      resetSidebarSearch();
    } else if (
      isValidUuid(conversationUrlMatch?.params.id as string) &&
      (conversationState.current === null ||
        conversationState?.current?.id !== conversationUrlMatch?.params.id)
    ) {
      getAndSetCurrent(conversationUrlMatch?.params.id as string, messageId ?? undefined);
      resetSidebarSearch();
    }
  }, [conversationUrlMatch?.params.id, location.search]);

  /**
   *
   * Handle conversation related websocket connections
   *
   */

  // if socket is null - connect to socket
  useEffect(() => {
    if (!socket) {
      handleConnect();
    }

    if (socket && conversationState.current) {
      setConversation(conversationState.current);
    }
  }, [socket]);

  // when the conversation channels receives an update
  // then update state
  useEffect(() => {
    if (!conversationChannel) return;

    conversationChannel.on(
      ConversationWebsocketEventTypes.UPDATE_CONVERSATION,
      (payload: ConversationType) => {
        dispatch({
          type: ConversationsActionTypes.UPDATE_CONVERSATION,
          payload: payload,
        });
      }
    );
  }, [conversationChannel]);

  // connect to the conversation channel for each of the channels
  // the user has access to
  useEffect(() => {
    channels.map((l: ChannelType) => {
      const channel: Channel | null = joinChannel(`conversation:location:${l.id}`);

      // Bulk update conversation
      if (channel) {
        channel.on(ConversationWebsocketEventTypes.BULK_UPDATE_CONVERSATION, () => {
          advancedSearchConversations(inbox);
        });

        channel.on(
          ConversationWebsocketEventTypes.UPDATE_CONVERSATION,
          (payload: PartialWithId<ConversationType>) => {
            dispatch({
              type: ConversationsActionTypes.UPDATE_CONVERSATION,
              payload: payload,
            });
          }
        );

        channel.on(
          ConversationWebsocketEventTypes.UPDATE_CONVERSATION_LANGUAGES,
          (payload: PartialWithId<ConversationType>) => {
            dispatch({
              type: ConversationsActionTypes.UPDATE_CONVERSATION,
              payload: payload,
            });
          }
        );

        channel.on(
          ConversationWebsocketEventTypes.NEW_CONVERSATION,
          (payload: PartialWithId<ConversationType>) => {
            dispatch({
              type: ConversationsActionTypes.NEW_CONVERSATION,
              payload: payload,
            });
          }
        );

        channel.on(
          ConversationWebsocketEventTypes.REMOVE_CONVERSATION,
          (payload: { id: string }) => {
            dispatch({
              type: ConversationsActionTypes.REMOVE_CONVERSATION,
              payload: payload,
            });
          }
        );

        channel.on(
          ConversationWebsocketEventTypes.ADD_NOTE,
          (payload: ConversationMessageType) => {
            dispatch({
              type: ConversationsActionTypes.ADD_MESSAGE,
              payload: payload,
            });
          }
        );

        // add message to the conversation and trigger notification
        channel.on(
          ConversationWebsocketEventTypes.ADD_MESSAGE,
          (payload: ConversationMessageType) => {
            dispatch({
              type: ConversationsActionTypes.ADD_MESSAGE,
              payload: payload,
            });
          }
        );

        // update message without triggering notification
        channel.on(
          ConversationWebsocketEventTypes.UPDATE_MESSAGE,
          (payload: ConversationMessageType) => {
            dispatch({
              type: ConversationsActionTypes.ADD_MESSAGE,
              payload: payload,
            });
          }
        );

        // sends the last two messages in the conversation
        channel.on(
          ConversationWebsocketEventTypes.UPSERT_MESSAGES,
          (payload: { data: ConversationMessageType[] }) => {
            dispatch({
              type: ConversationsActionTypes.UPSERT_MESSAGES,
              payload: payload.data,
            });
          }
        );

        channel.on(
          ConversationWebsocketEventTypes.ADD_SCHEDULED_MESSAGE,
          (payload: { params: ConversationScheduledMessageType }) => {
            toast.success(i18next.t('scheduled_message_created_success') as string);
            dispatch({
              type: ConversationsActionTypes.ADD_SCHEDULED_MESSAGE,
              payload: payload,
            });
          }
        );

        channel.on(
          ConversationWebsocketEventTypes.SEND_SCHEDULED_MESSAGE_NOW,
          (payload: { params: ConversationScheduledMessageType }) => {
            dispatch({
              type: ConversationsActionTypes.SEND_SCHEDULED_MESSAGE_NOW,
              payload: payload,
            });
          }
        );

        channel.on(
          ConversationWebsocketEventTypes.UPDATE_SCHEDULED_MESSAGE,
          (payload: { params: ConversationScheduledMessageType }) => {
            toast.success(i18next.t('scheduled_message_updated_success') as string);
            dispatch({
              type: ConversationsActionTypes.UPDATE_SCHEDULED_MESSAGE,
              payload: payload,
            });
          }
        );

        channel.on(
          ConversationWebsocketEventTypes.DELETE_SCHEDULED_MESSAGE,
          (payload: { params: { job_id: number } }) => {
            toast.success(i18next.t('scheduled_message_deleted_success') as string);
            dispatch({
              type: ConversationsActionTypes.DELETE_SCHEDULED_MESSAGE,
              payload: payload,
            });
          }
        );

        channel.on(
          ConversationWebsocketEventTypes.NEW_EVENT,
          (payload: ConversationEventType) => {
            dispatch({
              type: ConversationsActionTypes.ADD_MESSAGE,
              payload: payload,
            });
          }
        );
      }
    });
  }, [auth, JSON.stringify(channels), socket]);

  // Resets the results in the sidebar search
  const resetSidebarSearch = () => {
    setSideBarResults([]);
    setSideBarSearchInput('');
  };

  // Function that will get or create a conversation between
  // a contact and a location redirect to the conversation
  const getOrCreateConversation = async (params: NewConversationParamsType) => {
    try {
      const data = await API.createConversation(params);

      dispatch({
        type: ConversationsActionTypes.NEW_CONVERSATION,
        payload: data,
      });

      setConversation(data);

      if (inboxUrlMatch) {
        return history.push(`/inbox/${filter}/${tab}/${data.id}`);
      }
    } catch (err) {
      console.log('Error creating conversation:', err);
      throw err;
    }
  };

  const getConversationMessages = async (
    id: string,
    offset: number,
    limit: number,
    loadBefore?: boolean
  ) => {
    try {
      const data = await API.getConversationMessages(id, offset, limit);
      if (loadBefore) {
        dispatch({
          type: ConversationsActionTypes.UPSERT_MESSAGES_BEFORE,
          payload: data,
        });
      } else {
        dispatch({
          type: ConversationsActionTypes.UPSERT_MESSAGES,
          payload: data,
        });
      }

      return data;
    } catch (err) {
      console.error('Error fetching conversation messages:', err);
    }
  };

  // get all conversation messages
  const getAllConversationMessages = async (
    id: string,
    limit: number
  ): Promise<ConversationMessageType[]> => {
    try {
      const { messages } = await getConversationMessagesRecursively(id, [], limit, 0);
      return messages;
    } catch (err) {
      console.warn('Error fetching all conversation messages:', err);
    }
    return [];
  };

  // recursively fetch conversation messages
  const getConversationMessagesRecursively = async (
    id: string,
    messages: ConversationMessageType[] | [],
    limit: number,
    recursionCount: number
  ): Promise<{
    messages: ConversationMessageType[];
    index: number;
  }> => {
    const messageFetched: ConversationMessageType[] = await API.getConversationMessages(
      id,
      recursionCount * limit
    );
    // fetch all messages then if count is equal to current limit
    // call again the function carrying the current list of messages and the recursion count
    // recursion count is passed to set it as the offset value, to get the next list
    if (messageFetched.length === limit) {
      const totalMessages = [...messages, ...messageFetched];
      return getConversationMessagesRecursively(
        id,
        totalMessages,
        limit,
        recursionCount + 1
      );
    }
    // if fetched messages is now not equal to limit, then all messages are now fetched return all the messages
    return {
      messages: [...messages, ...messageFetched],
      index: recursionCount + 1,
    };
  };

  // sends closed event to the conversation topic
  // if closeAndNavigate is true then it will navigate to the next conversation
  // it should be false when closing from the drawer
  const handleMarkedClosed = (closeAndNavigate?: boolean) => {
    conversationChannel?.push(ConversationWebsocketEventTypes.CLOSED, {});

    if (closeAndNavigate === undefined || closeAndNavigate === true) {
      if (isDesktop) {
        return openNextConversation();
      } else {
        return history.push('/inbox/all/open');
      }
    }
  };

  // sends open event to the conversation topic
  // if openAndNavigate is true then it will navigate to the next conversation
  // it should be false when opening from the drawer
  const handleMarkedOpen = (openAndNavigate?: boolean) => {
    conversationChannel?.push(ConversationWebsocketEventTypes.OPEN, {});

    if (openAndNavigate === undefined || openAndNavigate === true) {
      resetInbox();

      return history.push(`/inbox/${filter}/open/${conversationState.current?.id}`);
    }
  };

  const isMyInbox = location.pathname.includes('/inbox/me');
  const isUnassignedInbox = location.pathname.includes('/inbox/unassigned');
  const isGhostedInbox = location.pathname.includes('/inbox/ghosted');

  useEffect(() => {
    console.log('Re-setting conversation search query');
    resetInbox();
  }, [filter, tab]);

  const resetInbox = async () => {
    let params = { ...inbox, offset: 0 };

    switch (filter) {
      case ConversationFilterTypes.ALL:
        // remove the locations_ids from the search params and remove the assigned_users_ids
        params = { sort: params.sort, limit: params.limit, offset: params.offset };
        break;
      case ConversationFilterTypes.ME:
        params = {
          ...params,
          // TO DO: Fix Typescript error
          assigned_users_ids: [
            auth?.tokens?.user_id ? auth?.tokens?.user_id : ('' as unknown as number),
          ],
        };
        break;
      case ConversationFilterTypes.UNASSIGNED:
        params = { ...params, unassigned: true };
        break;
      case ConversationFilterTypes.GHOSTED:
        params = {
          ...params,
          last_message_source_type: [ConversationItemSourceTypes.INBOUND],
        };
        break;
      default:
        params = isValidUuid(filter)
          ? { ...params, locations_ids: [filter] }
          : { ...params, assigned_users_ids: [Number(filter)] };
        break;
    }

    if (!isMyInbox) {
      const { ...rest } = params;
      params = { ...rest };
    }

    // filter is a user id
    if (!isValidUuid(filter) && !isNaN(Number(filter))) {
      const { locations_ids, ...rest } = params;
      params = { ...rest };
    }

    // filter is a location id
    if (isValidUuid(filter)) {
      const { assigned_users_ids, ...rest } = params;
      params = { ...rest };
    }

    if (!isUnassignedInbox) {
      const { unassigned, ...rest } = params;
      params = { ...rest };
    }

    if (!isGhostedInbox) {
      const { last_message_source_type, ...rest } = params;
      params = { ...rest };
    }

    setInbox(params);
  };

  // Sends ASSIGN_USER event to the conversation topic
  const handleAssignUser = (user: User | null) => {
    conversationChannel?.push(ConversationWebsocketEventTypes.ASSIGN_USER, {
      assigned_user_id: user?.id || null,
      assigned_user_name: user?.name || null,
      assigned_user_email: user?.email || null,
    });
  };

  // Sends ASSIGN_TEAM event to the conversation topic
  const handleAssignTeam = (team: Team | null) => {
    conversationChannel?.push(ConversationWebsocketEventTypes.ASSIGN_TEAM, {
      assigned_team_id: team?.id || null,
      assigned_team_name: team?.name || null,
    });
  };

  const translateMessage = (params: TranslateMessageParams) => {
    conversationChannel?.push(ConversationWebsocketEventTypes.TRANSLATE_MESSAGE, params);
  };

  const updateMessage = (params: { message_id: string; visibility_status?: string }) => {
    conversationChannel?.push(ConversationWebsocketEventTypes.UPDATE_MESSAGE, params);
  };

  // sends ADD_MESSAGE event to the conversation topic
  const addMessage = (message: {
    body: string;
    attachment_urls: Array<string>;
    translated_body?: string;
  }) => {
    const formattedMessage = message.translated_body
      ? {
          ...message,
          body: message.body.trim(),
          translated_body: message.translated_body.trim(),
        }
      : {
          ...message,
          body: message.body.trim(),
        };
    conversationChannel?.push(
      ConversationWebsocketEventTypes.ADD_MESSAGE,
      formattedMessage
    );
  };

  const addNote = (note: { body?: string; attachment_urls?: Array<string> }) => {
    conversationChannel?.push(ConversationWebsocketEventTypes.ADD_NOTE, note);
  };

  const addScheduledMessage = (
    message: {
      body: string;
      attachment_urls: Array<string>;
    },
    scheduleParams: ScheduleConversationItemParamsType
  ) => {
    if (conversationChannel?.state === 'joined' && socket?.connectionState() === 'open') {
      conversationChannel?.push(ConversationWebsocketEventTypes.ADD_SCHEDULED_MESSAGE, {
        params: {
          message: {
            ...message,
            body: message.body.trim(),
          },
        },
        schedule_options: scheduleParams,
        target: 'message',
      });
    } else {
      conversationState?.current &&
        scheduleNewConversationMessage({
          contact: {
            name: conversationState?.current?.contact?.name || '',
            phone: conversationState?.current?.contact?.phone || '',
            email: conversationState?.current?.contact?.email || '',
          },
          location_id: conversationState?.current?.location_id,
          scheduled_message: {
            params: {
              message: {
                ...message,
                body: message.body.trim(),
              },
              user_id: Number(auth?.tokens?.user_id),
              organization_id: String(auth?.tokens?.organization_id),
            },
            schedule_options: scheduleParams,
            target: 'message',
          },
        })
          .then((data) => {
            const scheduleMessageComplete: Promise<void> = Promise.any([data]);

            if (!(scheduleMessageComplete instanceof Promise))
              throw new Error('Conversation is not a promise');

            toast.promise(scheduleMessageComplete, {
              loading: 'Scheduling message',
              success: 'Message scheduled',
              error: 'Failed to schedule message',
            });
          })
          .catch((error) => {
            console.warn(error);
            toast.error('Could not schedule message');
          });
    }
  };

  // leave old conversation channel abd joins a new one
  // updates the current conversation in state with a new one
  const setConversation = (
    conversation: ConversationWithStateType | null,
    offset?: number,
    messageId?: string
  ) => {
    // leave the old conversation channel before joining a new one
    if (conversationChannel) {
      conversationChannel?.leave();
    }

    if (conversation?.id) {
      // join the new conversation channel
      const channel = joinChannel(`conversation:${conversation.id}`);

      // list for a new message translation
      channel?.on(
        ConversationWebsocketEventTypes.TRANSLATE_MESSAGE,
        (payload: ConversationMessageType) => {
          dispatch({
            type: ConversationsActionTypes.ADD_MESSAGE,
            payload: payload,
          });
        }
      );

      // set the new conversation channel in state
      setConversationChannel(channel);

      if (offset) {
        conversation.topOffset = offset + 50;
        conversation.bottomOffset = offset;
      }

      // set the new conversation in state
      dispatch({
        type: ConversationsActionTypes.SET_CONVERSATION,
        payload: { ...conversation, searchMessageId: messageId },
      });
    }
  };

  const addTypingUser = () => {
    conversationChannel?.push(ConversationWebsocketEventTypes.ADD_TYPING_USER, {});
  };

  const removeTypingUser = () => {
    conversationChannel?.push(ConversationWebsocketEventTypes.REMOVE_TYPING_USER, {});
  };

  const handleMarkUnread = () => {
    conversationChannel?.push(ConversationWebsocketEventTypes.MARK_UNREAD, {});
  };

  const handleTransferLocation = (locationId: string) => {
    conversationChannel?.push(ConversationWebsocketEventTypes.TRANSFER_LOCATION, {
      location_id: locationId,
    });
  };

  const getConversationById = async (conversationId: string) => {
    if (conversationId) {
      try {
        const data = await API.getConversation(conversationId);
        if (data !== null && data.id) {
          dispatch({
            type: ConversationsActionTypes.NEW_CONVERSATION,
            payload: data,
          });
        }
      } catch (err) {
        console.warn('Error fetching conversation by ID:', err);
      }
    }
  };

  // Use the useRef hook to keep track of the current AbortController instance
  const currentController = useRef<AbortController | null>(null);

  /**
   * Cancel the previous request by calling the abort method on the current AbortController instance,
   * and then set the current instance to null.
   */
  const cancelPreviousRequest = () => {
    if (currentController.current) {
      currentController.current.abort();
      currentController.current = null;
    }
  };

  /**
   * Fetches the conversation with the given ID, dispatches the result to the store,
   * and sets the current conversation state. If there is no valid data in the response,
   * it redirects to the open filter inbox view.
   * Cancels any pending request before making a new one. This is to prevent state mismatches.
   *
   * @param conversationId - The ID of the conversation to fetch and set as current
   * @param messageId - The ID of the message to fetch conversation and scroll to searched message
   */
  const getAndSetCurrent = async (conversationId: string, messageId?: string) => {
    cancelPreviousRequest();
    const controller = new AbortController();
    currentController.current = controller;

    try {
      const data = await API.getConversation(conversationId, controller);
      // Check for a valid response with a defined ID
      if (messageId) {
        const result = await API.searchMessageById(conversationId, messageId);
        data.conversationItemsPage.conversationItems = result.data;
        setNewConversation(data, result.offset, messageId);
      } else {
        setNewConversation(data, 0);
      }
    } catch (err) {
      console.warn('Error fetching and setting current conversation:', err);
    }
  };

  const setNewConversation = (
    data: ConversationWithStateType,
    offset: number,
    messageId?: string
  ) => {
    if (data !== null && data.id) {
      dispatch({
        type: ConversationsActionTypes.NEW_CONVERSATION,
        payload: data,
      });
      setConversation(data, offset, messageId);
    } else {
      // Redirect to the open filter inbox view if there is no valid response
      history.push(`/inbox/${filter}/open`);
    }
  };

  const getConversationByLocationAndContact = async (
    contact_id: string,
    location_id: string
  ): Promise<void> => {
    try {
      const data = await API.getConversationByLocationAndContact(contact_id, location_id);

      if (data !== null) {
        // set the new conversation in state
        dispatch({
          type: ConversationsActionTypes.NEW_CONVERSATION,
          payload: data,
        });
        // set the new conversation channel
        setConversation(data);
        // if in inbox
        if (inboxUrlMatch) {
          // redirect to the conversation
          history.push(`/inbox/${filter}/${data.status}/${data.id}`);
        }
      } else {
        dispatch({
          type: ConversationsActionTypes.SET_CONVERSATION,
          payload: null,
        });
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const advancedSearchConversations = async (params: SearchParams) => {
    const data = await API.advancedSearchConversations(params);

    try {
      dispatch({
        type: ConversationsActionTypes.APPEND_CONVERSATIONS,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
    return [];
  };

  const bulkAddConversations = async (messages: Array<any>) => {
    try {
      await API.bulkImportConversations(messages);
      toast.success(i18next.t('conversations_bulk_import_success') as string);
    } catch (err) {
      console.error(err);
      if (err.response) {
        if (err.response.data.errors.detail.startsWith('Channel with phone') as string) {
          toast.error(err.response.data.errors.detail);
        } else {
          toast.error(i18next.t('conversations_bulk_import_failure') as string);
        }
      } else {
        toast.error(i18next.t('conversations_bulk_import_failure') as string);
      }
    }
  };

  const deleteScheduledMessage = (id: number) => {
    conversationChannel?.push(ConversationWebsocketEventTypes.DELETE_SCHEDULED_MESSAGE, {
      job_id: id,
    });
  };

  const sendScheduledMessageNow = (id: number) => {
    conversationChannel?.push(
      ConversationWebsocketEventTypes.SEND_SCHEDULED_MESSAGE_NOW,
      { job_id: id }
    );
  };

  const updateOneScheduledMessage = (
    id: number,
    scheduledMessage: {
      message: {
        body: string;
        attachment_urls: Array<string>;
      };
      scheduleParams: ScheduleConversationItemParamsType;
    }
  ) => {
    conversationChannel?.push(ConversationWebsocketEventTypes.UPDATE_SCHEDULED_MESSAGE, {
      job_id: id,
      params: {
        message: {
          attachment_urls: scheduledMessage.message.attachment_urls,
          body: scheduledMessage.message.body.trim(),
        },
      },
      schedule_options: scheduledMessage.scheduleParams,
      target: 'message',
    });
  };

  const updateConversationContact = (phone: string) => {
    try {
      dispatch({
        type: ConversationsActionTypes.UPDATE_CONVERSATION_CONTACT,
        payload: phone,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const updateConversationContactLanguage = (language: string) => {
    try {
      dispatch({
        type: ConversationsActionTypes.UPDATE_CONTACT_LANGUAGE,
        payload: language,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const changeTabIndex = (index: string) => {
    dispatch({
      type: ConversationsActionTypes.CHANGE_TAB,
      payload: index,
    });
  };

  const setLoading = (loading: boolean) => {
    dispatch({
      type: ConversationsActionTypes.SET_LOADING,
      payload: loading,
    });
  };

  const setSort = (sort: ConversationSortTypes) => {
    dispatch({
      type: ConversationsActionTypes.SET_SORT,
      payload: sort,
    });
  };

  const openNextConversation = () => {
    return history.push(`/inbox/${filter}/open`);
  };

  const [totalUnread, setTotalUnread] = useState(0);

  const calculateTotalUnread = () => {
    return conversationState?.conversations
      ?.filter((item) => item?.status === ConversationStatusTypes.OPEN)
      ?.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue?.unread_count || 0);
      }, 0);
  };

  useEffect(() => {
    const total = calculateTotalUnread();
    setTotalUnread(total);
  }, [conversationState?.conversations]);

  const toggleForwardState = (isForwarded: boolean, forwardingParams: ForwardParams) => {
    dispatch({
      type: ConversationsActionTypes.TOGGLE_FORWARD,
      payload: { isForwarded, forwardingParams },
    });
  };

  const scheduleNewConversationMessage = async (
    params: NewScheduledConversationParamsType
  ): Promise<void> => {
    const data = await API.createConversation(params);

    try {
      dispatch({
        type: ConversationsActionTypes.NEW_CONVERSATION,
        payload: data,
      });

      setConversation(data);

      if (inboxUrlMatch) {
        return history.push(`/inbox/${filter}/${tab}/${data.id}`);
      }
      return;
    } catch (err) {
      console.error(err);
    }
  };

  const selectConversation = (id: string) => {
    dispatch({
      type: ConversationsActionTypes.SELECT_CONVERSATION,
      payload: id,
    });
  };

  const unselectConversation = (id: string) => {
    dispatch({
      type: ConversationsActionTypes.UNSELECT_CONVERSATION,
      payload: id,
    });
  };

  const clearSelectedConversations = () => {
    dispatch({
      type: ConversationsActionTypes.CLEAR_SELECTED_CONVERSATIONS,
      payload: null,
    });
  };

  // when the tab changes, clear the selected conversations
  useEffect(() => {
    clearSelectedConversations();
  }, [tab, filter]);

  // bulk change the status of conversations
  const bulkUpdateConversationsStatus = async (
    conversation_ids: Array<string>,
    status: ConversationStatusTypes.OPEN | ConversationStatusTypes.CLOSED
  ) => {
    try {
      await API.bulkUpdateConversationStatus(conversation_ids, status);

      dispatch({
        type: ConversationsActionTypes.BULK_UPDATE_CONVERSATION_STATUS,
        payload: { conversation_ids, status },
      });

      clearSelectedConversations();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ConversationContext.Provider
      value={{
        conversationState,
        inbox: inbox,
        showTabs,
        totalUnread,
        body,
        searchV2,
        setSearchV2,
        sideBarSearchInput,
        setSideBarSearchInput,
        sideBarResults,
        setSideBarResults,
        toggleConversationPanel,
        getOrCreateConversation,
        getConversationById,
        getAndSetCurrent,
        bulkAddConversations,
        setConversation,
        addMessage,
        addNote,
        updateMessage,
        handleMarkedClosed,
        handleMarkedOpen,
        changeTabIndex,
        formatState,
        handleAssignUser,
        handleAssignTeam,
        addTypingUser,
        removeTypingUser,
        handleMarkUnread,
        handleTransferLocation,
        updateConversationContact,
        updateConversationContactLanguage,
        setInbox: setInbox,
        setShowTabs,
        advancedSearchConversations,
        setLoading,
        resetInbox,
        getConversationByLocationAndContact,
        setSort,
        addScheduledMessage,
        updateOneScheduledMessage,
        deleteScheduledMessage,
        getConversationMessages,
        scheduleNewConversationMessage,
        sendScheduledMessageNow,
        setConversationPanel,
        toggleForwardState,
        translateMessage,
        setBody,
        selectConversation,
        unselectConversation,
        clearSelectedConversations,
        bulkUpdateConversationsStatus,
        getAllConversationMessages,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};

export default ConversationState;
