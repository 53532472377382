import React from 'react';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { ChannelTypes } from '@/shared/types';
import { Channel } from '@/shared/types/channels';
import { Box } from '@/shared/ui';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';

type SelectLocationProps = {
  /** the location the campaign is scheduled */
  location: string;
  /** set the location the campaign is scheduled */
  setLocation: (location: string) => void;
  /** is the select disabled? */
  disabled?: boolean;
  /** The channel type to filter by */
  channelType?: ChannelTypes[];
};

export const SelectLocation = (props: SelectLocationProps): JSX.Element => {
  const { location, setLocation, disabled } = props;

  const channelContext = useChannels();
  const { channelsState } = channelContext;
  const { channels: allChannels } = channelsState;

  // filter out none relevant channels when specified
  // otherwise return all channel types
  const channels = allChannels.filter((channel) => {
    if (props.channelType && channel.type) {
      return props.channelType.includes(channel.type);
    } else {
      return true;
    }
  });

  const getLocationName = (id: string) =>
    channels.find((location) => location.id === id)?.name;

  const generateLocationName = (location: Channel) => {
    switch (location.type) {
      case 'phone':
        return `SMS: ${location.name} - ${formatPhoneNumber(location.phone)}`;
      case 'email':
        return `Email: ${location.name}`;
      case 'whatsapp':
        return `WhatsApp: ${location.name}`;
      default:
        return `SMS: ${location.name} - ${formatPhoneNumber(location.phone)}`;
    }
  };

  // map over channels and filter out channels that are not enabled
  const enabledChannels = channels.filter(
    (channel: Channel) => channel.state === 'enabled'
  );

  return (
    <Box>
      {channels.length > 0 && (
        <SingleSelect
          disabled={disabled ? true : false}
          selectItem={location}
          setSelectItem={setLocation}
          closeOnClick={true}
          options={enabledChannels.map((location: Channel) => ({
            type: generateLocationName(location),
            value: location?.id,
          }))}
          defaultPlaceholder={getLocationName(location || '') || 'Select Channel'}
          isDropdown={true}
        />
      )}
    </Box>
  );
};
