/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-case-declarations */
import { PopoverPortal } from '@radix-ui/react-popover';
import { TooltipTrigger } from '@radix-ui/react-tooltip';
import { captureException } from '@sentry/react';
import Fuse from 'fuse.js';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  HiEmojiHappy,
  HiLightningBolt,
  HiOutlineReply,
  HiPencilAlt,
  HiTemplate,
  HiUserGroup,
} from 'react-icons/hi';
import { CaretPosition, createRegexRenderer, RichTextarea } from 'rich-textarea';
import { toast } from 'sonner';

import { useConversation } from '@/pages/inbox/context/ConversationContext';
import { useSettings } from '@/pages/settings/organization/general/context/SettingsContext';
import { Channel } from '@/shared/types/channels';
import { User } from '@/shared/types/users';
import {
  Badge,
  Box,
  HStack,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  TooltipContent,
} from '@/shared/ui';

import { Spinner } from '../../Icons';
import { ICON_SIZE } from '.';
import { useAIContext } from './context/AIContext';
import { useFocusedContext } from './context/FocusedContext';
import { Menu, Option } from './Menu';
import {
  MessageSegmentVisualizer,
  returnSegmentLength,
} from './MessageSegmentVisualizer';
import { EditorPosition, emptyRegex, numericMentions, pipeRegex } from './utils';

const textareaMinHeight = 55;
const textareaMaxHeight = 200;

export type EditorProps = {
  // Editor State Props
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  setCharacterCount: (characterCount: number) => void;

  // UI Feature Toggles (default to false if optional)
  showAutoComplete?: boolean;
  showAddNote?: boolean;
  showAddTemplate?: boolean;
  showAddReview?: boolean;
  showAddEmoji?: boolean;
  showShortcuts?: boolean;

  // Contextual Information and Other Props
  source?: string;
  templateButton: React.RefObject<HTMLDivElement>;
  reviewButton: React.RefObject<HTMLDivElement>;
  emojiButton: React.RefObject<HTMLButtonElement>;
  aiBoxButton: React.RefObject<HTMLButtonElement>;
  users: User[];
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  placeholder?: string;
  maxHeight?: number;
  minHeight?: number;
  // Limits are only applied to phone messages
  channel_type?: 'phone' | 'email' | 'whatsapp';
};

export const Editor = (props: EditorProps) => {
  const {
    message,
    source,
    setCharacterCount,
    setMessage,
    users,
    showAutoComplete,
    emojiButton,
    templateButton,
    reviewButton,
    aiBoxButton,
    showAddNote,
    showAddTemplate,
    showAddEmoji,
    showShortcuts,
    textareaRef,
    placeholder,
    channel_type = 'phone',
    maxHeight = textareaMaxHeight,
    minHeight = textareaMinHeight,
  } = props;

  const { conversationState } = useConversation();
  const { current } = conversationState;

  const {
    promptMessage,
    completionEnabled,
    setPromptMessage,
    isPromptLoading,
    setIsPromptLoading,
    setCompletionEnabled,
  } = useAIContext();
  const {
    mentionActive,
    setMentionActive,
    isNote,
    setShortcutActive,
    setTemplatesModalActive,
    setReviewRequestTemplatesModalActive,
    shortcutActive,
    setIsNote,
    setAiModalActive,
  } = useFocusedContext();
  const { conversationCompletion, aiSentenceGenerator } = useFlags();

  const [textAreaHeight, setTextAreaHeight] = useState(minHeight);

  // Replace basic non-GSM7 characters with their GSM7 equivalent
  // These typically come from applications like Google Docs or Microsoft Word
  // But they cost more than 1 character in SMS, which is why we replace them
  function replaceNonGSM7Characters(input: string) {
    try {
      return input
        .replace(/–/g, '-')
        .replace(/`/g, "'")
        .replace(/“|”/g, '"')
        .replace(/‘|’/g, "'")
        .replace(/—/g, '-')
        .replace(/…/g, '...')
        .replace(/•/g, '*');
    } catch (error) {
      console.error('Error replacing non-GSM7 characters:', error);
      return input; // return the original string if an error occurs
    }
  }

  // This function is used to handle the message change
  // It replaces non-GSM7 characters with their GSM7 equivalent
  // Do no call setMessage directly, use this function instead
  const handleSetMessage = (value: string) => {
    const newValue = replaceNonGSM7Characters(value);
    setMessage(newValue);
  };

  // Action search (text and index) is used to filter the Menu options based on user input
  // It is triggered by typing @ or / in the editor
  const [actionSearchText, setActionSearchText] = useState('');
  const [actionSearchIndex, setActionSearchIndex] = useState(0);

  // Cursor position is used to position the Menu relative to the users typing cursor
  // The Menu currently supports @mention and / shortcuts features
  const [cursorPosition, setCursorPosition] = useState<EditorPosition | null>(null);
  const defaultCursorPosition: EditorPosition = {
    top: 0,
    left: 0,
    caret: {
      focused: true,
      selectionStart: 0,
      selectionEnd: 0,
      top: 0,
      height: 0,
      left: 0,
    },
  };

  // Reset search for mentions and shortcuts
  const resetSearch = () => {
    setActionSearchText('');
    setActionSearchIndex(0);
    setMentionActive(false);
    setShortcutActive(false);
    setAiModalActive(false);
  };

  useEffect(() => {
    // Update character count whenever message changes
    setCharacterCount(message.length);

    // Handle textarea height
    if (textareaRef.current) {
      // Temporarily set the style height to auto
      textareaRef.current.style.height = 'auto';

      const newHeight = textareaRef.current.scrollHeight || minHeight;
      if (newHeight !== textAreaHeight) {
        setTextAreaHeight(newHeight);
      } else if (message === '') {
        setTextAreaHeight(minHeight);
      }

      // Set the style height back to the new height
      textareaRef.current.style.height = `${newHeight}px`;
    }
  }, [message]);

  // create regex out of user to mention
  const locationId = current?.location_id || '';

  // Filter users to @mention who have access to the current location
  const eligibleUsers: Option[] = useMemo<Option[]>(() => {
    const filteredUsers = users
      .filter((user: User) => {
        const userLocations = user?.locations || [];
        const userInLocation = userLocations.find(
          (location: Channel) => location?.id === locationId
        );
        return !!userInLocation; // Return true if userInLocation exists, false otherwise
      })
      .map((user: User) => {
        // Transform User objects into Option objects
        return {
          id: user.id,
          name: user.name,
          email: user.email,
          attachment: user.attachment ? { url: user.attachment.url || '' } : undefined,
        };
      });

    // Adding special tags '@all' and '@here' to the list of eligible users
    // @all is at the top so it will be shown at the top of the list, @here is at the bottom of the list
    return [
      {
        id: 'all',
        name: '@all - Tag Everyone',
        value: 'all',
        email: '',
        attachment: undefined,
        icon: <HiUserGroup size={ICON_SIZE} />,
      },
      ...filteredUsers,
      {
        id: 'here',
        name: '@here - Tag Everyone',
        value: 'here',
        email: '',
        attachment: undefined,
        icon: <HiUserGroup size={ICON_SIZE} />,
      },
    ];
  }, [locationId, users]);

  const mentionOptions = useMemo(() => {
    if (!eligibleUsers || !mentionActive) return [];
    if (!actionSearchText) return eligibleUsers;

    const searchOptions: Fuse.IFuseOptions<{ name?: string; email?: string }> = {
      includeScore: true,
      keys: ['name', 'email'],
      threshold: 0.3,
    };
    const userSearchEngine = new Fuse(eligibleUsers, searchOptions);

    // Perform the fuzzy search
    const result = userSearchEngine.search(actionSearchText);
    const options = result.map(({ item }) => item);
    if (options.length === 0) {
      // If there are no @mention matches, reset the search
      resetSearch();
    }
    return options;
  }, [actionSearchText, eligibleUsers, mentionActive]);

  const shortcutOptions = useMemo(() => {
    if (!shortcutActive || !showShortcuts) return [];

    const baseOptions: {
      id: number;
      action: string;
      name: string;
      icon?: JSX.Element;
    }[] = [];

    if (aiSentenceGenerator) {
      baseOptions.push({
        id: 4,
        action: 'open-ai-generator',
        name: 'AI Generator',
        icon: <HiLightningBolt size={ICON_SIZE} className="animated-text" />,
      });
    }

    if (showAddTemplate) {
      baseOptions.push({
        id: 0,
        action: 'open-templates',
        name: 'Templates',
        icon: <HiTemplate size={ICON_SIZE} />,
      });
    }

    if (showAddEmoji) {
      baseOptions.push({
        id: 3,
        action: 'open-emojis',
        name: 'Emojis',
        icon: <HiEmojiHappy size={ICON_SIZE} />,
      });
    }

    let noteOption;
    if (showAddNote) {
      if (isNote) {
        noteOption = {
          id: 2,
          action: 'switch-to-reply',
          name: 'Switch to Reply mode',
          icon: <HiOutlineReply size={ICON_SIZE} />,
        };
      } else {
        noteOption = {
          id: 1,
          action: 'switch-to-notes',
          name: 'Switch to Notes mode',
          icon: <HiPencilAlt size={ICON_SIZE} />,
        };
      }
      baseOptions.push(noteOption);
    }

    if (!actionSearchText || actionSearchText === '/') return baseOptions;

    const shortcutSearchEngine = new Fuse(baseOptions, {
      includeScore: true,
      keys: ['name', 'action'],
      threshold: 0.3,
    });

    const result = shortcutSearchEngine.search(actionSearchText);
    const options = Array.from(new Set(result.map(({ item }) => item)));
    if (options.length === 0) {
      // If there are no shortcut matches, reset the search
      resetSearch();
    }
    return options;
  }, [
    isNote,
    actionSearchText,
    shortcutActive,
    showAddTemplate,
    showAddEmoji,
    showAddNote,
  ]);

  // Map user names to @mentions
  const userMentions = eligibleUsers.map((c: Option) => `@${c.name}`);

  // Join mention patterns
  const mentionRegexPattern = [...userMentions, ...numericMentions, '@all', '@here'].join(
    '|'
  );
  // const mentionRegexPattern = userMentions.join('|');
  const mentionRegex = new RegExp(mentionRegexPattern, 'g');

  const onEditorChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCharacterCount(e.target.value.length);
    handleSetMessage(e.target.value);
    const newHeight = textareaRef.current?.scrollHeight || minHeight;
    if (newHeight != textAreaHeight) setTextAreaHeight(newHeight);
    else if (e.target.value === '') setTextAreaHeight(minHeight);

    // AI Autocomplete
    if (!completionEnabled || !showAutoComplete) return;
    // getCompletion(e.target.value);
  };

  const onEditorKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      resetSearch();
    }

    if (e.key === '@') {
      if (!isNote) return; // Only allow @mentions when Editor is in Note mode
      setShortcutActive(false);
      setActionSearchText('');
      setMentionActive(true);
    }

    if (e.key === '/') {
      const textBeforeSlash = message.slice(0, textareaRef.current?.selectionStart || 0);
      // If the character before '/' is not a space, not the first character, and not a newline
      const lastTypedIsSpaceOrNewline =
        textBeforeSlash.slice(-1) !== ' ' &&
        textBeforeSlash.slice(-1) !== '\n' &&
        textBeforeSlash.length > 0;
      if (!showShortcuts || lastTypedIsSpaceOrNewline) return;
      setMentionActive(false);
      setShortcutActive(true);
    }

    if (mentionActive || shortcutActive) {
      // Only allow alphanumeric characters for mention search
      const allowedChars = /^[A-Za-z0-9\u00C0-\u017F ']+$/;
      const actionKeys = ['ArrowUp', 'ArrowDown', 'Enter'];

      if (actionKeys.includes(e.key)) {
        const searchOptions = mentionActive ? mentionOptions : shortcutOptions;
        if (!cursorPosition || !searchOptions.length) return;
        e.preventDefault();
        switch (e.code) {
          case 'ArrowUp':
            const nextIndex =
              actionSearchIndex <= 0 ? searchOptions.length - 1 : actionSearchIndex - 1;
            setActionSearchIndex(nextIndex);
            break;
          case 'ArrowDown':
            const prevIndex =
              actionSearchIndex >= searchOptions.length - 1 ? 0 : actionSearchIndex + 1;
            setActionSearchIndex(prevIndex);
            break;
          case 'Enter':
            const option: Option = searchOptions[actionSearchIndex];
            if (mentionActive) chooseMention(option);
            if (shortcutActive) chooseShortcut(option);
            resetSearch();
            break;
          default:
            break;
        }
      }

      if (e.key === 'Backspace') {
        // remove the last character from setMentionSearch
        setActionSearchText(actionSearchText.slice(0, -1));

        // Reset the search if the user deletes the last character (eg @ or /)
        if (actionSearchText.length === 0) {
          resetSearch();
        }
      }

      // If character is within the allowed set, add it to the search string
      if (e.key.length !== 1 || !allowedChars.test(e.key)) return;
      setActionSearchText(actionSearchText + e.key);
    }

    // Ensure the feature flag is enabled for AI completion
    if (!conversationCompletion || isNote || !showAutoComplete) return;
    if (e.key === 'Tab') {
      e.preventDefault();
      if (!completionEnabled || !promptMessage) return;
      handleSetMessage(message + promptMessage);
      setPromptMessage('');
      return;
    }
  };
  const onEditorSelectionChange = (currentPosition: CaretPosition) => {
    if (currentPosition.focused) {
      setActionSearchIndex(0);
      const newPosition = {
        top: currentPosition.top + currentPosition.height,
        left: currentPosition.left,
        caret: currentPosition,
      };
      setCursorPosition(newPosition);
    } else {
      setMentionActive(false);
      setActionSearchText('');
    }
  };

  const chooseShortcut = (option: Option) => {
    if (!textareaRef.current || !shortcutActive) return;
    const editor = textareaRef.current;
    if (!editor) return;

    const { selectionStart, selectionEnd } = editor;
    const prefix = message.slice(0, selectionStart - 1 - actionSearchText.length);
    const suffix = message.slice(selectionStart);
    const newMessage = `${prefix}${suffix}`;
    handleSetMessage(newMessage);

    setTimeout(() => {
      // Set caret back to original position
      // Using setTimeout to ensure cursor positioning occurs after setMessage updates the textarea's value.
      // Without this delay, the cursor might move to the end due to asynchronous state updates
      editor.focus();
      editor.setSelectionRange(selectionStart - 1, selectionEnd - 1);
    }, 0);
    resetSearch();

    switch (option.action) {
      case 'open-templates':
        // Open the templates modal
        setTimeout(() => {
          if (templateButton?.current) templateButton?.current.click();
        }, 0);
        setTemplatesModalActive(true);
        break;
      case 'open-review-request-templates':
        // Open the review requests templates modal
        setTimeout(() => {
          if (reviewButton?.current) reviewButton?.current.click();
        }, 0);
        setReviewRequestTemplatesModalActive(true);
        break;
      case 'switch-to-notes':
        // switch to notes
        setIsNote(true);
        break;
      case 'switch-to-reply':
        // switch to reply
        setIsNote(false);
        break;
      case 'open-emojis':
        // Open the emoji modal
        setTimeout(() => {
          if (emojiButton?.current) emojiButton?.current.click();
        }, 0);
        break;
      case 'open-ai-generator':
        // Open the AI box modal
        setTimeout(() => {
          if (aiBoxButton?.current) aiBoxButton?.current.click();
        }, 0);
        break;
      default:
        toast(`Shortcut ${option.name} not implemented yet`);
        break;
    }
  };

  const chooseMention = (user: Option) => {
    try {
      if (!textareaRef.current || !mentionActive) return;
      const selectedMention = user?.value || user?.name || `{{${user?.id}}}`;
      if (!selectedMention) throw new Error('Could not @mention user');

      const { selectionStart, selectionEnd } = cursorPosition?.caret || {
        selectionStart: defaultCursorPosition.caret.selectionStart,
        selectionEnd: defaultCursorPosition.caret.selectionEnd,
      };

      const prefix = message.slice(0, selectionStart - actionSearchText.length - 1);
      const suffix = message.slice(selectionEnd);

      const newMessage = `${prefix}@${selectedMention} ${suffix}`;
      handleSetMessage(newMessage);

      resetSearch();
    } catch (e) {
      console.error(e);
      captureException(e, {
        tags: {
          feature: 'MessageEditorV2',
          action: 'insertMention',
        },
      });
      toast.error('Could not @mention user');
      resetSearch();
    }
  };

  const {
    settingsState: { settings },
  } = useSettings();

  const segmentCount = returnSegmentLength(message);
  const maxSegments = settings?.max_number_of_segments || 12;

  const [open, setOpen] = useState(false);

  return (
    <>
      {segmentCount > maxSegments && !isNote && channel_type === 'phone' && (
        <Badge variant="red" size="2">
          <HStack>
            <Text>Message exceeds admin-set credit limit.</Text>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger>
                <button>See why?</button>
              </PopoverTrigger>
              <PopoverPortal>
                <PopoverContent
                  side="top"
                  css={{ maxWidth: '300px', height: 'auto', padding: '10px' }}
                >
                  <Box>{MessageSegmentVisualizer({ message })}</Box>
                </PopoverContent>
              </PopoverPortal>
            </Popover>
          </HStack>
        </Badge>
      )}
      <Box
        css={{
          width: '100%',
          height: '100%',
          lineHeight: '21px',
          textAlign: 'left',
          fontSize: '15px',
          overflowY: 'auto',
          overflowX: 'hidden',
          cursor: 'text',
          position: 'relative',
          borderRadius: '10px',
          border: 'none',
          resize: 'none',
          '&:focus': {
            outline: 'none',
          },
          '@md': {
            paddingTop: 12,
          },
          '@lg': {
            paddingTop: 12,
            minHeight: '50px',
          },
        }}
        onKeyDown={onEditorKeyDown}
      >
        <div style={{ position: 'relative' }}>
          <RichTextarea
            ref={textareaRef}
            style={{
              width: '100%',
              lineHeight: 1.8,
              minHeight: minHeight,
              maxHeight: maxHeight,
              height: `${textAreaHeight}px`,
              paddingLeft: 15,
              paddingRight: 15,
              outline: 'none',
              resize: 'none', // disable textarea resize by user
            }}
            value={message}
            // there should only be a character limit for phone messages
            maxLength={
              !isNote && channel_type === 'phone'
                ? (settings?.max_number_of_segments || 12) * 160
                : undefined
            }
            placeholder={
              placeholder
                ? placeholder
                : isNote
                  ? 'Enter Note'
                  : !isNote &&
                      !showShortcuts &&
                      (promptMessage === '' || !showAutoComplete)
                    ? 'Enter Message'
                    : !isNote &&
                        promptMessage != '' &&
                        completionEnabled &&
                        showAutoComplete
                      ? ''
                      : 'Use / for shortcuts'
            }
            onChange={onEditorChange}
            onSelectionChange={onEditorSelectionChange}
          >
            {(content) => {
              const highlightRenderer = createRegexRenderer([
                [
                  !isNote ? pipeRegex : emptyRegex,
                  { background: '#EAF5F9', color: '#4276AA', borderRadius: '3px' },
                ],
                [
                  isNote ? mentionRegex : emptyRegex,
                  { background: '#ffb703', color: '#023047', borderRadius: '3px' },
                ],
              ]);

              return (
                <div>
                  {highlightRenderer(content)}

                  {!placeholder &&
                    promptMessage &&
                    completionEnabled &&
                    conversationCompletion &&
                    showAutoComplete && (
                      <span key="__placeholder" style={{ color: 'darkgray' }}>
                        {/* The conditional isNote must render an empty space as there is rendering issues when isNote is false. 
                    Putting the space in the conversation completion is invisible to the user but solves the issue with rendering issues.   */}
                        {!isNote || (!aiSentenceGenerator && showAutoComplete)
                          ? promptMessage
                          : ' '}
                      </span>
                    )}
                </div>
              );
            }}
          </RichTextarea>

          {((mentionActive && isNote) || shortcutActive) &&
            createPortal(
              <Menu
                source={source || 'editor'}
                options={shortcutActive ? shortcutOptions : mentionOptions}
                index={actionSearchIndex}
                position={cursorPosition || defaultCursorPosition}
                complete={shortcutActive ? chooseShortcut : chooseMention}
              />,
              document.body
              // Scoping portal to parent node of textarea to prevent portal from displaying multiple menus when the editor is in the main editor and the user is typing in another editor (eg translate message editor modal)
              // However, inside the modal the menu is not displayed correctly
              // Solution for moment is to disable shortcuts in the modal
              // textareaRef.current?.parentNode as Element
            )}

          {/* This implementation prevents react portal displaying multiple menus when the editor is in the main editor and the user is typing in another editor (eg translate message editor modal)
            However, it does not work well with the message editor in contacts drawer */}
          {/* {((mentionActive && isNote) || shortcutActive) && (
          <Menu
            source={source || 'editor'}
            options={shortcutActive ? shortcutOptions : mentionOptions}
            index={actionSearchIndex}
            position={position || defaultPosition}
            complete={shortcutActive ? chooseShortcut : chooseMention}
          />
        )} */}

          {conversationCompletion &&
            !isNote &&
            showAutoComplete &&
            !aiSentenceGenerator && (
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: 'calc(100% - 35px)',
                  marginRight: 10,
                }}
              >
                <Tooltip>
                  <TooltipTrigger asChild>
                    <IconButton
                      size={2}
                      type="button"
                      css={{
                        ml: 10,
                        backgroundColor: completionEnabled ? '$slate4' : undefined,
                      }}
                      onClick={() => {
                        // Default action on button click
                        setPromptMessage('');
                        setIsPromptLoading(false);
                        setCompletionEnabled(!completionEnabled);
                      }}
                    >
                      {!isPromptLoading ? (
                        <HiLightningBolt type="button" size={ICON_SIZE} fill="#2196f3" />
                      ) : (
                        <Spinner size={ICON_SIZE} color="primary" />
                      )}
                    </IconButton>
                  </TooltipTrigger>
                  <TooltipContent side="top">AI Content Generator</TooltipContent>
                </Tooltip>
              </div>
            )}
        </div>
      </Box>
    </>
  );
};
